import { ReactElement, useEffect, useState } from 'react';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import './AuthPage.scss';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import { DarkButton } from '../../Buttons/Buttons';
import { Status } from '../../ContextProviders/Auth';
import { LoadingSVG } from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faEyeSlash, faPhone, faUnlock, faUser } from '@fortawesome/pro-solid-svg-icons';
import { useFirebase, useMonofunction } from '../../ContextProviders/Firebase';
import { toasts } from '../../../shared';
import { UserCredential, getAuth, sendEmailVerification, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';

export const RegisterPage = (): ReactElement => {
  const localization = useLocalization();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState('');
  const [phoneFieldErrorMessage, setPhoneFieldErrorMessage] = useState('');
  const [passwordFieldErrorMessage, setPasswordFieldErrorMessage] = useState('');
  const [passwordConfirmFieldErrorMessage, setPasswordConfirmFieldErrorMessage] = useState('');

  const [status, setStatus] = useState(Status.IDLE);
  const accountFunctions = useMonofunction();
  const projectConfig = useProjectConfig();
  const firebase = useFirebase();

  useEffect(() => {
    document.title = `${localization.strings.auth.registerButton} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.auth.registerButton]);

  const handleErrors = ({ code }: FirebaseError) => {
    // https://firebase.google.com/docs/auth/admin/errors
    switch (code) {
      case 'auth/email-already-exists':
      case 'auth/uid-already-exists':
        setEmailFieldErrorMessage(localization.strings.settings.emailAlreadyExist);
        break;
      case 'auth/invalid-email':
        setEmailFieldErrorMessage(localization.strings.settings.emailError);
        break;
      case 'auth/invalid-phone-number':
        setPhoneFieldErrorMessage(localization.strings.auth.phoneErrorDisplay);
        break;
      case 'auth/phone-number-already-exists':
        setPhoneFieldErrorMessage(localization.strings.auth.phoneAlreadyExists);
        break;
      case 'auth/invalid-password':
        setPasswordFieldErrorMessage(localization.strings.auth.passwordRules.staffMin);
        break;
      default:
        toasts.error(localization.strings.auth.error.invalidCreateAccount);
    }
  };
  const register = () => {
    setStatus(Status.IN_FLIGHT);

    let validatedPhone = phone
      .replace(/\+/g, '')
      .replace(/-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/ /g, '');
    validatedPhone = '+' + validatedPhone;

    const user = {
      password: password,
      phoneNumber: validatedPhone,
      firstName: firstName,
      lastName: lastName,
      email: email,
    };

    void accountFunctions
      .CreateStaffAccount(user)
      .then(loginUser)
      .catch((reason) => {
        console.log(reason);
        handleErrors(reason.details);
        setStatus(Status.IDLE);
      });
  };

  const loginUser = () => {
    signInWithEmailAndPassword(getAuth(firebase), email, password)
      .then((user) => verifyUserWithEmail(user))
      .catch(({ reason }) => {
        console.log(reason);
        toasts.error(localization.strings.auth.notAuthenticated);
        setStatus(Status.IDLE);
      });
  };

  const verifyUserWithEmail = ({ user }: UserCredential) => {
    sendEmailVerification(user, {
      url: `${window.location.origin}/sign-in`,
      handleCodeInApp: true,
    })
      .then(() => {
        clearFields();
        toasts.success(localization.strings.auth.emailVerification.emailSent);
      })
      .catch(({ reason }) => {
        console.log(reason);
        toasts.error(localization.strings.auth.error.invalidEmailVerification);
      })
      .finally(() => {
        void signOut(getAuth(firebase));
        setStatus(Status.IDLE);
      });
  };

  const clearFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setPassword('');
    setPasswordConfirm('');
  };

  const validateEmail = (value: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(value);
  };

  const validatePhone = (value: string) => {
    const re = /^[0-9)( -+]{10,24}$/;
    return re.test(value);
  };

  const onEmailChange = (value: string) => {
    setEmail(value);
    if (value.trim().length > 0) {
      setEmailFieldErrorMessage(validateEmail(value) ? '' : localization.strings.auth.emailPlaceholder);
    } else {
      setEmailFieldErrorMessage('');
    }
  };

  const onPhoneChange = (value: string) => {
    setPhone(value);
    if (value.trim().length > 0) {
      setPhoneFieldErrorMessage(validatePhone(value) ? '' : localization.strings.auth.phoneErrorDisplay);
    } else {
      setPhoneFieldErrorMessage('');
    }
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
    setPasswordFieldErrorMessage(value.trim().length < 6 ? localization.strings.auth.passwordRules.staffMin : '');
  };

  const onPasswordConfirmChange = (value: string) => {
    setPasswordConfirm(value);
    setPasswordConfirmFieldErrorMessage(value !== password ? localization.strings.auth.invalidPasswordRetype : '');
  };

  const isValidRegister = () => {
    if (
      emailFieldErrorMessage ||
      phoneFieldErrorMessage ||
      passwordFieldErrorMessage ||
      passwordConfirmFieldErrorMessage
    ) {
      return false;
    } else if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      phone === '' ||
      password === '' ||
      passwordConfirm === ''
    ) {
      return false;
    } else return true;
  };

  return (
    <div className="centered-page-box">
      <div className="login-form">
        <div className="login-header text-center">
          <h2 className="font-weight-bold">{localization.strings.auth.registerButton}</h2>
          <p>{localization.strings.auth.registerToGetAccess}</p>
        </div>
        <form
          onSubmit={(e) => {
            register();
            e.preventDefault();
          }}
        >
          <div className="register-fields">
            <Row className="fields" xs="1" md="2">
              <Col>
                <Row>
                  <Label className="font-weight-bold">{localization.strings.auth.firstNameTitle}</Label>
                </Row>
                <Row style={{ position: 'relative' }}>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      height: '1rem',
                      width: '1rem',
                      position: 'absolute',
                      top: '0.65rem',
                      left: '0.65rem',
                    }}
                  />
                  <Input
                    type="text"
                    value={firstName}
                    disabled={status === Status.IN_FLIGHT}
                    onChange={({ target: { value } }) => {
                      setFirstName(value);
                    }}
                    placeholder={localization.strings.auth.firstNamePlaceholder}
                  />
                </Row>
              </Col>

              <Col>
                <Row>
                  <Label className="font-weight-bold">{localization.strings.auth.lastNameTitle}</Label>
                </Row>
                <Row style={{ position: 'relative' }}>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      height: '1rem',
                      width: '1rem',
                      position: 'absolute',
                      top: '0.65rem',
                      left: '0.65rem',
                    }}
                  />
                  <Input
                    type="text"
                    value={lastName}
                    disabled={status === Status.IN_FLIGHT}
                    onChange={({ target: { value } }) => {
                      setLastName(value);
                    }}
                    placeholder={localization.strings.auth.lastNamePlaceholder}
                  />
                </Row>
              </Col>
            </Row>

            <Row className="fields" xs="1" md="2">
              <Col>
                <Row>
                  <Label className="font-weight-bold">{localization.strings.auth.emailTitle}</Label>
                </Row>
                <Row style={{ position: 'relative' }}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{
                      height: '1rem',
                      width: '1rem',
                      position: 'absolute',
                      top: '0.65rem',
                      left: '0.65rem',
                    }}
                  />
                  <Input
                    type="email"
                    value={email}
                    disabled={status === Status.IN_FLIGHT}
                    onChange={({ target: { value } }) => {
                      onEmailChange(value);
                    }}
                    placeholder={localization.strings.auth.emailPlaceholder}
                    invalid={!!emailFieldErrorMessage}
                  />
                  <FormFeedback>{emailFieldErrorMessage}</FormFeedback>
                </Row>
              </Col>

              <Col>
                <Row>
                  <Label className="font-weight-bold">{localization.strings.auth.phoneTitle}</Label>
                </Row>
                <Row style={{ position: 'relative' }}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{
                      height: '1rem',
                      width: '1rem',
                      position: 'absolute',
                      top: '0.65rem',
                      left: '0.65rem',
                    }}
                  />
                  <Input
                    type="tel"
                    value={phone}
                    disabled={status === Status.IN_FLIGHT}
                    onChange={({ target: { value } }) => {
                      onPhoneChange(value);
                    }}
                    placeholder={localization.strings.auth.phonePlaceHolder}
                    invalid={!!phoneFieldErrorMessage}
                  />
                  <FormFeedback>{phoneFieldErrorMessage}</FormFeedback>
                </Row>
              </Col>
            </Row>

            <Row className="fields" xs="1" md="2">
              <ViewablePassword
                disabled={status === Status.IN_FLIGHT}
                value={password}
                confirm={false}
                onChange={({ target: { value } }) => {
                  onPasswordChange(value);
                }}
                errorMessage={passwordFieldErrorMessage}
              />

              <ViewablePassword
                disabled={status === Status.IN_FLIGHT}
                value={passwordConfirm}
                confirm={true}
                onChange={({ target: { value } }) => {
                  onPasswordConfirmChange(value);
                }}
                errorMessage={passwordConfirmFieldErrorMessage}
              />
            </Row>
          </div>

          <Row className="register">
            {status !== Status.IN_FLIGHT ? (
              <DarkButton disabled={!isValidRegister()} style={{ width: '100%', margin: '0' }}>
                {localization.strings.auth.registerButton}
              </DarkButton>
            ) : (
              <DarkButton disabled={!isValidRegister() || Status.IN_FLIGHT} style={{ width: '100%', margin: '0' }}>
                <LoadingSVG size={20} />
              </DarkButton>
            )}
          </Row>
        </form>
      </div>
    </div>
  );
};

const ViewablePassword = ({
  value,
  onChange,
  disabled = false,
  confirm = false,
  errorMessage = '',
}: {
  value: string;
  onChange: (e) => void;
  disabled?: boolean;
  confirm?: boolean;
  errorMessage: string;
}): ReactElement => {
  const localization = useLocalization();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Col>
      <Row>
        <Label className="font-weight-bold">
          {!confirm ? localization.strings.auth.passwordTitle : localization.strings.auth.passwordConfirmTitle}
        </Label>
      </Row>
      <Row style={{ position: 'relative' }}>
        <FontAwesomeIcon
          icon={faUnlock}
          style={{ height: '1rem', width: '1rem', position: 'absolute', top: '0.65rem', left: '0.65rem' }}
        />
        <Input
          disabled={disabled}
          type={showPassword ? 'text' : 'password'}
          placeholder={
            !confirm
              ? localization.strings.auth.passwordPlaceholder
              : localization.strings.auth.passwordPlaceholderRetype
          }
          value={value}
          onChange={onChange}
          invalid={!!errorMessage}
        />
        <FormFeedback>{errorMessage}</FormFeedback>
        <div
          onClick={() => setShowPassword(!showPassword)}
          style={{
            position: 'absolute',
            width: 'fit-content',
            right: '-2.1rem',
            top: '0.2rem',
            cursor: 'pointer',
            padding: '0.2rem 0.4rem',
          }}
        >
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
        </div>
      </Row>
    </Col>
  );
};

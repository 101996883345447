import { ReactElement, useEffect, useState } from 'react';
import { ButtonGroup, PopoverBody, PopoverHeader, Popover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { LanguageCode } from '@eir/core';
import { LightButton } from '../Buttons/Buttons';
import { useConcreteProject } from '../ContextProviders/ProjectContext';
import { useLocalization } from '../ContextProviders/LocalizationContext';

export const LanguageSwitcher = (): ReactElement => {
  const project = useConcreteProject();
  const localization = useLocalization();
  const [popoverIsVisible, setPopoverIsVisible] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState<[string, LanguageCode][]>();

  useEffect(() => {
    setAvailableLanguages(Object.entries(LanguageCode).filter(([, value]) => project.languages?.includes(value)));
  }, [project.languages]);

  return (
    <>
      {availableLanguages?.length && availableLanguages.length > 1 ? (
        <>
          <FontAwesomeIcon
            icon={faLanguage}
            id="language-switcher-button"
            style={{ color: 'white', cursor: 'pointer' }}
            onClick={() => setPopoverIsVisible(!popoverIsVisible)}
          ></FontAwesomeIcon>
          <Popover
            placement="top"
            target="language-switcher-button"
            toggle={() => setPopoverIsVisible(!popoverIsVisible)}
            isOpen={popoverIsVisible}
          >
            <PopoverHeader>{localization.strings.settings.selectLanguage}</PopoverHeader>
            <PopoverBody>
              <ButtonGroup>
                {availableLanguages?.map(([key, value]) => (
                  <LightButton
                    active={localization.activeLanguageCode === value}
                    onClick={() => {
                      localization.setLanguage(value);
                      setPopoverIsVisible(!popoverIsVisible);
                    }}
                    key={value}
                  >
                    {key}
                  </LightButton>
                ))}
              </ButtonGroup>
            </PopoverBody>
          </Popover>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

import {
  onSnapshot,
  getDocs,
  collection,
  getDoc,
  doc as document,
  getDocFromCache,
  getDocsFromCache,
  QueryConstraint,
  CollectionReference,
  Query,
  query,
  DocumentData,
} from 'firebase/firestore';
import { equals } from 'lodash/fp';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFirestore } from '../components/ContextProviders/Firebase';
import { Maybe, WithID } from '../Types';
import { ApplicationStyle, style as coreStyle } from '@eir/core';
export interface FirestoreDocument<T extends WithID> {
  doc: T;
  error: Maybe<Error>;
  loading: boolean;
}
export interface FirestoreCollection<T extends WithID> {
  docs: T[];
  error: Maybe<Error>;
  loading: boolean;
}

export function useProjectStyle(projectId: string): ApplicationStyle {
  const [projectStyle, setProjectStyle] = useState(coreStyle[projectId]);
  const firestore = useFirestore();
  useEffect(() => {
    getDoc(document(firestore, 'project', projectId))
      .then((projectData: DocumentData) => {
        setProjectStyle(projectData.data().style);
      })
      .catch((error) => {
        console.error('error: ', error);

        setProjectStyle(coreStyle[projectId]);
      });
  }, [firestore, projectId]);

  return projectStyle;
}

export function useDocumentOnce<T extends WithID>(ref: string): FirestoreDocument<T> {
  const [doc, setDoc] = useState<T>({} as T);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const firestore = useFirestore();

  useEffect(() => {
    getDoc(document(firestore, ref))
      .then((snap) => {
        setDoc({ ...snap.data(), fId: snap.id } as T);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [ref, firestore]);

  return { doc, error, loading };
}

export function useCollectionOnce<T extends WithID>(ref: string): FirestoreCollection<T> {
  const [docs, setDocs] = useState<T[]>([] as T[]);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const firestore = useFirestore();

  useEffect(() => {
    getDocs(collection(firestore, ref))
      .then((snap) => {
        setDocs(snap.docs.map((snap) => ({ ...snap.data(), fId: snap.id } as T)));
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [ref, firestore]);

  return { docs, error, loading };
}

/*
  "What's going on with the cache and unstable batch updates?"
  There's a problem where react will rerender even in the case where you use setState(s => s) in a callback (Possibly just the first time?)
  The caching is used to speed up load, since the config document is needed for everything, the project id is used in the path for other objects.
  The old app was very slow so this is a solution to speed it up and avoid extra round trips before the page is rendered, while also making sure that new changes are reflected eventually
  The batch updating and equality check is to avoid unnecessary rerenders, the three state updates would previously cause a lot of rerenders, one per state update * 3 states.

  If you need to change things here, make sure to add some prints or use a profiler to see what's going on with updates and rerenders.
*/
export function useCollection<T extends WithID, MetaDataType = Record<string, never>>(
  sref: Maybe<string>,
  meta: MetaDataType,
  retryNonce?: unknown,
  queryConstraint?: QueryConstraint,
): FirestoreCollection<T & MetaDataType> {
  const [docs, setDocs] = useState<(T & MetaDataType)[]>([]); //TODO can we just use the type, we're force casting after the check anyway
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true); //Only "loading" on first fetch - subsequent ones just kind of arrive
  const firestore = useFirestore();
  const docsRef = useRef<T[]>();
  docsRef.current = docs;

  //Make sure this works as expected, use a ref
  const updateDocsState = (newDocs: (T & MetaDataType)[]) => {
    if (!equals(docsRef.current, newDocs)) setDocs(newDocs);
  };

  //reset all when sref changes
  useEffect(() => {
    setDocs([]);
    setError(undefined);
    setLoading(true);
  }, [sref]);

  useEffect(() => {
    if (sref === undefined)
      return () => {
        /* void */
      };

    let serverHasResponded = false;
    let promiseIsStale = false;

    const ref = collection(firestore, sref);
    const queryOrCollection: Query | CollectionReference = queryConstraint ? query(ref, queryConstraint) : ref;

    getDocsFromCache(queryOrCollection)
      .then((snap) => {
        if (!serverHasResponded && !promiseIsStale) {
          updateDocsState(
            snap.docs.map((docSnap) => ({ ...docSnap.data(), fId: docSnap.id, ...meta } as T & MetaDataType)),
          );
        }
      })
      .catch((err) => {
        console.log('cache error', err);
      });

    const unsubscribe = onSnapshot(
      queryOrCollection,
      (snap) => {
        ReactDOM.unstable_batchedUpdates(() => {
          updateDocsState(
            snap.docs.map((docSnap) => ({ ...docSnap.data(), fId: docSnap.id, ...meta } as T & MetaDataType)),
          );
          setLoading(false);
          serverHasResponded = true;
        });
      },
      (err) => {
        ReactDOM.unstable_batchedUpdates(() => {
          updateDocsState([]);
          setError(err);
          setLoading(false);
        });
        console.log('fail from fb', sref, err);
      },
    );

    return () => {
      unsubscribe();
      promiseIsStale = true;
    };
    // if error, retry every time?
    // eslint-disable-next-line
  }, [sref, firestore, retryNonce]);
  return { docs, error, loading };
}

export function useDocument<T extends WithID, MetaDataType = Record<string, never>>(
  sref: Maybe<string>,
  meta: MetaDataType,
): FirestoreDocument<T & MetaDataType> {
  const [doc, setDoc] = useState<T & MetaDataType>({} as T & MetaDataType);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const firestore = useFirestore();
  const docRef = useRef<T>();
  docRef.current = doc;

  const updateDocState = (newDoc: T & MetaDataType) => {
    if (!equals(docRef.current, newDoc)) {
      setDoc(newDoc);
    }
  };

  //reset all when sref changes
  useEffect(() => {
    setDoc({} as T & MetaDataType);
    setError(undefined);
    setLoading(true);
  }, [sref]);

  useEffect(() => {
    if (sref === undefined)
      return () => {
        /* void */
      };

    let serverHasResponded = false;
    let promiseIsStale = false;

    const ref = document(firestore, sref);

    getDocFromCache(ref)
      .then((snap) => {
        if (!serverHasResponded && !promiseIsStale) {
          updateDocState({ ...snap.data(), fId: snap.id, ...meta } as T & MetaDataType);
        }
      })
      .catch((err) => {
        console.log('cache error');
      });

    const unsubscribe = onSnapshot(
      ref,
      (snap) => {
        ReactDOM.unstable_batchedUpdates(() => {
          //TODO - still will cause rerender because later two setstates
          updateDocState({ ...snap.data(), fId: snap.id, ...meta } as T & MetaDataType);
          setError(undefined);
          setLoading(false);
          serverHasResponded = true;
        });
      },
      (err) => {
        ReactDOM.unstable_batchedUpdates(() => {
          updateDocState({} as T & MetaDataType);
          setError(err);
          setLoading(false);
        });
      },
    );

    return () => {
      unsubscribe();
      promiseIsStale = true;
    };
    // eslint-disable-next-line
  }, [sref, firestore]);

  return { doc, error, loading };
}

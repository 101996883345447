import { firestore } from '../../typings/firestore';

export interface PublishResult extends firestore.DocumentSnapshot {
  data: () => PublishResultData;
}

export interface PublishResultData {
  accUserId: string;
  completedOn: string;
  consistencyReport: { [key: string]: ConsistencyReportData };
  initiatedOn: string;
  publishedSuccessful: boolean;
}

export interface ConsistencyReportData {
  href: string[];
  images: string[];
}

export enum PublishState {
  IDLE,
  WAIT_CONFIRM,
  IN_FLIGHT,
  ERROR,
}

export interface PublishProcess {
  notifyMessage: string;
  shouldSendNotification: boolean;
  shouldNotifyAll: boolean;
  targetUsers: string[];
}

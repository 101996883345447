import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faUser, faUnlock } from '@fortawesome/pro-solid-svg-icons';
import { ReactElement, useEffect, useState } from 'react';
import { Status, useAuth } from '../../ContextProviders/Auth';
import { useStaff } from '../../ContextProviders/AppContext';
import { LoadingSVG } from '../Loading/Loading';
import { DarkButton, HoverButton } from '../../Buttons/Buttons';
import './AuthPage.scss';
import { Col, Input, InputGroup, Label, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';
enum LoginMode {
  Admin,
  Staff,
}

export const AuthPage = (): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const project = useConcreteProject();
  const title = useProjectTitle();
  const [mode, setMode] = useState(LoginMode.Admin);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pin, setPin] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const { doc: staff } = useStaff();
  const config = useConcreteProject();

  //Auto login by URL param
  const location = useLocation();
  const paramPw = new URLSearchParams(location.search).get('staffLogin');
  useEffect(() => {
    if (paramPw !== null && staff.value) {
      auth.signIn(staff.value, paramPw);
    }
    // eslint-disable-next-line
  }, [staff.value, paramPw]);

  useEffect(() => {
    setMode(LoginMode.Admin);
    setEmail('');
    setPassword('');
    setPin('');
  }, []);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const _onChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim().length > 0) {
      setInvalidEmail(!validateEmail(e.target.value));
    } else {
      setInvalidEmail(false);
    }
  };

  useEffect(() => {
    document.title = `${localization.strings.auth.signInButton} | ${title}`;
  }, [title, localization.strings.auth.signInButton]);

  const signIn = () => {
    if (config.uniformAuthentication) auth.signIn(email, password);
    else if (mode === LoginMode.Admin) auth.signIn(email, password);
    else if (mode === LoginMode.Staff) auth.signInStaff(pin);
  };

  return (
    <div className="centered-page-box">
      <div className="login-form">
        {
          <>
            <div className="login-header text-center">
              <h2 className="font-weight-bold">{localization.strings.auth.welcomeBack}</h2>
              <p>{localization.strings.auth.loginToGetAccess}</p>
            </div>
            {config.uniformAuthentication ? (
              <></>
            ) : (
              <div className="login-mode-buttons">
                <HoverButton
                  onClick={() => setMode(LoginMode.Staff)}
                  className={mode === LoginMode.Staff ? 'dark-button' : 'light-button'}
                  style={{ margin: 0 }}
                >
                  {localization.strings.auth.staffAccount}
                </HoverButton>
                <HoverButton
                  onClick={() => setMode(LoginMode.Admin)}
                  className={mode === LoginMode.Admin ? 'dark-button' : 'light-button'}
                  style={{ margin: 0 }}
                >
                  {localization.strings.auth.adminAccount}
                </HoverButton>
              </div>
            )}
            <form
              className="login-fields"
              onSubmit={(e) => {
                e.preventDefault();
                signIn();
              }}
            >
              {mode === LoginMode.Admin && (
                <Col>
                  <Row form style={{ marginBottom: '1.5rem' }}>
                    <Col>
                      <Row>
                        <Label className="font-weight-bold">{localization.strings.auth.emailTitle}</Label>
                      </Row>
                      <Row style={{ position: 'relative' }}>
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{
                            height: '1rem',
                            width: '1rem',
                            position: 'absolute',
                            top: '0.65rem',
                            left: '0.65rem',
                          }}
                        />
                        <Input
                          type="email"
                          value={email}
                          disabled={auth.status === Status.IN_FLIGHT}
                          onChange={_onChange}
                          placeholder={localization.strings.auth.emailPlaceholder}
                          invalid={invalidEmail}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <ViewablePassword
                      disabled={auth.status === Status.IN_FLIGHT}
                      value={password}
                      onChange={({ target: { value } }) => setPassword(value)}
                    />
                  </Row>
                  <Row style={{ marginTop: '1rem' }}>
                    <Col>
                      {localization.strings.auth.forgotPasswordClick}&nbsp;
                      <Link style={{ color: '#ff0000' }} to={'/forgot-password'}>
                        {localization.strings.auth.here}
                      </Link>
                    </Col>
                  </Row>
                  {project.enableStripe &&
                  project.enableVultusAPI &&
                  project.uniformAuthentication &&
                  project.allowAccountCreation ? (
                    <Row style={{ marginTop: '1rem' }}>
                      <Col>
                        {localization.strings.auth.noAccountYetClick}&nbsp;
                        <Link style={{ color: '#ff0000' }} to={'/register'}>
                          {localization.strings.auth.here}
                        </Link>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row style={{ marginTop: '1rem' }}>
                    {auth.status !== Status.IN_FLIGHT ? (
                      <DarkButton style={{ width: '100%', margin: '0' }}>
                        {localization.strings.auth.signInButton}
                      </DarkButton>
                    ) : (
                      <DarkButton style={{ width: '100%', margin: '0' }}>
                        <LoadingSVG size={20} />
                      </DarkButton>
                    )}
                  </Row>
                </Col>
              )}
              {mode === LoginMode.Staff && (
                <Col>
                  <Input type="text" defaultValue={staff.value} style={{ display: 'none' }} />
                  <Row style={{ marginTop: '1rem' }}>
                    <ViewablePassword
                      disabled={auth.status === Status.IN_FLIGHT}
                      value={pin}
                      onChange={({ target: { value } }) => setPin(value)}
                    />
                  </Row>
                  <Row style={{ marginTop: '1rem' }}>
                    {auth.status !== Status.IN_FLIGHT ? (
                      <DarkButton style={{ width: '100%', margin: '0' }}>
                        {localization.strings.auth.signInButton}
                      </DarkButton>
                    ) : (
                      <DarkButton style={{ width: '100%', margin: '0' }}>
                        <LoadingSVG size={20} />
                      </DarkButton>
                    )}
                  </Row>
                </Col>
              )}
            </form>
          </>
        }
      </div>
    </div>
  );
};

const ViewablePassword = ({
  value,
  onChange,
  disabled = false,
}: {
  value: string;
  onChange: (e) => void;
  disabled?: boolean;
}): ReactElement => {
  const localization = useLocalization();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup>
      <Col>
        <Row>
          <Label className="font-weight-bold">{localization.strings.auth.passwordTitle}</Label>
        </Row>
        <Row style={{ position: 'relative' }}>
          <FontAwesomeIcon
            icon={faUnlock}
            style={{ height: '1rem', width: '1rem', position: 'absolute', top: '0.65rem', left: '0.65rem' }}
          />
          <Input
            disabled={disabled}
            type={showPassword ? 'text' : 'password'}
            placeholder={localization.strings.auth.passwordPlaceholder}
            value={value}
            onChange={onChange}
          />
          <div
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: 'absolute',
              width: 'fit-content',
              right: '-2.1rem',
              top: '0.2rem',
              cursor: 'pointer',
              padding: '0.2rem 0.4rem',
            }}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
          </div>
        </Row>
      </Col>
    </InputGroup>
  );
};

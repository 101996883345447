import { ReactElement } from 'react';

import './Loading.scss';

export const LoadingSVG = ({ size = 50 }: { size?: number }): ReactElement => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 296.08 253.64"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className="loading-spinner"
    >
      <g transform="translate(-.77416 -.9083)">
        <ellipse
          cx="95.838"
          cy="31.145"
          rx="27.478"
          ry="27.478"
          fill="#fafafa"
          fillRule="evenodd"
          stroke="#1a1f39"
          strokeWidth="5.5168"
        />
        <ellipse cx="219.7" cy="31.306" rx="30.236" ry="30.236" fill="#1a1f39" />
        <rect x="123.62" y="28.317" width="67.101" height="5.4907" fill="#1a1f39" />
        <ellipse
          cx="142.76"
          cy="124.19"
          rx="27.478"
          ry="27.478"
          fill="#fafafa"
          fillRule="evenodd"
          stroke="#1a1f39"
          strokeWidth="5.5168"
        />
        <ellipse cx="266.62" cy="124.35" rx="30.236" ry="30.236" fill="#1a1f39" />
        <rect x="170.54" y="121.37" width="67.101" height="5.4907" fill="#1a1f39" />
        <ellipse cx="31.01" cy="123.7" rx="30.236" ry="30.236" fill="#1a1f39" />
        <rect x="46.741" y="120.84" width="67.101" height="5.4907" fill="#1a1f39" />
        <ellipse
          cx="208.29"
          cy="224.31"
          rx="27.478"
          ry="27.478"
          fill="#fafafa"
          fillRule="evenodd"
          stroke="#1a1f39"
          strokeWidth="5.5168"
        />
        <ellipse cx="84.537" cy="223.82" rx="30.236" ry="30.236" fill="#1a1f39" />
        <rect x="112.27" y="220.96" width="67.101" height="5.4907" fill="#1a1f39" />
      </g>
    </svg>
  );
};

export const Loading = ({ waitingFor, altText }: { waitingFor?: string; altText?: string }): ReactElement => {
  return (
    <div className="loading-page">
      <div className="loading-inner-stackvert">
        <LoadingSVG />
        {waitingFor && <>Loading {waitingFor}</>}
        {altText && <>{altText}</>}
      </div>
    </div>
  );
};

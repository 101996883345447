import { useRef, useEffect } from 'react';
import './WorkOrderIframe.scss';
import { useAuth } from '../ContextProviders/Auth';
import { useStyle } from '../ContextProviders/Style';
import { useConcreteProject } from '../ContextProviders/ProjectContext';
import { useLocation } from 'react-router-dom';

interface AuthData {
  type: 'auth';
  userId: string;
  email: string;
  workorderModule: string;
  userToken: string;
  projectId: string;
  firebaseId: string;
}

interface WorkOrderIframeProps {
  workorderModule: string;
}

const sendAuth = (iframeRef, authData: AuthData, url: string) => {
  iframeRef.current.contentWindow.postMessage(authData, url);
};

interface LocationState {
  externalPath?: string;
}

export const WorkOrderIframe = (props: WorkOrderIframeProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const concreteProject = useConcreteProject();
  const auth = useAuth();
  const currentUser = auth.user;
  const style = useStyle();
  const intervalIdRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

  const location = useLocation<LocationState>();
  const workorderPath = sessionStorage.getItem('path') ? sessionStorage.getItem('path') : location.state?.externalPath;
  sessionStorage.removeItem('path');
  const baseUrl = concreteProject.workorderUrl;
  const workorderFrontend = `${baseUrl}${workorderPath ?? ''}`;

  useEffect(() => {
    if (!iframeRef?.current) {
      return;
    }

    iframeRef.current.onload = async () => {
      if (!iframeRef?.current?.contentWindow || !currentUser?.email || !concreteProject) {
        return;
      }
      const userToken = await currentUser.getIdToken(true);
      const firebaseConfigString = window.localStorage.getItem('firebaseConfig');
      const firebaseConfig = JSON.parse(firebaseConfigString ? firebaseConfigString : '{}');
      const firebaseId = firebaseConfig?.projectId ? firebaseConfig?.projectId : '';

      iframeRef.current.contentWindow.postMessage(
        {
          type: 'style',
          style: {
            palette: {
              primary: {
                main: style.primary,
                light: style.primaryLight,
                dark: style.primaryDark,
              },
              secondary: {
                main: style.accent,
              },
            },
          },
        },
        workorderFrontend,
      );

      sendAuth(
        iframeRef,
        {
          type: 'auth',
          userId: currentUser.email,
          email: currentUser.email,
          userToken: userToken,
          projectId: concreteProject.id,
          firebaseId,
          workorderModule: props.workorderModule,
        },
        baseUrl,
      );

      if (intervalIdRef) {
        clearInterval(intervalIdRef.current);
      }

      intervalIdRef.current = setInterval(() => {
        void currentUser.getIdToken(true).then((newToken) => {
          if (!currentUser.email) {
            return;
          }

          sendAuth(
            iframeRef,
            {
              type: 'auth',
              userId: currentUser.uid,
              email: currentUser.email,
              workorderModule: props.workorderModule,
              userToken: newToken,
              projectId: concreteProject.id,
              firebaseId,
            },
            baseUrl,
          );
        });
      }, 25 * 60 * 1000); // every 25 minutes
    };
  }, [baseUrl, currentUser, concreteProject, props.workorderModule, style, workorderFrontend]);

  useEffect(() => {
    return () => {
      if (intervalIdRef) {
        clearInterval(intervalIdRef.current);
      }
    };
  });

  return (
    <div className="workOrderIframeContainer">
      {currentUser && (
        <iframe
          ref={iframeRef}
          src={workorderFrontend}
          title="Workorder WebApp"
          allow="clipboard-write"
          className="workOrderIframe"
        ></iframe>
      )}
    </div>
  );
};

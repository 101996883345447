import { deleteApp } from 'firebase/app';
import { clearIndexedDbPersistence } from 'firebase/firestore';
import { ReactNode } from 'react';
import { useFirebase, useFirestore } from '../ContextProviders/Firebase';
import { ErrorBoundary } from './BugsnagErrorBoundary';
import { Event } from '@bugsnag/js';

interface Args {
  onError?: (e: Event) => void;
  FallbackComponent?: ({ clearError }) => JSX.Element;
  children: ReactNode;
}

export const CacheClearingErrorBoundary = ({ onError, FallbackComponent, children }: Args) => {
  const fb = useFirebase();
  const fs = useFirestore();
  const _onError = (e: Event) => {
    deleteApp(fb)
      .then(() => {
        console.log('Deleted firebase app');
      })
      .catch((e) => console.log(e));

    clearIndexedDbPersistence(fs)
      .then(() => {
        console.log('Cleared firestore cache');

        const openReq = indexedDB.open('firebaseLocalStorageDb', 1);
        openReq.onsuccess = (event) => {
          const db = openReq.result;
          const transaction = db.transaction(['firebaseLocalStorage'], 'readwrite');
          const objectStore = transaction.objectStore('firebaseLocalStorage');
          objectStore.clear();
        };
      })
      .catch((e) => console.log(e));
    onError?.(e);
  };

  return (
    <ErrorBoundary onError={_onError} FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundary>
  );
};

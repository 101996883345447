import { useEffect, useState } from 'react';
import { useListSubscriptionsFunction } from '../components/ContextProviders/Firebase';
import { Status } from '../components/ContextProviders/Auth';
import { ExtendedSubscription } from '@eir/core';

export const useListSubscriptions = ({ retryNonce }: { retryNonce?: unknown }) => {
  const listSubscriptions = useListSubscriptionsFunction();
  const [status, setStatus] = useState(Status.IDLE);
  const [subscriptions, setSubscriptions] = useState<ExtendedSubscription[]>();

  useEffect(() => {
    setStatus(Status.IN_FLIGHT);
    listSubscriptions()
      .then((res) => {
        setSubscriptions(res.data);
        setStatus(Status.IDLE);
      })
      .catch((error) => {
        console.log(error);
        setStatus(Status.ERROR);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryNonce]);

  return { status, subscriptions };
};

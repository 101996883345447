import { firestore } from '../../typings/firestore';

export enum SectionFieldPath {
  category = 'category',
  deleted = 'deleted',
  orderIndex = 'orderIndex',
}

export interface Section extends firestore.DocumentSnapshot {
  data: () => SectionData;
}

export interface SectionData {
  category: string;
  orderIndex: number;
  name: string;
  deleted?: boolean;
}

export const isSection = (doc?: firestore.DocumentSnapshot): boolean => {
  return doc != null && doc.ref.parent.id.startsWith('section');
};

import { NavLink } from 'react-router-dom';

import './MiniMenu.scss';

import {
  useCategories,
  useCategoriesDraft,
  useChiefCategories,
  useExternals,
  useIsDraft,
} from '../ContextProviders/AppContext';

import { useAuth } from '../ContextProviders/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { Category, Ordered } from '../../Types';
import { ReactElement } from 'react';
import { toasts } from '../../shared';
import { useLocalization } from '../ContextProviders/LocalizationContext';

interface NavItem {
  to: string;
  isProtected: boolean;
  isChief?: boolean;
  title: string;
  id: string;
  icon: string;
}
const CategoryNavigation = ({ isMiniMenu }: { isMiniMenu: boolean }): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const [draft] = useIsDraft();
  const { docs: externalsRaw } = useExternals();
  const { docs: mainPub } = useCategories();
  const { docs: mainDraft } = useCategoriesDraft();
  const { docs: chief } = useChiefCategories();

  const mainRaw = draft ? mainDraft : mainPub;
  const main = auth.isAdmin
    ? mainRaw
    : mainRaw.filter((c) => auth.categoryPermissions.includes(c.fId) || c.isProtected === false);
  const externals = auth.isAdmin
    ? externalsRaw
    : externalsRaw.filter((c) => auth.categoryPermissions.includes(c.fId) || c.isProtected === false);

  const orderIndexComparator = (c1: Category, c2: Category) => c1.orderIndex - c2.orderIndex;
  const orderIndexComparator2 = (c1: NavItem & Ordered, c2: NavItem & Ordered) => c1.orderIndex - c2.orderIndex;
  const restrictedProps = (n: NavItem) =>
    n.isProtected && !auth.isAdmin && !auth.isStaff
      ? {
          className: 'restricted',
          onClick: (e) => {
            toasts.warning(localization.strings.auth.loginToSee);
            e.preventDefault();
          },
        }
      : {};

  let navItems: NavItem[] = [];

  const { accessWO } = useAuth();

  // categories, changelog and externals
  const mainNavItems = main
    .filter((c) => c.availableWeb !== false)
    .map((c) => ({
      to: `/category/${c.fId}`,
      isProtected: c.isProtected,
      title: c.name,
      id: c.fId,
      orderIndex: c.orderIndex,
      icon: c.icon,
    }));

  const externaNavItems = externals
    .filter((c) => c.availableWeb !== false && (c.type === 'workorder' ? accessWO === 'true' : true))
    .map((e) => ({
      to: `/externals/${e.fId}`,
      isProtected: e.isProtected,
      title: e.name,
      id: e.fId,
      orderIndex: e.orderIndex,
      icon: e.icon,
    }));

  navItems = [...navItems, ...mainNavItems, ...externaNavItems].sort(orderIndexComparator2); // Changelog missing

  navItems = [
    ...navItems,
    ...chief
      .sort(orderIndexComparator)
      .filter((c) => c.availableWeb !== false)
      .map((c) => ({
        to: `/category/${c.fId}`,
        isProtected: true,
        isChief: true,
        title: c.name,
        id: c.fId,
        icon: c.icon,
      })),
  ];

  // Remove whitespace and accents of characters like ö or é
  // https://stackoverflow.com/a/37511463
  const cleanImageUri = (path: string) => path.normalize('NFD').replace(/[\u0300-\u036f]|\s/g, '');

  return (
    <nav className={`header-bottom-row ${isMiniMenu ? 'mini-menu' : ''}`}>
      <ol>
        {navItems.map((c, i) => (
          <li key={c.title}>
            <NavLink
              {...restrictedProps(c)}
              activeClassName="active"
              to={`${c.to}`}
              title={c.title}
              isActive={(match, location) => {
                if (!match) {
                  // /externals/ are redirected to /external/, so we need to use their redirected path instead of the originial one
                  return c.to.replace('/externals/', '/external/') === location.pathname;
                }
                return true;
              }}
            >
              {isMiniMenu ? (
                <div className="category-button">
                  <span
                    className="icon"
                    style={{
                      maskImage: cleanImageUri(`url(${c.icon})`),
                      WebkitMaskImage: cleanImageUri(`url(${c.icon})`),
                    }}
                  />
                  <svg height="8" width="14" className="triangle-indicator">
                    <polygon points="7,0 14,8 0,8" className="triangle" />
                  </svg>
                </div>
              ) : (
                <>
                  {c.title}
                  {c.isProtected && (
                    <FontAwesomeIcon
                      color="white"
                      className="nav-link-icon"
                      icon={c.isChief ? faShieldHalved : faLockKeyhole}
                    />
                  )}
                  <svg height="8" width="14" className="triangle-indicator">
                    <polygon points="7,0 14,8 0,8" className="triangle" />
                  </svg>
                </>
              )}
            </NavLink>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default CategoryNavigation;

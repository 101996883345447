import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap';

const ImagePicker = ({ isVisible, onClose, onSelect }) => {
  //Add images here if the user should have more to choose from in our gallery.
  const icons = [
    { image: require('../../../categoryTempImages/cross.png'), name: 'Cross Icon' },
    { image: require('../../../categoryTempImages/ekg.png'), name: 'EKG Icon' },
    { image: require('../../../categoryTempImages/tablet.png'), name: 'Tablet Icon' },
    { image: require('../../../categoryTempImages/general.png'), name: 'General Icon' },
    { image: require('../../../categoryTempImages/archive.png'), name: 'Archive Icon' },
    { image: require('../../../categoryTempImages/child.png'), name: 'Child Icon' },
    { image: require('../../../categoryTempImages/adult.png'), name: 'Adult Icon' },
    { image: require('../../../categoryTempImages/calculator.png'), name: 'Calculator Icon' },
    { image: require('../../../categoryTempImages/book.png'), name: 'Book Icon' },
    { image: require('../../../categoryTempImages/staff.png'), name: 'Staff Icon' },
    { image: require('../../../categoryTempImages/coherense.png'), name: 'Coherence Icon' },
    { image: require('../../../categoryTempImages/medicinlogo.png'), name: 'Medicine Icon' },
    { image: require('../../../categoryTempImages/trunk.png'), name: 'Trunk Icon' },
    { image: require('../../../categoryTempImages/changelog.png'), name: 'Changelog Icon' },
    { image: require('../../../categoryTempImages/information.png'), name: 'Information Icon' },
    { image: require('../../../categoryTempImages/education.png'), name: 'Education Icon' },
    { image: require('../../../categoryTempImages/thoughts.png'), name: 'Thoughts Icon' },
    { image: require('../../../categoryTempImages/contact_info.png'), name: 'Contactinformation Icon' },
    { image: require('../../../categoryTempImages/events.png'), name: 'Events Icon' },
    { image: require('../../../categoryTempImages/faq.png'), name: 'FAQ Icon' },
    { image: require('../../../categoryTempImages/newsletter.png'), name: 'Newsletter Icon' },
    { image: require('../../../categoryTempImages/handbook.png'), name: 'Handbook Icon' },
    { image: require('../../../categoryTempImages/computerWithBook.png'), name: 'Computer with book Icon' },
    { image: require('../../../categoryTempImages/list.png'), name: 'List Icon' },
    { image: require('../../../categoryTempImages/needle.png'), name: 'Needle Icon' },
    { image: require('../../../categoryTempImages/communication.png'), name: 'Communication Icon' },
    { image: require('../../../categoryTempImages/calculatorNeedle.png'), name: 'Calculator with needle Icon' },
    { image: require('../../../categoryTempImages/rutines.png'), name: 'Routines Icon' },
    { image: require('../../../categoryTempImages/baby.png'), name: 'Baby Icon' },
    { image: require('../../../categoryTempImages/alarm.png'), name: 'Alarm Icon' },
    { image: require('../../../categoryTempImages/map.png'), name: 'Map Icon' },
    { image: require('../../../categoryTempImages/notepad.png'), name: 'Notepad Icon' },
    { image: require('../../../categoryTempImages/school.png'), name: 'School Icon' },
    { image: require('../../../categoryTempImages/id.png'), name: 'ID Icon' },
    { image: require('../../../categoryTempImages/phone.png'), name: 'Phone Icon' },
    { image: require('../../../categoryTempImages/forms.png'), name: 'Forms Icon' },
    { image: require('../../../categoryTempImages/news.png'), name: 'News Icon' },
    { image: require('../../../categoryTempImages/educationTwo.png'), name: 'Education 2 Icon' },
    { image: require('../../../categoryTempImages/calendarCheck.png'), name: 'Calendarwith checkbox Icon' },
    { image: require('../../../categoryTempImages/routes.png'), name: 'Routes Icon' },
    { image: require('../../../categoryTempImages/educationThree.png'), name: 'Education 3 Icon' },
    { image: require('../../../categoryTempImages/organization.png'), name: 'Organization Icon' },
    { image: require('../../../categoryTempImages/faqTwo.png'), name: 'FAQ 2 Icon' },
    { image: require('../../../categoryTempImages/heart.png'), name: 'Heart Icon' },
    { image: require('../../../categoryTempImages/educationFour.png'), name: 'Education 4 Icon' },
    { image: require('../../../categoryTempImages/cardiology.png'), name: 'Cardiology Icon' },
    { image: require('../../../categoryTempImages/elderly.png'), name: 'Elderly Icon' },
    { image: require('../../../categoryTempImages/administration.png'), name: 'Administration Icon' },
    { image: require('../../../categoryTempImages/house.png'), name: 'House Icon' },
    { image: require('../../../categoryTempImages/emergency.png'), name: 'Emergency Icon' },
    { image: require('../../../categoryTempImages/network.png'), name: 'Network Icon' },
    { image: require('../../../categoryTempImages/admin.png'), name: 'Admin Icon' },
    { image: require('../../../categoryTempImages/career.png'), name: 'Career Icon' },
    { image: require('../../../categoryTempImages/newsroom.png'), name: 'Newsroom Icon' },
    { image: require('../../../categoryTempImages/it.png'), name: 'IT Icon' },
    { image: require('../../../categoryTempImages/tooth.png'), name: 'Tooth Icon' },
    { image: require('../../../categoryTempImages/pig.png'), name: 'Pig Icon' },
    { image: require('../../../categoryTempImages/horse.png'), name: 'Horse Icon' },
    { image: require('../../../categoryTempImages/sheep.png'), name: 'Sheep Icon' },
    { image: require('../../../categoryTempImages/veterinarian.png'), name: 'Veterinarian Icon' },
    { image: require('../../../categoryTempImages/cow.png'), name: 'Cow Icon' },
    { image: require('../../../categoryTempImages/bedridden.png'), name: 'Bedridden Icon' },
    { image: require('../../../categoryTempImages/babySolid.png'), name: '1 Icon' },
    { image: require('../../../categoryTempImages/ban.png'), name: '2 Icon' },
    { image: require('../../../categoryTempImages/bicycle.png'), name: '3 Icon' },
    { image: require('../../../categoryTempImages/brain.png'), name: '4 Icon' },
    { image: require('../../../categoryTempImages/checkBoxPen.png'), name: '5 Icon' },
    { image: require('../../../categoryTempImages/hiking.png'), name: '6 Icon' },
    { image: require('../../../categoryTempImages/Hospital.png'), name: '7 Icon' },
    { image: require('../../../categoryTempImages/megaphone.png'), name: '8 Icon' },
    { image: require('../../../categoryTempImages/message.png'), name: '9 Icon' },
    { image: require('../../../categoryTempImages/paperPlane.png'), name: '10 Icon' },
    { image: require('../../../categoryTempImages/pen.png'), name: '11 Icon' },
    { image: require('../../../categoryTempImages/shoeprints.png'), name: '12 Icon' },
    { image: require('../../../categoryTempImages/snowboard.png'), name: '13 Icon' },
    { image: require('../../../categoryTempImages/swimming.png'), name: '14 Icon' },
    { image: require('../../../categoryTempImages/vials.png'), name: '15 Icon' },
    { image: require('../../../categoryTempImages/viruses.png'), name: '16 Icon' },
    { image: require('../../../categoryTempImages/xRay.png'), name: '17 Icon' },
    { image: require('../../../categoryTempImages/handsBaby.png'), name: '18 Icon' },
    { image: require('../../../categoryTempImages/buildingShield.png'), name: '19 Icon' },
    { image: require('../../../categoryTempImages/car.png'), name: '20 Icon' },
    { image: require('../../../categoryTempImages/fileShield.png'), name: '21 Icon' },
    { image: require('../../../categoryTempImages/handcuffs.png'), name: '22 Icon' },
    { image: require('../../../categoryTempImages/idBadge.png'), name: '23 Icon' },
    { image: require('../../../categoryTempImages/archiveBoxes.png'), name: '24 Icon' },
    { image: require('../../../categoryTempImages/clipboardList.png'), name: '25 Icon' },
    { image: require('../../../categoryTempImages/contract.png'), name: '26 Icon' },
    { image: require('../../../categoryTempImages/halfShield.png'), name: '27 Icon' },
    { image: require('../../../categoryTempImages/key.png'), name: '28 Icon' },
    { image: require('../../../categoryTempImages/locked.png'), name: '29 Icon' },
    { image: require('../../../categoryTempImages/police.png'), name: '30 Icon' },
    { image: require('../../../categoryTempImages/shieldHeart.png'), name: '31 Icon' },
    { image: require('../../../categoryTempImages/shieldV2.png'), name: '32 Icon' },
    { image: require('../../../categoryTempImages/spy.png'), name: '33 Icon' },
    { image: require('../../../categoryTempImages/unlock.png'), name: '34 Icon' },
    { image: require('../../../categoryTempImages/userLock.png'), name: '35 Icon' },
    { image: require('../../../categoryTempImages/userShield.png'), name: '36 Icon' },
    { image: require('../../../categoryTempImages/atlasBook.png'), name: '37 Icon' },
    { image: require('../../../categoryTempImages/clipboardCheck.png'), name: '38 2 Icon' },
    { image: require('../../../categoryTempImages/extinguisher.png'), name: '39 Icon' },
    { image: require('../../../categoryTempImages/eyelowVision.png'), name: '40 Icon' },
    { image: require('../../../categoryTempImages/fireCurved.png'), name: '41 3 Icon' },
    { image: require('../../../categoryTempImages/helicopter.png'), name: '42 Icon' },
    { image: require('../../../categoryTempImages/helicopterParking.png'), name: '43 Icon' },
    { image: require('../../../categoryTempImages/recycle.png'), name: '44 Icon' },
    { image: require('../../../categoryTempImages/safetyHelmet.png'), name: '45 4 Icon' },
    { image: require('../../../categoryTempImages/scaleBalanced.png'), name: '46 Icon' },
    { image: require('../../../categoryTempImages/scaleUnbalancedLeft.png'), name: '47 Icon' },
    { image: require('../../../categoryTempImages/scaleUnbalancedRight.png'), name: '48 Icon' },
    { image: require('../../../categoryTempImages/truck.png'), name: '49 Icon' },
    { image: require('../../../categoryTempImages/utensils.png'), name: '50 Icon' },
    { image: require('../../../categoryTempImages/warehouse.png'), name: '51 Icon' },
    { image: require('../../../categoryTempImages/checkDouble.png'), name: '52 Icon' },
    { image: require('../../../categoryTempImages/circleCheckMark.png'), name: '53 Icon' },
    { image: require('../../../categoryTempImages/mars.png'), name: '54 Icon' },
    { image: require('../../../categoryTempImages/marsDouble.png'), name: '55 Icon' },
    { image: require('../../../categoryTempImages/onlyCheckMark.png'), name: '56 Icon' },
    { image: require('../../../categoryTempImages/penRuler.png'), name: '57 Icon' },
    { image: require('../../../categoryTempImages/personDouble.png'), name: '58 Icon' },
    { image: require('../../../categoryTempImages/plane.png'), name: '59 Icon' },
    { image: require('../../../categoryTempImages/streetview.png'), name: '60 Icon' },
    { image: require('../../../categoryTempImages/trophy.png'), name: '61 Icon' },
    { image: require('../../../categoryTempImages/venus.png'), name: '62 Icon' },
    { image: require('../../../categoryTempImages/venusDouble.png'), name: '63 Icon' },
    { image: require('../../../categoryTempImages/venusMars.png'), name: '64 Icon' },
    { image: require('../../../categoryTempImages/wrench.png'), name: '65 Icon' },
    { image: require('../../../categoryTempImages/addressCardUser.png'), name: '66 Icon' },
    { image: require('../../../categoryTempImages/calculatorSolidV2.png'), name: '67 Icon' },
    { image: require('../../../categoryTempImages/chartLine.png'), name: '68 Icon' },
    { image: require('../../../categoryTempImages/chartPie.png'), name: '69 Icon' },
    { image: require('../../../categoryTempImages/cloudArrowDown.png'), name: '70 Icon' },
    { image: require('../../../categoryTempImages/computer.png'), name: '71 Icon' },
    { image: require('../../../categoryTempImages/database.png'), name: '72 Icon' },
    { image: require('../../../categoryTempImages/desktopComputer.png'), name: '73 Icon' },
    { image: require('../../../categoryTempImages/dove.png'), name: '74 Icon' },
    { image: require('../../../categoryTempImages/drowning.png'), name: '75 Icon' },
    { image: require('../../../categoryTempImages/globe.png'), name: '76 Icon' },
    { image: require('../../../categoryTempImages/graduationCap.png'), name: '77 Icon' },
    { image: require('../../../categoryTempImages/handHeart.png'), name: '78 Icon' },
    { image: require('../../../categoryTempImages/handShake.png'), name: '79 Icon' },
    { image: require('../../../categoryTempImages/horseHead.png'), name: '80 Icon' },
    { image: require('../../../categoryTempImages/houseSignal.png'), name: '81 Icon' },
    { image: require('../../../categoryTempImages/inTheCloud.png'), name: '82 Icon' },
    { image: require('../../../categoryTempImages/laptopCode.png'), name: '83 Icon' },
    { image: require('../../../categoryTempImages/leaf.png'), name: '84 Icon' },
    { image: require('../../../categoryTempImages/mobile.png'), name: '85 Icon' },
    { image: require('../../../categoryTempImages/networkV2.png'), name: '86 Icon' },
    { image: require('../../../categoryTempImages/paw.png'), name: '87 Icon' },
    { image: require('../../../categoryTempImages/ribbon.png'), name: '88 Icon' },
    { image: require('../../../categoryTempImages/satelliteDish.png'), name: '89 Icon' },
    { image: require('../../../categoryTempImages/seedling.png'), name: '90 Icon' },
    { image: require('../../../categoryTempImages/shieldCat.png'), name: '91 Icon' },
    { image: require('../../../categoryTempImages/shieldDog.png'), name: '92 Icon' },
    { image: require('../../../categoryTempImages/squareCheckMark.png'), name: '93 Icon' },
    { image: require('../../../categoryTempImages/stickyNote.png'), name: '94 Icon' },
    { image: require('../../../categoryTempImages/tempDown.png'), name: '95 Icon' },
    { image: require('../../../categoryTempImages/tempUp.png'), name: '96 Icon' },
    { image: require('../../../categoryTempImages/trophyV2.png'), name: '97 Icon' },
    { image: require('../../../categoryTempImages/userGraduation.png'), name: '98 Icon' },
    { image: require('../../../categoryTempImages/userReading.png'), name: '99 Icon' },
    { image: require('../../../categoryTempImages/wifiRange.png'), name: '100 Icon' },
    { image: require('../../../categoryTempImages/bioHazard.png'), name: '101 checkbox Icon' },
    { image: require('../../../categoryTempImages/biohazardWarning.png'), name: '102 Icon' },
    { image: require('../../../categoryTempImages/boneHuman.png'), name: '103 3 Icon' },
    { image: require('../../../categoryTempImages/capsulesMedicine.png'), name: '104 Icon' },
    { image: require('../../../categoryTempImages/chartSimple.png'), name: '105 Icon' },
    { image: require('../../../categoryTempImages/dna.png'), name: '106 Icon' },
    { image: require('../../../categoryTempImages/earDeaf.png'), name: '107 Icon' },
    { image: require('../../../categoryTempImages/envelopeClosed.png'), name: '108 Icon' },
    { image: require('../../../categoryTempImages/eyeDropper.png'), name: '109 Icon' },
    { image: require('../../../categoryTempImages/eyeSolidOpen.png'), name: '110 Icon' },
    { image: require('../../../categoryTempImages/heartV3.png'), name: '111 Icon' },
    { image: require('../../../categoryTempImages/idCardClipOn.png'), name: '112 Icon' },
    { image: require('../../../categoryTempImages/lungsHuman.png'), name: '113 Icon' },
    { image: require('../../../categoryTempImages/medicinePills.png'), name: '114 Icon' },
    { image: require('../../../categoryTempImages/microscopeLab.png'), name: '115 Icon' },
    { image: require('../../../categoryTempImages/nurseUser.png'), name: '116 Icon' },
    { image: require('../../../categoryTempImages/prescriptionBottle.png'), name: '117 Icon' },
    { image: require('../../../categoryTempImages/questionMark.png'), name: '118 Icon' },
    { image: require('../../../categoryTempImages/syringeV5.png'), name: '119 Icon' },
    { image: require('../../../categoryTempImages/teethClosed.png'), name: '120 Icon' },
    { image: require('../../../categoryTempImages/userDoctor.png'), name: '121 Icon' },
    { image: require('../../../categoryTempImages/userWithMask.png'), name: '122 Icon' },
  ];

  if (!isVisible) return null;
  return (
    <Modal isOpen={isVisible} onClose={onClose} style={{ minWidth: '50vw' }}>
      <ModalBody style={{ display: 'flex', flexWrap: 'wrap' }}>
        <>
          {icons.map(({ image, name }) => (
            <div key={name} style={{ margin: '1rem' }} onClick={() => onSelect(image)}>
              <img src={image} alt={name} style={{ width: 60, height: 60 }} />
            </div>
          ))}
        </>
      </ModalBody>
      <ModalFooter>
        <Button style={{ display: 'block', width: '100%' }} color="dark" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImagePicker;

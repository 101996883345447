import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

interface AddCategoryButtonProps {
  onClick: () => void;
  text: string;
}

export const AddCategoryButton: React.FC<AddCategoryButtonProps> = ({ onClick, text }) => {
  return (
    <div className="category-item rounded shadow-sm acc-mng-module" onClick={onClick} style={{ cursor: 'pointer' }}>
      <FontAwesomeIcon icon={faPlus} className="fa-2xl" />
      <div className="name">
        <div>{text}</div>
      </div>
    </div>
  );
};

import { FC, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from '../../../Buttons/Buttons';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
//import { useFirestore } from '../../../ContextProviders/Firebase';
import { Status } from '../../../ContextProviders/Auth';
import { toast } from 'react-toastify';
import { LoadingSVG } from '../../Loading/Loading';
import { StaffRoleTire } from '@eir/core';
import { WithID } from '../../../../Types';
import { useConcreteProject } from '../../../ContextProviders/ProjectContext';

interface Props {
  role?: StaffRoleTire & WithID;
  onClose: (deleted?: boolean) => void;
}
const DeleteRoleModal: FC<Props> = ({ onClose, role }) => {
  const { strings } = useLocalization();
  const project = useConcreteProject();

  //const firestore = useFirestore();
  const [requestStatus, setRequestStatus] = useState(Status.IDLE);
  const [baseUrl, setBaseUrl] = useState<string>();

  useEffect(() => {
    if (project && project.firebase && project.firebase.project) {
      setBaseUrl(`https://europe-west3-${project.firebase.project}.cloudfunctions.net/MultiAccountApi`);
    }
  }, [project]);

  const handleDelete = async () => {
    if (!role || !baseUrl) {
      onClose(false);
      return;
    }
    setRequestStatus(Status.IN_FLIGHT);
    try {
      const url = `${baseUrl}/delete-role/${role.fId}`;
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setRequestStatus(Status.IDLE);
      if (response.ok) {
        onClose(true);
      } else {
        onClose(false);
      }
    } catch (error) {
      setRequestStatus(Status.IDLE);
      toast.error(strings.settings.roles.delete.error);
    }
  };
  return (
    <Modal isOpen={!!role}>
      <ModalHeader>
        {strings.settings.roles.delete.modalHeader}({role?.name})
      </ModalHeader>
      <ModalBody>{strings.settings.roles.delete.modalBody}</ModalBody>
      <ModalFooter>
        <Button onClick={handleDelete} disabled={requestStatus === Status.IN_FLIGHT} color="danger">
          {requestStatus === Status.IN_FLIGHT ? <LoadingSVG size={20} /> : strings.global.delete}
        </Button>
        <Button onClick={() => onClose()}>{strings.global.cancel}</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteRoleModal;

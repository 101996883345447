import { ReactElement, useEffect } from 'react';
import { configActions } from '../../../Hooks/DatabaseActions';
import { EditableContent } from '../../ArticleBody/ArticleBodyEdit';
import { useIsDraft, useProjectConfig, useProjectConfigDraft } from '../../ContextProviders/AppContext';
import { useFirestore } from '../../ContextProviders/Firebase';
import { useConcreteProject, useProjectTitle } from '../../ContextProviders/ProjectContext';
import { FabButton } from '../../FabButton/FabButton';
import { PlatformContentFrame } from '../../PlatformContentFrame/PlatformContentFrame';

export const LandingPage = (): ReactElement => {
  const [draftMode] = useIsDraft();
  const title = useProjectTitle();

  useEffect(() => {
    if (title) document.title = `${title}`;
  }, [title]);

  return (
    <>
      {draftMode ? <LandingPageEdit /> : <LandingPagePublish />}
      <FabButton id={''} />
    </>
  );
};
export const LandingPageEdit = (): ReactElement => {
  const config = useProjectConfigDraft();
  const project = useConcreteProject();
  const firestore = useFirestore();

  const title = useProjectTitle();
  useEffect(() => {
    document.title = `${title}`;
  }, [title]);

  const save = async (content: string) => configActions(firestore, project.id).update({ aboutInfo: content });

  if (config.loading || config.error) return <></>;

  return (
    <div className="editable-content padded-container">
      <EditableContent content={config.doc.aboutInfo} save={save} />
    </div>
  );
};
export const LandingPagePublish = (): ReactElement => {
  const config = useProjectConfig();
  if (config.loading || config.error) return <></>;

  return <PlatformContentFrame body={config.doc.aboutInfo} />;
};

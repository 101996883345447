import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { ReactElement, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, FormFeedback, Input, Label, InputGroup, FormGroup } from 'reactstrap';
import { toasts } from '../../../shared';
import { useAuth } from '../../ContextProviders/Auth';
import { DarkButton } from '../../Buttons/Buttons';
import './ForgotPassword.scss';
import { useFirebase } from '../../ContextProviders/Firebase';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';

export const ForgotPassword = (): ReactElement => {
  const localization = useLocalization();
  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const auth = useAuth();
  const firebase = useFirebase();
  const project = useConcreteProject();

  const sendReset = () => {
    if (invalidEmail || email.trim().length === 0) return toasts.error(localization.strings.settings.emailError);

    sendPasswordResetEmail(getAuth(firebase), email)
      .then(() => {
        toasts.success(localization.strings.auth.resetEmailSent);
      })
      .catch(() => {
        toasts.error(localization.strings.auth.resetEmailFail);
      });
  };
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const _onChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim().length > 0) {
      setInvalidEmail(!validateEmail(e.target.value));
    } else {
      setInvalidEmail(false);
    }
  };

  const _onBlur: React.FocusEventHandler<HTMLInputElement> | undefined = () => {
    if (email.trim().length === 0) setInvalidEmail(false);
  };

  if (auth.isAdmin || auth.isStaff) return <Redirect to="/" />;

  return (
    <div className="reset-password-page">
      <div className="text-center">
        <h2 className="font-weight-bold">{localization.strings.auth.resetPassword}</h2>
        <p>
          {localization.strings.auth.recoveryEmailInfo}&nbsp;
          <a
            style={{ color: '#ff0000' }}
            href={`mailto:${project.support !== undefined ? project.support : 'contact@infosynk.se'}`}
          >
            {localization.strings.auth.here}
          </a>
        </p>
      </div>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <Label className="font-weight-bold" for="email-address-input">
            {localization.strings.auth.emailTitle}
          </Label>
          <InputGroup style={{ position: 'relative' }}>
            <FontAwesomeIcon
              style={{
                height: '1rem',
                width: '1rem',
                position: 'absolute',
                top: '0.7rem',
                left: '0.65rem',
                zIndex: 10,
              }}
              icon={faEnvelope}
            />
            <Input
              type="email"
              value={email}
              onChange={_onChange}
              invalid={invalidEmail}
              valid={!invalidEmail && email.trim().length > 0}
              onBlur={_onBlur}
              placeholder="example@domain.com"
              id="email-address-input"
            />
            <FormFeedback>{localization.strings.settings.emailError}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <DarkButton onClick={sendReset} style={{ width: '100%', margin: '0' }} disabled={invalidEmail}>
          <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: 5 }} />
          {localization.strings.global.send}
        </DarkButton>
      </Form>
    </div>
  );
};

import { ReactElement, useEffect } from 'react';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { Col, Container, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import { useStyle } from '../../ContextProviders/Style';
import { Status, useAuth } from '../../ContextProviders/Auth';
import { CMSUser, StripeUser, Subscription } from '../../../Types';
import { useDocument } from '../../../Hooks/Database';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { useReadSubscription } from '../../../Hooks/useReadSubscription';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading } from '../Loading/Loading';
import { IconButton } from '../../Buttons/Buttons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { getAgroScanAccessDescriptionRecords } from '../../../util/DataHelpers';
import { AgroScanMutation, AgroScanQuery } from '@eir/core';
import { usePolygons } from '../../../Hooks/usePolygons';
import { useParams } from 'react-router-dom';

interface Params {
  userId?: string;
}

enum Currency {
  'brl' = 'R$',
  'usd' = '$',
}

export const UserProfileManagement = (): ReactElement => {
  const localization = useLocalization();
  const projectConfig = useProjectConfig();
  const { enableVultusAPI } = useConcreteProject();
  const style = useStyle();
  const params = useParams<Params>();
  const { user: authUser } = useAuth();
  const userId = params.userId ?? authUser?.uid;

  const {
    doc: user,
    error: userError,
    loading: userLoading,
  } = useDocument<CMSUser>(userId ? `cmsUser/${userId}` : undefined, {});
  const {
    doc: stripeUser,
    error: stripeUserError,
    loading: stripeUserLoading,
  } = useDocument<StripeUser>(userId ? `/stripe/data/users/${userId}` : undefined, {});
  const {
    doc: subscription,
    error: subscriptionError,
    loading: subDocLoading,
  } = useDocument<Subscription>(
    stripeUser?.subscription ? `/stripe/data/subscriptions/${stripeUser.subscription}` : undefined,
    {},
  );

  const roundToTwoDecimals = (value: number) => {
    return value.toFixed(2);
  };

  const currencyFormat = (value: string | undefined) => {
    if (!value) return;
    return Currency[value];
  };

  const { status, subscription: subscriptionWithPrice } = useReadSubscription({
    productId: stripeUser.subscription,
    priceId: stripeUser.priceId,
  });

  const subscriptionLoading = stripeUser.subscription && subDocLoading;

  const { polygons, polygonsLoading, statistics } = usePolygons({ benefactor: params.userId ?? authUser?.uid });

  useEffect(() => {
    document.title = `${localization.strings.settings.userProfile.title} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.settings.userProfile.title]);

  const AccountInfo = () => {
    return (
      <div>
        <h5 className="ms-2" style={{ fontWeight: 'bold' }}>
          {localization.strings.settings.userProfile.account.title}
        </h5>
        <Container
          className="ps-2 pe-2 pt-3 pb-3"
          style={{ backgroundColor: `${style.primaryDark}`, color: 'white', borderRadius: 10 }}
        >
          <Row xs={4} md={4}>
            <Col style={{ fontWeight: 'bold' }}>{localization.strings.settings.userProfile.account.firstName}</Col>
            <Col>{user.firstName}</Col>
          </Row>
          <Row xs={4} md={4}>
            <Col style={{ fontWeight: 'bold' }}>{localization.strings.settings.userProfile.account.lastName}</Col>
            <Col>{user.lastName}</Col>
          </Row>
          <Row xs={4} md={4}>
            <Col style={{ fontWeight: 'bold' }}>{localization.strings.settings.userProfile.account.email}</Col>
            <Col>{user.email}</Col>
          </Row>
          <Row xs={4} md={4}>
            <Col style={{ fontWeight: 'bold' }}>{localization.strings.settings.userProfile.account.phone}</Col>
            <Col>{user.phoneNumber}</Col>
          </Row>
        </Container>
      </div>
    );
  };

  const StatisticsInfo = () => {
    return (
      <div>
        <h5 className="ms-2" style={{ fontWeight: 'bold' }}>
          {localization.strings.settings.userProfile.statistics.title}
        </h5>
        <Container
          className="ps-2 pe-2 pt-3 pb-3"
          style={{
            textAlign: 'center',
            backgroundColor: `${style.primaryDark}`,
            color: 'white',
            fontWeight: 'bold',
            borderRadius: 10,
          }}
        >
          <Row xs={3} md={3} style={{ fontSize: 'x-large' }}>
            <Col>{statistics.polygons}</Col>
            <Col>{roundToTwoDecimals(statistics.hectares)}</Col>
            <Col>{subscription.hectares ? roundToTwoDecimals(subscription.hectares - statistics.hectares) : '0'}</Col>
          </Row>
          <Row xs={3} md={3}>
            <Col>{localization.strings.settings.userProfile.statistics.polygons}</Col>
            <Col>{localization.strings.settings.userProfile.statistics.hectaresUsed}</Col>
            <Col>{localization.strings.settings.userProfile.statistics.hectaresAvailable}</Col>
          </Row>
        </Container>
      </div>
    );
  };
  const SubscriptionInfo = () => {
    const price = subscriptionWithPrice?.prices[0];

    return (
      <div>
        <h5 className="ms-2" style={{ fontWeight: 'bold' }}>
          {localization.strings.settings.userProfile.subscription.title}
        </h5>
        <Container
          className="ps-2 pe-2 pt-3 pb-3"
          style={{ backgroundColor: `${style.primaryDark}`, color: 'white', fontWeight: 'bold', borderRadius: 10 }}
        >
          <p className="mb-1">{localization.strings.settings.userProfile.subscription.level}</p>
          <h2>
            {subscriptionWithPrice?.name ?? localization.strings.settings.userProfile.subscription.noSubscription}
          </h2>

          {subscription.access && (
            <>
              <p className="mb-0">{localization.strings.settings.userProfile.subscription.perks.title}</p>
              <IconButton
                type="button"
                id={'subscription-access-info-button'}
                icon={faCircleInfo}
                theme="dark"
                text={localization.strings.global.view}
              />
              <UncontrolledPopover placement="right-start" target={'subscription-access-info-button'} trigger="click">
                <PopoverBody>
                  <>
                    {Object.entries(getAgroScanAccessDescriptionRecords(localization.strings)).map(
                      ([entry, description]: [AgroScanMutation | AgroScanQuery, string]) =>
                        subscription.access?.[entry] ? <div key={entry}>{description}</div> : null,
                    )}
                  </>
                </PopoverBody>
              </UncontrolledPopover>
            </>
          )}

          <p>{localization.strings.settings.userProfile.subscription.cost}</p>
          <h2>
            {currencyFormat(price?.currency)} {price?.amount ?? '-'} /{' '}
            {price?.interval === 'month'
              ? localization.strings.settings.userProfile.subscription.month
              : localization.strings.settings.userProfile.subscription.year}
          </h2>
        </Container>
      </div>
    );
  };

  const PolygonsInfo = () => {
    return (
      <div>
        <h5 className="ms-2" style={{ fontWeight: 'bold' }}>
          {localization.strings.settings.userProfile.statistics.polygons}
        </h5>
        <Container>
          <Row className="p-2" style={{ fontWeight: 'bold', backgroundColor: '#F0F0F0' }}>
            <Col role="gridcell">{localization.strings.settings.userProfile.statistics.name}</Col>
            <Col>{localization.strings.settings.userProfile.statistics.hectares}</Col>
          </Row>

          {polygons.length > 0 ? (
            polygons.map((polygon, index) => (
              <Row className="p-2" style={{ backgroundColor: index % 2 === 0 ? '#E8E8E8' : '##F8F8F8' }}>
                <Col>{polygon?.label}</Col>
                <Col>{polygon?.hectares}</Col>
              </Row>
            ))
          ) : (
            <div style={{ textAlign: 'center' }}>
              {localization.strings.settings.userProfile.statistics.emptyPolygons}
            </div>
          )}
        </Container>
      </div>
    );
  };

  const getPageContent = () => {
    if (userError || stripeUserError || subscriptionError || status === Status.ERROR)
      return <ErrorPage error={new Error(localization.strings.error.unknown)} />;
    if (userLoading || stripeUserLoading || subscriptionLoading || status === Status.IN_FLIGHT || polygonsLoading)
      return <Loading />;

    return (
      <Container className="mx-0">
        <Row className="my-5">
          <Col className="me-2">
            <AccountInfo />
            <br></br>
            {enableVultusAPI && <StatisticsInfo />}
          </Col>
          <Col className="ms-2">
            <SubscriptionInfo />
          </Col>
        </Row>

        {enableVultusAPI && (
          <Row className="my-5">
            <Col>
              <PolygonsInfo />
            </Col>
          </Row>
        )}
      </Container>
    );
  };
  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.userProfile.title}</h1>
          <hr />

          {getPageContent()}
        </div>
      </main>
    </PageWithSidebar>
  );
};

import { Key, ReactElement, useEffect, useState } from 'react';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectConfig, useSubprojects } from '../../ContextProviders/AppContext';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { doc, Firestore, Timestamp, DocumentReference, writeBatch, deleteField } from 'firebase/firestore';
import './Transfer.scss';
import { useConcreteProject, useProjectTitle } from '../../ContextProviders/ProjectContext';
import { toasts } from '../../../shared';
import { useFirestore } from '../../ContextProviders/Firebase';
import { ArticleData, SectionData } from '@eir/core/dist/models';
import { Article, Category, IsDraft, Ordered, Section, Subproject, WithLastUpdateDate } from '../../../Types';
import { useCollection } from '../../../Hooks/Database';
import { useAuth } from '../../ContextProviders/Auth';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';

export interface ListProject {
  fId: string;
  name: string;
  icon: string;
  creationDate: Timestamp;
  lastUpdate: Timestamp;
  admin: string;
}

export type ArticleCopy = ArticleData & WithLastUpdateDate & Ordered;
export type SectionCopy = SectionData & WithLastUpdateDate & Ordered;

export interface WithDocReference {
  siblingDocReference: DocumentReference;
  temporary: boolean;
}

type Order = 'asc' | 'desc';
type Attribute = 'fId' | 'name' | 'category';
type SortOptions = {
  order: Order;
  attribute: Attribute;
};

type SortButtonProps = {
  attribute: Attribute;
  disabled: boolean;
  sortOptions: SortOptions;
  handleClick: (attribute: Attribute) => void;
};

const SortButton = ({ attribute, disabled, sortOptions, handleClick }: SortButtonProps): ReactElement => {
  const localization = useLocalization();
  const { sorting } = localization.strings.settings.projectValues;
  const title = sorting[attribute];

  return (
    <Col onClick={() => handleClick(attribute)} className="sort-button" disabled={disabled}>
      {title}
      {!disabled && (
        <FontAwesomeIcon
          icon={sortOptions.attribute === attribute && sortOptions.order === 'desc' ? faAngleUp : faAngleDown}
          className={` mx-1 sort-indicator ${sortOptions.attribute === attribute && 'active'}`}
        ></FontAwesomeIcon>
      )}
    </Col>
  );
};

export const TransferManager = (): ReactElement => {
  const project = useConcreteProject();
  const projectConfig = useProjectConfig();
  const localization = useLocalization();
  const firestore = useFirestore();
  const title = useProjectTitle();
  const subprojects = useSubprojects().docs;
  const auth = useAuth();

  const [phase, setPhase] = useState(1);
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
  const [selectedArticleIds, setSelectedArticleIds] = useState<string[]>([]);
  const [selectedProjectIdsCopy, setSelectedProjectIdsCopy] = useState<string[]>([]);
  const [selectedArticleIdsCopy, setSelectedArticleIdsCopy] = useState<string[][]>([]);
  const [checkProjectAll, setCheckProjectAll] = useState(false);
  const [checkArticleAll, setCheckArticleAll] = useState(false);
  const [checkArticleAllCopy, setCheckArticleAllCopy] = useState<boolean[]>([]);
  const [hideArticleCopy, setHideArticleCopy] = useState<boolean[]>([]);
  const [projectFrom, setProjectFrom] = useState<string>(project.id);

  const categories = useCollection<Category>(auth.isAdmin ? `project/${projectFrom}/category` : undefined, {}).docs;
  const articlesDraft = useCollection<Article, IsDraft>(
    auth.isAdmin ? `project/${projectFrom}/articleDraft` : undefined,
    { isDraft: true },
  ).docs;
  const articles = useCollection<Article>(auth.isAdmin ? `project/${projectFrom}/article` : undefined, {}).docs;
  const sectionsDraft = useCollection<Section, IsDraft>(
    auth.isAdmin ? `project/${projectFrom}/sectionDraft` : undefined,
    { isDraft: true },
  ).docs;
  const sections = useCollection<Section>(auth.isAdmin ? `project/${projectFrom}/section` : undefined, {}).docs;

  const [sortedArticles, setSortedArticles] = useState<Article[]>([...articles]);
  const [sortedProjects, setSortedProjects] = useState<Subproject[]>([...subprojects]);
  const [projectSortOptions, setProjectSortOptions] = useState<SortOptions>({ attribute: 'name', order: 'asc' });
  const [articleSortOptions, setArticleSortOptions] = useState<SortOptions>({ attribute: 'name', order: 'asc' });

  const projectColumns = [
    { label: 'project', accessor: 'checkbox', type: 'checkbox', sortable: false },
    {
      label: localization.strings.settings.projectValues.sorting.name,
      accessor: 'name',
      type: 'string',
      sortable: true,
    },
  ];

  const articleColumns = [
    { label: 'article', accessor: 'checkbox', type: 'checkbox', isProject: false, sortable: false },
    {
      label: localization.strings.settings.projectValues.sorting.category,
      accessor: 'category',
      type: 'category',

      sortable: true,
    },
    {
      label: localization.strings.settings.projectValues.sorting.fId,
      accessor: 'fId',
      type: 'string',
      sortable: true,
    },
    {
      label: localization.strings.settings.projectValues.sorting.name,
      accessor: 'name',
      type: 'string',
      sortable: true,
    },
  ];

  const copyAllDocuments = async (firestore: Firestore) => {
    for (let i = 0; i < selectedProjectIdsCopy.length; i++) {
      if (selectedProjectIdsCopy[i] === projectFrom) continue;

      const includeSections: string[] = [];
      const batchArticle = writeBatch(firestore);
      const batchArticleDraft = writeBatch(firestore);
      const batchSection = writeBatch(firestore);
      const batchSectionDraft = writeBatch(firestore);
      const batchRemoveTemporaryArticleDraft = writeBatch(firestore);
      const batchRemoveTemporarySectionDraft = writeBatch(firestore);

      for (const selectedArticle of selectedArticleIdsCopy[i]) {
        for (const article of articlesDraft) {
          if (article.fId === selectedArticle) {
            const articleCopy: ArticleCopy & WithDocReference = {
              category: article.category,
              content: article.content,
              name: article.name,
              orderIndex: article.orderIndex,
              lastUpdated: Timestamp.now(),
              siblingDocReference: doc(firestore, `project/${selectedProjectIdsCopy[i]}/article/${article.fId}`),
              temporary: true,
            };

            if (article.section !== undefined) articleCopy.section = article.section;
            if (article.lastUpdatedBy !== undefined) articleCopy.lastUpdatedBy = article.lastUpdatedBy;
            if (article.deleted !== undefined) articleCopy.deleted = article.deleted;
            if (article.state !== undefined) articleCopy.state = article.state;

            batchArticleDraft.set(
              doc(firestore, `project/${selectedProjectIdsCopy[i]}/articleDraft/${article.fId}`),
              articleCopy,
            );

            batchRemoveTemporaryArticleDraft.update(
              doc(firestore, `project/${selectedProjectIdsCopy[i]}/articleDraft/${article.fId}`),
              { temporary: deleteField() },
            );
          }
        }

        for (const article of articles) {
          if (article.fId === selectedArticle) {
            if (article.section !== null && article.section !== undefined)
              if (!includeSections.includes(article.section)) includeSections.push(article.section);

            const articleCopy: ArticleCopy = {
              category: article.category,
              content: article.content,
              name: article.name,
              orderIndex: article.orderIndex,
              lastUpdated: Timestamp.now(),
            };

            if (article.section !== undefined) articleCopy.section = article.section;
            if (article.lastUpdatedBy !== undefined) articleCopy.lastUpdatedBy = article.lastUpdatedBy;
            if (article.deleted !== undefined) articleCopy.deleted = article.deleted;
            if (article.state !== undefined) articleCopy.state = article.state;

            batchArticle.set(
              doc(firestore, `project/${selectedProjectIdsCopy[i]}/article/${article.fId}`),
              articleCopy,
            );
          }
        }
      }

      for (const section of sectionsDraft)
        if (includeSections.includes(section.fId)) {
          const sectionCopy: SectionCopy & WithDocReference = {
            category: section.category,
            name: section.name,
            orderIndex: section.orderIndex,
            lastUpdated: Timestamp.now(),
            siblingDocReference: doc(firestore, `project/${selectedProjectIdsCopy[i]}/section/${section.fId}`),
            temporary: true,
          };

          if (section.deleted !== undefined) sectionCopy.deleted = section.deleted;

          batchSection.set(
            doc(firestore, `project/${selectedProjectIdsCopy[i]}/sectionDraft/${section.fId}`),
            sectionCopy,
          );

          batchRemoveTemporaryArticleDraft.update(
            doc(firestore, `project/${selectedProjectIdsCopy[i]}/sectionDraft/${section.fId}`),
            { temporary: deleteField() },
          );
        }

      for (const section of sections)
        if (includeSections.includes(section.fId)) {
          const sectionCopy: SectionCopy = {
            category: section.category,
            name: section.name,
            orderIndex: section.orderIndex,
            lastUpdated: Timestamp.now(),
          };

          if (section.deleted !== undefined) sectionCopy.deleted = section.deleted;

          batchSectionDraft.set(
            doc(firestore, `project/${selectedProjectIdsCopy[i]}/section/${section.fId}`),
            sectionCopy,
          );
        }

      await batchArticle.commit();
      await batchArticleDraft.commit();
      await batchSection.commit();
      await batchSectionDraft.commit();

      await batchRemoveTemporaryArticleDraft.commit();
      await batchRemoveTemporarySectionDraft.commit();
    }
    toasts.success(localization.strings.settings.transfer.finished);
  };

  const _changeProjectFrom = (event: { target: { value: string } }) => {
    setProjectFrom(event.target.value);
  };

  const handleProjectCheckClick = (fId: string) => {
    if (selectedProjectIds.includes(fId)) setSelectedProjectIds(selectedProjectIds.filter((i) => i !== fId));
    else setSelectedProjectIds([...selectedProjectIds, fId]);
  };

  const handleProjectCheckAllClick = () => {
    if (!checkProjectAll) {
      setCheckProjectAll(true);
      setSelectedProjectIds(subprojects.map((a) => a.fId));
    } else {
      setCheckProjectAll(false);
      setSelectedProjectIds([]);
    }
  };

  const handleArticleCheckClick = (fId: string) => {
    if (selectedArticleIds.includes(fId)) setSelectedArticleIds(selectedArticleIds.filter((i) => i !== fId));
    else setSelectedArticleIds([...selectedArticleIds, fId]);
  };

  const handleArticleCheckAllClick = () => {
    if (!checkArticleAll) {
      setCheckArticleAll(true);
      setSelectedArticleIds(articles.map((a) => a.fId));
    } else {
      setCheckArticleAll(false);
      setSelectedArticleIds([]);
    }
  };

  const handleArticleCopyCheckClick = (fId: string, set: number) => {
    setSelectedArticleIdsCopy(
      selectedArticleIdsCopy.map(function (arr, index) {
        if (index === set) {
          if (arr.includes(fId)) arr = arr.filter((i) => i !== fId);
          else arr = [...arr, fId];
        }
        return arr;
      }),
    );
  };

  const handleArticleCopyCheckAllClick = (set: number) => {
    setCheckArticleAllCopy(
      checkArticleAllCopy.map(function (i, index) {
        if (index === set) return !i;
        return i;
      }),
    );

    setSelectedArticleIdsCopy(
      selectedArticleIdsCopy.map(function (arr, index) {
        if (index === set) {
          if (checkArticleAllCopy[set]) arr = [];
          else arr = selectedArticleIds;
        }
        return arr;
      }),
    );
  };

  const Table = ({
    data,
    columns,
    selected,
    set,
    checkAll,
    handleCheckAll,
    handleCheck,
    include,
    exclude,
    project,
    sortOptions,
    handleSort,
  }) => {
    if (include !== null && include !== undefined) {
      if (Array.isArray(include)) data = data.filter((i) => include.includes(i.fId));
      else if (typeof include === 'string') data = data.filter((i: { fId: string }) => i.fId === include);
    }

    if (exclude !== null && exclude !== undefined) {
      if (Array.isArray(exclude)) data = data.filter((i) => !exclude.includes(i.fId));
      else if (typeof exclude === 'string') data = data.filter((i: { fId: string }) => i.fId !== exclude);
    }

    const dataCount = data.length;

    return (
      <table className="mx-auto table table-bordered table-striped text-center">
        <TableHead
          {...{
            dataCount,
            columns,
            set,
            checkAll,
            handleCheckAll,
            project,
            sortOptions,
            handleSort,
          }}
        />
        <TableBody {...{ data, columns, selected, set, handleCheck }} />
      </table>
    );
  };

  const TableHead = ({ dataCount, columns, set, checkAll, handleCheckAll, project, sortOptions, handleSort }) => {
    return (
      <thead>
        {project !== null && project !== undefined ? (
          <tr>
            <th>
              <button
                className="btn btn-primary"
                style={{ margin: '0 0 0 0.4rem' }}
                onClick={() => {
                  setHideArticleCopy(
                    hideArticleCopy.map(function (i, j) {
                      if (j === set) return !i;
                      return i;
                    }),
                  );
                }}
              >
                {hideArticleCopy[set]
                  ? localization.strings.settings.transfer.showTable
                  : localization.strings.settings.transfer.hideTable}
              </button>
            </th>
            <th>{project}</th>
          </tr>
        ) : null}

        <tr>
          {columns.map(({ accessor, type }) => {
            if (type === 'checkbox') {
              return (
                <th key={`table-th-checkbox-${accessor}`} scope="col">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="projectCheckboxAll"
                    checked={checkAll}
                    onClick={() => handleCheckAll(set)}
                    onChange={(e) => {
                      /* */
                    }}
                  ></input>
                </th>
              );
            }

            return (
              <th key={`table-th-${accessor}`} style={{ width: '30%' }}>
                <SortButton
                  disabled={dataCount === 0}
                  attribute={accessor}
                  sortOptions={sortOptions}
                  handleClick={handleSort}
                />
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const TableBody = ({ data, columns, selected, set, handleCheck }) => {
    let checkHide = false;
    if (typeof set === 'number') checkHide = hideArticleCopy[set];

    return (
      <tbody className={`${checkHide ? 'd-none' : ''}`}>
        {data.map((data: { fId: Key }) => {
          return (
            <tr key={`body-tr-${data.fId}`}>
              {columns.map(({ accessor, type }) => {
                let tData = data[accessor] ? data[accessor] : '——';
                if (type === 'date') tData = tData.toDate().toDateString();
                if (type === 'category') {
                  for (const c of categories) {
                    if (tData === c.fId) {
                      tData = c.name;
                      break;
                    }
                  }
                  return <td key={`body-td-category-${data.fId}`}>{tData}</td>;
                }
                if (type === 'checkbox') {
                  return (
                    <th key={`body-td-checkbox-${data.fId}`}>
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={selected.includes(data.fId)}
                        onClick={() => handleCheck(data.fId, set)}
                        onChange={(e) => {
                          /* */
                        }}
                      ></input>
                    </th>
                  );
                }

                return <td key={accessor}>{tData}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  const handleProjectsSortClick = (attribute: Attribute) => {
    if (projectSortOptions.attribute === attribute) {
      setProjectSortOptions({ attribute, order: projectSortOptions.order === 'asc' ? 'desc' : 'asc' });
    } else {
      setProjectSortOptions({ attribute, order: 'asc' });
    }
  };

  const handleArticlesSortClick = (attribute: Attribute) => {
    if (articleSortOptions.attribute === attribute) {
      setArticleSortOptions({ attribute, order: articleSortOptions.order === 'asc' ? 'desc' : 'asc' });
    } else {
      setArticleSortOptions({ attribute, order: 'asc' });
    }
  };

  const drawPhase1 = () => {
    return (
      <div>
        {subprojects.length > 0 ? (
          <form>
            <p className="mt-3">{localization.strings.settings.transfer.projectFrom}</p>
            <select id="selectProjectFrom" className="mt-1" onChange={_changeProjectFrom} defaultValue={project.id}>
              {subprojects.map((item) => (
                <option key={`projectFrom-${item.fId}`} value={item.fId}>
                  {item.name}
                </option>
              ))}
            </select>
          </form>
        ) : (
          <></>
        )}

        <br></br>
        <div className="mx-auto" style={{ width: '70%' }}>
          <Table
            data={sortedProjects}
            columns={projectColumns}
            selected={selectedProjectIds}
            set={null}
            checkAll={checkProjectAll}
            handleCheck={handleProjectCheckClick}
            handleCheckAll={handleProjectCheckAllClick}
            include={null}
            exclude={projectFrom}
            project={null}
            sortOptions={projectSortOptions}
            handleSort={handleProjectsSortClick}
          />
        </div>

        <br></br>
        <div className="mx-auto" style={{ width: '70%' }}>
          <Table
            data={sortedArticles}
            columns={articleColumns}
            selected={selectedArticleIds}
            set={null}
            checkAll={checkArticleAll}
            handleCheck={handleArticleCheckClick}
            handleCheckAll={handleArticleCheckAllClick}
            include={null}
            exclude={null}
            project={null}
            sortOptions={articleSortOptions}
            handleSort={handleArticlesSortClick}
          />
        </div>

        <hr></hr>
        <div className="position-relative pb-5" style={{ width: '90%' }}>
          <button
            type="button"
            className="btn btn-success position-absolute bottom-0 end-0"
            style={{ margin: '0 0 0 0.4rem' }}
            onClick={() => {
              setSelectedProjectIdsCopy(selectedProjectIds);
              setSelectedArticleIdsCopy(
                Array(subprojects.length)
                  .fill(0)
                  .map(() => selectedArticleIds),
              );
              setCheckArticleAllCopy(new Array(subprojects.length).fill(true));
              setHideArticleCopy(new Array(subprojects.length).fill(true));
              setPhase(2);
            }}
          >
            {localization.strings.settings.transfer.next}
          </button>
        </div>
      </div>
    );
  };

  const drawPhase2 = () => {
    return (
      <div>
        {subprojects.map((item, index) => (
          <div className="my-5" key={`confirm-${item.fId}`}>
            {selectedProjectIdsCopy.includes(item.fId) && item.fId !== projectFrom ? (
              <div className="mx-auto" style={{ width: '80%' }}>
                <Table
                  data={sortedArticles}
                  columns={articleColumns}
                  selected={selectedArticleIdsCopy[index]}
                  set={index}
                  checkAll={checkArticleAllCopy[index]}
                  handleCheck={handleArticleCopyCheckClick}
                  handleCheckAll={handleArticleCopyCheckAllClick}
                  include={selectedArticleIds}
                  exclude={null}
                  project={item.name}
                  sortOptions={articleSortOptions}
                  handleSort={handleArticlesSortClick}
                />
              </div>
            ) : null}
          </div>
        ))}
        <hr></hr>
        <div className="position-relative pb-5" style={{ width: '90%' }}>
          <div className="position-absolute bottom-0 end-0">
            <button
              type="button"
              className="btn btn-success d-inline mt-1"
              style={{ margin: '0 0 0 0.4rem' }}
              onClick={() => setPhase(1)}
            >
              {localization.strings.settings.transfer.goBack}
            </button>
            <button
              type="button"
              className="btn btn-success d-inline mt-1"
              style={{ margin: '0 0 0 0.4rem' }}
              onClick={() => {
                setPhase(1);
                toasts.info(localization.strings.settings.transfer.started);
                void copyAllDocuments(firestore);
              }}
            >
              {localization.strings.settings.transfer.confirm}
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.title = `${localization.strings.settings.transfer.title} | ${title}`;
  }, [title, localization.strings.settings.transfer.title, firestore]);

  useEffect(() => {
    const { attribute, order } = projectSortOptions;
    const sortedData = [...subprojects].sort((a, b) => {
      return a[attribute].localeCompare(b[attribute], { numeric: true }) * (order === 'asc' ? 1 : -1);
    });
    setSortedProjects(sortedData);
  }, [subprojects, subprojects.length, projectSortOptions]);

  useEffect(() => {
    const { attribute, order } = articleSortOptions;
    const sortedData = [...articles].sort((a, b) => {
      let valueA = (a[attribute] || '').toString();
      let valueB = (b[attribute] || '').toString();

      if (attribute === 'fId') {
        valueA = (a['fId'] || '').toString();
        valueB = (b['fId'] || '').toString();
      }

      return valueA.localeCompare(valueB) * (order === 'asc' ? 1 : -1);
    });
    setSortedArticles(sortedData);
  }, [articles, articles.length, articleSortOptions]);

  if (projectConfig.loading) return <Loading waitingFor="Project Config" />;

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page transfer-page">
          <h1>{localization.strings.settings.transfer.title}</h1>
          <hr />
          {phase === 1 ? drawPhase1() : drawPhase2()}
        </div>
      </main>
    </PageWithSidebar>
  );
};

import { FC, useEffect, useState } from 'react';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCreateCheckoutSessionFunction } from '../../../ContextProviders/Firebase';
import { useParams } from 'react-router-dom';
import { Loading } from '../../Loading/Loading';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';

const stripePromise = loadStripe(
  'pk_test_51OL3FYGzdVngtfGYX5elGLypVGRj9HJiOHijwIR1uk0s7UCNJgUUfhj796VZ0UWBShAOGngtkOV0in5f7Mm5sFBi00c7nEO2xe',
);

interface Params {
  priceId?: string;
}
const SubscriptionCheckout: FC<{}> = () => {
  const [clientSecret, setClientSecret] = useState('');
  const { priceId } = useParams<Params>();
  const [loading, setLoading] = useState<boolean>(true);
  const { strings } = useLocalization();

  const createCheckoutSession = useCreateCheckoutSessionFunction();

  useEffect(() => {
    if (!priceId) return;
    createCheckoutSession({ priceId })
      .then(({ data }) => {
        setClientSecret(data.clientSecret);
        setLoading(false);
      })
      //TODO: Add error handling
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceId]);
  return loading ? (
    <Loading altText={strings.settings.subscriptions.loadingPayWall} />
  ) : (
    <div>
      {clientSecret && stripePromise && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default SubscriptionCheckout;

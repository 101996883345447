import { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from 'reactstrap';
import { TaxIdCreateParamsType } from '../../../../Types';
import { LoadingSVG } from '../../Loading/Loading';
import { toast } from 'react-toastify';
import { useCreateCustomerTaxIdFunction } from '../../../ContextProviders/Firebase';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  selectedItem: {
    stripeProductId: string;
    stripePriceId: string;
  };
  onSuccess: () => void;
};
const ConsentAndTaxModal = (props: Props) => {
  const [taxId, setTaxId] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);
  const [type, setType] = useState<TaxIdCreateParamsType>();
  const [loading, setLoading] = useState<boolean>(false);
  const createTaxId = useCreateCustomerTaxIdFunction();

  const { strings } = useLocalization();

  const isValid = () => {
    let _valid = false;
    const CNPJRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    const CPFRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

    if (CNPJRegex.test(taxId)) {
      _valid = true;
      setType('br_cnpj');
    }
    if (CPFRegex.test(taxId)) {
      _valid = true;
      setType('br_cpf');
    }

    setValid(_valid);

    if (_valid && checked) return true;
    if (!checked) {
      toast.warn('Please accept terms and conditions');
      return false;
    }
    return false;
  };

  const submit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (isValid() && type) {
      setLoading(true);
      createTaxId({ type, value: taxId })
        .then(({ data }) => {
          console.log('useCreateCustomerTaxIdFunction success:', data);
          toast.success('Tax ID registration successful');
        })
        .catch((e) => {
          console.log('useCreateCustomerTaxIdFunction Failed:', e);
          toast.error('Tax ID registration failed');
        })
        .finally(() => {
          setLoading(false);
          props.onSuccess();
        });
    }
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>{strings.settings.subscriptions.tax.modalHeader}</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => submit(e)}>
            <FormGroup className="position-relative">
              <Label for="examplePassword">{strings.settings.subscriptions.tax.id}</Label>
              <Input
                invalid={!valid}
                value={taxId}
                onChange={(e) => {
                  if (!valid) setValid(true);
                  setTaxId(e.target.value);
                }}
              />
              <FormFeedback>{strings.settings.subscriptions.tax.feedback}</FormFeedback>
              <FormText>{strings.settings.subscriptions.tax.example}</FormText>
            </FormGroup>
            <FormGroup check>
              <Input id="checkbox" type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} />

              <Label>
                {strings.settings.subscriptions.tax.accept}{' '}
                <a
                  target="_blank"
                  href={`${window.location.origin}/terms-of-use`}
                  style={{ color: 'blue', fontStyle: 'italic' }}
                  rel="noreferrer"
                >
                  {strings.settings.subscriptions.tax.termsOfUse}
                </a>
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => submit()} disabled={loading} type="submit">
            {loading ? <LoadingSVG size={20} /> : `${strings.settings.subscriptions.tax.register}`}
          </Button>
          <Button color="secondary" onClick={props.toggle} disabled={loading}>
            {strings.global.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConsentAndTaxModal;

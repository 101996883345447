import { FieldPath, where } from 'firebase/firestore';
import { chunk } from 'lodash/fp';
import { useAuth } from '../components/ContextProviders/Auth';
import { useConcreteProject } from '../components/ContextProviders/ProjectContext';
import { useCollection } from './Database';
import { WithID } from '../Types';
import { SubprojectContext } from '../components/ContextProviders/AppContext';
import { useContext } from 'react';

/*
    Firebase allows a maximum of 10 items in a where-in clause
    
    Ideally we handle this some other way, such as a separate implementation of useCollection for this.
    Right now, however, there's no time
*/
export function useProtectedCollection<T extends WithID, MetaDataType = Record<string, never>>(
  collection: string,
  fieldName: string | FieldPath,
  meta: MetaDataType,
  allowedList: string[],
  retryNonce: unknown,
) {
  const project = useConcreteProject();
  const auth = useAuth();
  const activeSubproject = useContext(SubprojectContext);
  if (activeSubproject.id !== null) project.id = activeSubproject.id;
  else project.id = activeSubproject.main;

  const [chunk0, chunk1, chunk2, chunk3, chunk4, chunk5, chunk6, chunk7, chunk8, chunk9] = [
    ...chunk(10, allowedList),
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  const files = [
    useCollection<T, MetaDataType>(
      chunk0.length > 0 || auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk0),
    ),
    useCollection<T, MetaDataType>(
      chunk1.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk1),
    ),
    useCollection<T, MetaDataType>(
      chunk2.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk2),
    ),
    useCollection<T, MetaDataType>(
      chunk3.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk3),
    ),
    useCollection<T, MetaDataType>(
      chunk4.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk4),
    ),
    useCollection<T, MetaDataType>(
      chunk5.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk5),
    ),
    useCollection<T, MetaDataType>(
      chunk6.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk6),
    ),
    useCollection<T, MetaDataType>(
      chunk7.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk7),
    ),
    useCollection<T, MetaDataType>(
      chunk8.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk8),
    ),
    useCollection<T, MetaDataType>(
      chunk9.length > 0 && !auth.isAdmin ? `project/${project.id}/${collection}` : undefined,
      meta,
      retryNonce,
      auth.isAdmin ? undefined : where(fieldName, 'in', chunk9),
    ),
  ].reduce(
    ({ docs, loading, error }, result) => ({
      docs: [...docs, ...result.docs],
      loading: loading || result.loading,
      error: error ?? result.error,
    }),
    { docs: [], loading: false, error: undefined },
  );
  return files;
}

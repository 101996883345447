import { useState, useEffect, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import CategoryNavigation from './CategoryNavigation';
import './HamburgerMenu.scss';
import { DraftModeSwitcher, PublishButton, RegisterButton, SearchInput, SettingsButton, UserNav } from './Header';
import { useAuth } from '../ContextProviders/Auth';

export const HamburgerMenu = (): ReactElement => {
  const auth = useAuth();
  const [isToggled, setIsToggled] = useState(false);
  const location = useLocation();

  const getHeight = () => (auth.isAdmin ? window.innerHeight - 32 : window.innerHeight);

  const [menuHeight, setMenuHeight] = useState(getHeight());

  useEffect(() => {
    setIsToggled(false);
  }, [location]);

  // This is done instead of simply using 100vh because of mobile browsers and their address bars
  // 100vh doesn't work because the height will be too high when address bar takes up space
  // 100% will work if we place the hamburger menu on the root of the app, but that requires a big
  // re-write of the hamburger menu, its style, and how it interacts with the regular menu.
  // Perhaps a TODO for the future.
  const handleResize = () => {
    setMenuHeight(getHeight());
  };
  window.addEventListener('resize', handleResize);
  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAdmin]);

  return (
    <div className="hamburger-menu">
      <button className="menu-toggle" onClick={() => setIsToggled(true)}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className={'main-menu' + (isToggled ? ' visible' : '')} style={{ height: menuHeight }}>
        <div className="top">
          <div className="right-container">
            <SearchInput />
          </div>
          <button className="menu-toggle" onClick={() => setIsToggled(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="middle">
          <CategoryNavigation isMiniMenu={false} />
        </div>
        <div className="bottom">
          <SettingsButton />
          <PublishButton />
          <DraftModeSwitcher />
          <UserNav />
          <RegisterButton />
        </div>
      </div>
    </div>
  );
};

import { Link } from 'react-router-dom';
import { useArticle, useCategory, useSection } from '../ContextProviders/AppContext';

export const ArticleBreadcrumbs = ({ id }: { id: string }) => {
  const article = useArticle(id);
  const section = useSection(article?.section);
  const category = useCategory(article?.category);
  if (!article || !category) return <></>;
  return (
    <div className="breadcrumbs">
      <span>
        <Link to={{ pathname: `/category/${category.fId}`, state: { sectionId: section?.fId } }}>{category.name}</Link>
        {section && (
          <>
            <span className="divider">&gt;</span>
            {section.name}
          </>
        )}
      </span>
    </div>
  );
};

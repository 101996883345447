import { ReactElement, useEffect } from 'react';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';

export const Handbook = (): ReactElement => {
  const localization = useLocalization();
  const title = useProjectTitle();

  useEffect(() => {
    document.title = `${localization.strings.settings.handbook} | ${title}`;
  }, [title, localization.strings.settings.handbook]);

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.handbook}</h1>
          <hr />
          <iframe
            title="handbook"
            src={'https://storage.googleapis.com/infosynk-static-file-directory/Handbok%20infosynk.pdf'}
          />
        </div>
      </main>
    </PageWithSidebar>
  );
};

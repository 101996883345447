import { createContext, ReactElement, ReactNode, useContext } from 'react';
import { useDocument } from '../../Hooks/Database';
import { Project } from '../../Types';
import { ErrorPage } from '../Pages/Error/ErrorPage';
import { Loading } from '../Pages/Loading/Loading';
import { SubprojectContext, useProjectConfig } from './AppContext';

export const ProjectContext = createContext<Project>({} as Project);
export const useConcreteProject = (): Project => useContext(ProjectContext);

export const useProjectTitle = () => {
  const projectConfig = useProjectConfig();
  const activeSubproject = useContext(SubprojectContext);
  if (activeSubproject.name !== null) return activeSubproject.name;
  return projectConfig.doc.name;
};

export function ProjectProvider({ children }: { children?: ReactNode }): ReactElement {
  const project = useDocument<Project, Record<string, never>>('configuration/project', {});

  if (project.error !== undefined) return <ErrorPage error={project.error} />;
  if (project.loading === true) return <Loading waitingFor="Project Data" />;

  return <ProjectContext.Provider value={project.doc}>{children}</ProjectContext.Provider>;
}

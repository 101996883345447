import { useEffect, useState } from 'react';
import { useAuth } from '../components/ContextProviders/Auth';
import { toast } from 'react-toastify';
import { useLocalization } from '../components/ContextProviders/LocalizationContext';
import { useFirebase } from '../components/ContextProviders/Firebase';
import { useConcreteProject } from '../components/ContextProviders/ProjectContext';

interface Statistics {
  polygons: number;
  hectares: number;
  acres: number;
}

type ExtendedPolygonDetails = {
  crop?: string | null;
  seedDate?: string | null;
};

type ExtendedRetrievePolygonsQuery = {
  __typename?: 'Query';
  retrievePolygons?: {
    __typename?: 'responseRetrievePolygons';
    IsSuccess: boolean;
    Message: string;
    Status: number;
    Result?: {
      __typename?: 'responseResultPolygons';
      total?: number | null;
      polygons?: Array<
        | ({
            __typename?: 'responseResultPolygonDetails';
            polygonId?: string | null;
            label?: string | null;
            hectares?: string | null;
            acres?: string | null;
            bbox?: string | null;
            geometry?: string | null;
            center?: string | null;
          } & ExtendedPolygonDetails)
        | null
      > | null;
    } | null;
  } | null;
};

const retrievePolygonsQuery = `
    query RetrievePolygons($startDate: String, $endDate: String, $benefactor: String, $status: PolygonStatus) {
      retrievePolygons(startDate: $startDate, endDate: $endDate, benefactor: $benefactor, status: $status) {
        IsSuccess
        Message
        Status
        Result {
          total
          polygons {
            polygonId
            label
            hectares
            acres
            bbox
            geometry
            center
          }
        }
      }
    }
  `;

export type Plot = NonNullable<
  NonNullable<NonNullable<ExtendedRetrievePolygonsQuery['retrievePolygons']>['Result']>['polygons']
>[0];

export const usePolygons = ({ benefactor }: { benefactor?: string }) => {
  const { user } = useAuth();
  const { enableVultusAPI } = useConcreteProject();
  const firebase = useFirebase();
  const { strings } = useLocalization();
  const [polygons, setPolygons] = useState<Plot[]>([]);
  const [polygonsLoading, setPolygonsLoading] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<Statistics>({ polygons: 0, hectares: 0, acres: 0 });

  useEffect(() => {
    const abortController = new AbortController();
    const fetchPolygons = async () => {
      if (!user || !enableVultusAPI || !benefactor) return;

      const handleError = (error: unknown, res?: Response) => {
        console.log(error);
        if (res && res.status === 403) return;
        toast.error(strings.error.unknown);
        setPolygons([]);
      };
      try {
        setPolygonsLoading(true);
        const token = await user.getIdToken();

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        const body = JSON.stringify({
          query: retrievePolygonsQuery,
          variables: {
            benefactor: benefactor,
            status: 'Active',
          },
        });

        const response = await fetch(
          `https://europe-west3-${firebase.options.projectId}.cloudfunctions.net/AgroScan-vultusAPI`,
          {
            method: 'POST',
            headers: headers,
            body: body,
            signal: abortController.signal,
          },
        );

        const { data, errors } = (await response.json()) as { data?: ExtendedRetrievePolygonsQuery; errors?: unknown };

        if (errors) {
          handleError(errors, response);
        } else {
          const result = data?.retrievePolygons?.Result;
          const { hectares, acres } = result?.polygons?.reduce(
            (acc, polygon) => {
              if (polygon) {
                acc.hectares += parseFloat(polygon.hectares ?? '') ?? 0;
                acc.acres += parseFloat(polygon.acres ?? '') ?? 0;
              }
              return acc;
            },
            { hectares: 0, acres: 0 },
          ) ?? { hectares: 0, acres: 0 };
          setStatistics({
            polygons: result?.total ?? 0,
            acres,
            hectares,
          });

          setPolygons(result?.polygons ?? []);
        }
      } catch (error) {
        handleError(error);
      } finally {
        setPolygonsLoading(false);
      }
    };

    void fetchPolygons();

    return () => {
      abortController.abort();
    };
  }, [user, strings.error.unknown, firebase.options.projectId, enableVultusAPI, benefactor]);

  return { statistics, polygons, polygonsLoading };
};

import { ReactElement } from 'react';
import { useArticle, useCategory } from '../ContextProviders/AppContext';
import { useAuth } from '../ContextProviders/Auth';
import { PlatformContentFrame } from '../PlatformContentFrame/PlatformContentFrame';

import './ArticleBodyPublish.scss';
import { ArticleBreadcrumbs } from './ArticleBreadcrumbs';
import { useLocalization } from '../ContextProviders/LocalizationContext';

interface ArticleBodyProps {
  id: string;
}
export const ArticleBodyPublish = ({ id }: ArticleBodyProps): ReactElement => {
  const article = useArticle(id);
  const localization = useLocalization();
  const category = useCategory(article?.category);
  const auth = useAuth();

  if (!article || !category) return <></>;

  return (
    <article>
      <ArticleBreadcrumbs id={id} />
      <div className="article-body">
        <PlatformContentFrame body={article.content} key={id} articleId={article.fId} editTags={true} />
      </div>
      {auth.isAdmin && article.lastUpdated && article.lastUpdatedBy ? (
        <div className="last-edited">
          {`${localization.strings.publish.titles.lastUpdatedBy}: ${article.lastUpdatedBy}, ${article.lastUpdated
            .toDate()
            .toLocaleDateString('sv-SE')}`}
        </div>
      ) : article.lastUpdated ? (
        <div className="last-edited">
          {`${localization.strings.publish.titles.lastUpdated}: ${article.lastUpdated
            .toDate()
            .toLocaleDateString('sv-SE')}`}
        </div>
      ) : (
        ''
      )}
    </article>
  );
};

import { ReactElement, useEffect } from 'react';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';

export const ErrorPage = ({ error }: { error: Error }): ReactElement => {
  const localization = useLocalization();
  const title = useProjectTitle();
  useEffect(() => {
    document.title = `${localization.strings.error.error} | ${title}`;
  }, [title, localization.strings.error.error]);

  return <code>{error.toString()}</code>;
};

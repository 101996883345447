import useLocalStorage from 'react-use-localstorage';
import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { en, LocalizedStrings, lv, pt, sv } from '../../localizedStrings';
import { LanguageCode } from '@eir/core';
import { useConcreteProject } from './ProjectContext';

interface Localization {
  strings: LocalizedStrings;
  activeLanguageCode: LanguageCode;
  setLanguage: (lang: LanguageCode) => void;
}

export const useLocalization = (): Localization => useContext(LocalizationContext);
function useProvideLocalization(): Localization {
  const project = useConcreteProject();
  const [strings, setStrings] = useState<LocalizedStrings>(sv);
  const [activeLanguageCode, setActiveLanguageCode] = useState<LanguageCode>(LanguageCode.Svenska);
  const [languageJSON, setLanguageJSON] = useLocalStorage('language');

  useEffect(() => {
    let tmpLang: string;

    try {
      tmpLang = JSON.parse(languageJSON);
    } catch (error) {
      // No language has been set in localStorage before
      try {
        tmpLang = project.languages[0];
      } catch (error) {
        tmpLang = LanguageCode.Svenska;
      }
      setLanguageJSON(JSON.stringify(tmpLang));
    }

    switch (tmpLang) {
      case LanguageCode.English:
        setStrings(en);
        setActiveLanguageCode(LanguageCode.English);
        break;
      case LanguageCode.Latviešu:
        setStrings(lv);
        setActiveLanguageCode(LanguageCode.Latviešu);
        break;
      case LanguageCode.Portuguese:
        setStrings(pt);
        setActiveLanguageCode(LanguageCode.Portuguese);
        break;
      default:
        setStrings(sv);
        setActiveLanguageCode(LanguageCode.Svenska);
        break;
    }
  }, [languageJSON, project.languages, setLanguageJSON]);

  return {
    strings,
    activeLanguageCode,
    setLanguage: (lang: LanguageCode) => setLanguageJSON(JSON.stringify(lang)),
  };
}

const LocalizationContext = createContext<Localization>({} as Localization);
export function LocalizationProvider({ children }: { children?: ReactNode }): ReactElement {
  return <LocalizationContext.Provider value={useProvideLocalization()}>{children}</LocalizationContext.Provider>;
}

import { Button } from '../Buttons/Buttons';
import { useLocalization } from '../ContextProviders/LocalizationContext';

export const ErrorView = ({ clearError }) => {
  const localization = useLocalization();
  return (
    <div style={{ padding: '1em', textAlign: 'center', width: 'fit-content' }}>
      <p>{localization.strings.error.unknown}</p>
      <Button className={'btn btn-primary'} onClick={clearError}>
        {localization.strings.global.clickToReload}
      </Button>
    </div>
  );
};

import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { style as coreStyle, ApplicationStyle } from '@eir/core';
import { useConcreteProject } from './ProjectContext';
import { SubprojectContext } from './AppContext';
import { useProjectStyle } from '../../Hooks';

const initStyle = coreStyle.demo;
const StyleContext = createContext<ApplicationStyle>(initStyle);

export const StyleProvider = ({ children }: { children?: ReactNode }): ReactElement => {
  const [appColors, setAppColors] = useState<ApplicationStyle>(initStyle);
  const project = useConcreteProject();
  const activeSubproject = useContext(SubprojectContext);
  const projectStyle = useProjectStyle(project.id);

  useEffect(() => {
    let appStyle = coreStyle[activeSubproject.main];
    if (activeSubproject.id === null) appStyle = projectStyle;
    if (activeSubproject.style !== null) appStyle = activeSubproject.style;

    if (process.env.NODE_ENV === 'development') {
      console.log(project.id);
      console.log(appStyle);
    }

    setAppColors(appStyle);
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
    :root {
      --color-primary: ${appStyle.primary} !important;
      --color-accent: ${appStyle.accent} !important;
      --color-primary-dark: ${appStyle.primaryDark} !important;
      --color-primary-light: ${appStyle.primaryLight} !important;
    }`;
    activeSubproject.setReload(false);
    document.head.appendChild(styleTag);
  }, [project.id, activeSubproject, activeSubproject.reload, projectStyle]);
  return <StyleContext.Provider value={appColors}>{children}</StyleContext.Provider>;
};

export const useStyle = (): ApplicationStyle => useContext(StyleContext);

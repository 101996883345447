import { ReactElement, useContext, useEffect, useState } from 'react';
import { useProjectConfig, SubprojectContext } from '../../ContextProviders/AppContext';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { IconButton } from '../../Buttons/Buttons';
import { faCheck, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { toasts } from '../../../shared';
import { ref, uploadBytes } from 'firebase/storage';
import { useAuth } from '../../ContextProviders/Auth';
import { useFirestore, useStorage } from '../../ContextProviders/Firebase';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const ImportDocument = (): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const projectConfig = useProjectConfig();
  const title = useProjectTitle();
  const activeSubproject = useContext(SubprojectContext);
  const activeProjectId = activeSubproject.id !== null ? activeSubproject.id : activeSubproject.main;
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [filename, setFilename] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const firebase = useFirestore();
  const storage = useStorage();

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const generateFilename = (): string => {
    const timestamp = new Date();

    const year = timestamp.getFullYear().toString().slice(-2);
    const month = (timestamp.getMonth() + 1).toString().padStart(2, '0');
    const day = timestamp.getDate().toString().padStart(2, '0');
    const hours = timestamp.getHours().toString().padStart(2, '0');
    const minutes = timestamp.getMinutes().toString().padStart(2, '0');
    const seconds = timestamp.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day}_${hours}_${minutes}_${seconds}`;
  };

  const uploadDocument = async () => {
    if (!storage) {
      return;
    }
    if (!auth.isAdmin || !auth.user?.uid) {
      toasts.error(localization.strings.auth.notAuthorized);
      return;
    }

    if (file) {
      const generatedFilename = generateFilename();
      setFilename(generatedFilename);
      const docUploadRef = ref(storage, `documentUploads/${activeProjectId}/${generatedFilename}.docx`);
      const firestoreDocRef = doc(firebase, `invocation/docx_parsing/documents/${generatedFilename}`);

      try {
        await setDoc(firestoreDocRef, { status: 'uploading', error: '', fullError: '' });
        toasts.info(localization.strings.settings.import.upload.started);

        await uploadBytes(docUploadRef, file);
      } catch (error) {
        toasts.error(localization.strings.settings.import.upload.failed);
        setFilename(null);
      }
    }
  };

  useEffect(() => {
    if (filename) {
      const firestoreDocRef = doc(firebase, `invocation/docx_parsing/documents/${filename}`);
      const unsubscribe = onSnapshot(firestoreDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUploadStatus(docSnapshot.data().status);
        }
      });
      return () => {
        unsubscribe();
      };
    }
    return undefined;
  }, [filename, firebase]);

  useEffect(() => {
    const handleFailedUpload = async () => {
      if (filename) {
        const firestoreDocRef = doc(firebase, `invocation/docx_parsing/documents/${filename}`);
        const docSnapshot = await getDoc(firestoreDocRef);
        if (docSnapshot.exists()) {
          const error = docSnapshot.data().error;
          setErrorMessage(error.join(''));
          setModalOpen(true);
        }
      }
    };

    const checkStatusAndHandle = async () => {
      if (uploadStatus === 'completed') {
        toasts.success(localization.strings.settings.import.upload.success);
      } else if (uploadStatus === 'noError') {
        toasts.success(localization.strings.settings.import.upload.noError);
        setFilename(null);
        setUploadStatus(null);
        setFile(null);
      } else if (uploadStatus === 'failed') {
        await handleFailedUpload();
      }
    };

    void checkStatusAndHandle();
  }, [
    uploadStatus,
    filename,
    localization.strings.settings.import.upload.success,
    localization.strings.settings.import.upload.noError,
    firebase,
    localization.strings.settings.import.upload.failedBookmark,
  ]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setFilename(null);
    setUploadStatus(null);
    setFile(null);
  };

  useEffect(() => {
    document.title = `${localization.strings.settings.import.title} | ${title}`;
  }, [title, localization.strings.settings.import.title]);

  if (projectConfig.loading) return <Loading waitingFor="Project Config" />;
  if (uploadStatus === 'uploading' || (filename && uploadStatus !== 'noError' && uploadStatus !== 'failed')) {
    return (
      <Loading
        waitingFor={localization.strings.settings.import.upload.loadingImportingDocument.replace(
          '{{document}}',
          String(filename),
        )}
      />
    );
  }

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.import.title}</h1>
          <hr />
          <input type="file" id="docpicker" accept=".docx" onChange={handleFileChange} />
          <IconButton icon={faUpload} disabled={!file} onClick={uploadDocument} />
        </div>
        <Modal
          isOpen={modalOpen}
          toggle={handleCloseModal}
          backdrop="static"
          keyboard={false}
          onClosed={handleCloseModal}
        >
          <ModalHeader>
            {localization.strings.settings.import.upload.failed}
            <br />
            {String(filename)}
          </ModalHeader>
          <ModalBody>
            <p style={{ whiteSpace: 'pre-line' }}>
              {localization.strings.settings.import.upload.failedBookmark.replace('{{error}}', String(errorMessage))}
            </p>
          </ModalBody>
          <ModalFooter>
            <IconButton
              type="button"
              theme="dark"
              onClick={handleCloseModal}
              onClose={handleCloseModal}
              icon={faCheck}
              text={localization.strings.global.confirm}
            />
          </ModalFooter>
        </Modal>
      </main>
    </PageWithSidebar>
  );
};

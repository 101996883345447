import { FC, useEffect, useState } from 'react';
import { useListSubscriptions } from '../../../../Hooks/useListSubscriptions';
import { Status } from '../../../ContextProviders/Auth';
import { ErrorPage } from '../../Error/ErrorPage';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { Loading } from '../../Loading/Loading';
import { PageWithSidebar } from '../../PageTypes';
import { SettingsSidebar } from '../SettingsSidebar';
import { useProjectConfig } from '../../../ContextProviders/AppContext';
import { faPencil, faPlus, faSave, faSort, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Button, IconButton } from '../../../Buttons/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import DeleteSubscriptionModal from './DeleteSubscriptionModal';
import { ExtendedSubscription } from '@eir/core';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useFirestore } from '../../../ContextProviders/Firebase';
import { subscriptionsActions } from '../../../../Hooks/DatabaseActions';
import { toast } from 'react-toastify';
import './SubscriptionsConfiguration.scss';

const SubscriptionsConfigurationPage: FC<{}> = () => {
  const { strings } = useLocalization();
  const projectConfig = useProjectConfig();

  useEffect(() => {
    document.title = `${strings.settings.subscriptions.configuration.title} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, strings.settings.subscriptions.configuration.title]);

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <SubscriptionsConfiguration />
        </div>
      </main>
    </PageWithSidebar>
  );
};

const SubscriptionsConfiguration: FC<{}> = () => {
  const [subscriptionReloadCounter, setSubscriptionReloadCounter] = useState<number>(0);
  const { status, subscriptions: originalSubscriptions } = useListSubscriptions({
    retryNonce: subscriptionReloadCounter,
  });
  const { strings } = useLocalization();
  const history = useHistory();
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<ExtendedSubscription | undefined>();
  const [isInSortMode, setIsInSortMode] = useState(false);
  const firestore = useFirestore();
  const { sort } = subscriptionsActions(firestore);

  const [subscriptions, setSubscriptions] = useState<ExtendedSubscription[]>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setSubscriptions(originalSubscriptions);
  }, [originalSubscriptions]);

  const onDragEnd: OnDragEndResponder = ({ destination, source }) => {
    if (!destination || !subscriptions) return;

    const newSubscriptions = [...subscriptions];
    const [reorderedItem] = newSubscriptions.splice(source.index, 1);
    newSubscriptions.splice(destination.index, 0, reorderedItem);
    setSubscriptions(newSubscriptions);
  };

  const saveSortChange = async () => {
    const subscriptionsToUpdate: { productId: string; orderIndex: number }[] = [];
    subscriptions?.forEach(({ orderIndex, productId }, i) => {
      if (orderIndex !== i) {
        subscriptionsToUpdate.push({ productId, orderIndex: i });
      }
    });
    if (!subscriptionsToUpdate.length) return;

    try {
      setIsSaving(true);
      await sort(subscriptionsToUpdate);
      setIsInSortMode(false);
      setSubscriptionReloadCounter((prevValue) => prevValue + 1);
      toast.success(strings.sorting.sortingHasChanged);
    } catch (error) {
      toast.error(strings.sorting.sortingHasChanged);
    } finally {
      setIsSaving(false);
    }
  };

  if (status === Status.ERROR) return <ErrorPage error={new Error(strings.error.unknown)} />;
  if (status === Status.IN_FLIGHT && !originalSubscriptions)
    return <Loading waitingFor={strings.settings.subscriptions.configuration.title} />;
  const disabled = status === Status.IN_FLIGHT;
  return (
    <>
      <h1>{strings.settings.subscriptions.configuration.title}</h1>
      <p style={{ color: 'var(--grayscale-2)' }}>{strings.settings.subscriptions.configuration.hint}</p>
      <hr />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="subscriptions-droppable" direction="vertical" isDropDisabled={!isInSortMode}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="acc-mng-module shadow-sm sub-config-container"
            >
              {subscriptions && subscriptions.length > 0
                ? subscriptions.map((item, index) => (
                    <Draggable
                      draggableId={item.productId}
                      index={index}
                      isDragDisabled={!isInSortMode}
                      key={item.productId}
                    >
                      {(provided) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="sub-config-item"
                          >
                            <div className="sub-config-name">{item.name}</div>
                            <Button
                              color="primary"
                              outline={true}
                              disabled={isInSortMode || disabled}
                              onClick={() => history.push(`/settings/subscriptions-configuration/${item.productId}`)}
                            >
                              <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
                            </Button>
                            <Button
                              color="danger"
                              outline={true}
                              disabled={isInSortMode || disabled}
                              onClick={() => setSubscriptionToDelete(item)}
                            >
                              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                            </Button>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))
                : strings.settings.subscriptions.noSubscriptionsExist}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <IconButton
        theme="dark"
        onClick={() => history.push('/settings/subscriptions-configuration/create')}
        icon={faPlus}
        disabled={isInSortMode || disabled}
        text={strings.settings.subscriptions.configuration.add}
      />

      {isInSortMode ? (
        <>
          <IconButton
            theme="dark"
            onClick={() => {
              setSubscriptions(originalSubscriptions);
              setIsInSortMode(false);
            }}
            icon={faTimes}
            disabled={isSaving}
            text={strings.global.cancel}
          />
          <IconButton
            theme="dark"
            onClick={saveSortChange}
            disabled={isSaving}
            isLoading={isSaving}
            icon={faSave}
            text={strings.sorting.saveSorting}
          />
        </>
      ) : (
        <IconButton
          theme="dark"
          onClick={() => setIsInSortMode(true)}
          disabled={disabled}
          icon={faSort}
          text={strings.settings.subscriptions.sort}
        />
      )}

      <DeleteSubscriptionModal
        onClose={(deleted) => {
          if (deleted) {
            setSubscriptionReloadCounter((prevValue) => prevValue + 1);
          }
          setSubscriptionToDelete(undefined);
        }}
        subscription={subscriptionToDelete}
      />
    </>
  );
};

export default SubscriptionsConfigurationPage;

import { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAddCustomerSubscriptionFunction, useGetSessionStatusFunction } from '../../../ContextProviders/Firebase';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { useAuth } from '../../../ContextProviders/Auth';
import { useConcreteProject } from '../../../ContextProviders/ProjectContext';
import { toast } from 'react-toastify';
import { Loading } from '../../Loading/Loading';

interface Params {
  sessionId?: string;
  priceId?: string;
}
const SubscriptionSession: FC<{}> = () => {
  const { strings } = useLocalization();
  const [status, setStatus] = useState<string | null>(null);
  const { sessionId } = useParams<Params>();
  const getSessionStatus = useGetSessionStatusFunction();
  const auth = useAuth();
  const config = useConcreteProject();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const triggered = useRef<boolean>(false);
  const addSubscription = useAddCustomerSubscriptionFunction();

  useEffect(() => {
    if (!sessionId || sessionId === 'null') {
      toast.warn(strings.settings.subscriptions.subscribeError);
      return history.push('/settings/subscriptions');
    }
    if (auth && auth.user && config) {
      if (!config.enableStripe) {
        toast.warn(strings.settings.subscriptions.subscribeError);
        return history.push('/settings/subscriptions');
      }
      if (!triggered.current) {
        triggered.current = true;
        getSessionStatus({ sessionId })
          .then(({ data }) => {
            setStatus(data.status);
            if (data && data.status && data.status === 'complete') {
              const subId = data.subscription;
              if (!subId) {
                setLoading(false);
                toast.error(strings.settings.subscriptions.subscribeError);
                return history.push('/settings/subscriptions');
              }
              addSubscription({ subscriptionId: subId as string })
                .then(() => {
                  setLoading(false);
                  toast.success(strings.settings.subscriptions.subscribeSuccess);
                  return history.push('/settings/subscriptions');
                })
                .catch((error) => {
                  console.log('addSubscription failed:', error);
                  setLoading(false);
                  toast.error(strings.settings.subscriptions.subscribeError);
                  return history.push('/settings/subscriptions');
                });
            }
          })
          //TODO: Add error handling
          .catch((error) => {
            console.log('getSessionStatus error:', error);
            setLoading(false);
            toast.success(strings.settings.subscriptions.subscribeError);
            return history.push('/settings/subscriptions');
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, config, sessionId]);

  if (loading) {
    return <Loading altText={strings.settings.subscriptions.processSession} />;
  }

  if (status === 'complete') {
    return (
      <div>
        <p>{strings.settings.subscriptions.subscribeSuccess}</p>
        <p>{JSON.stringify(status)}</p>
      </div>
    );
  }
  return null;
};

export default SubscriptionSession;

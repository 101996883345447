import { FC, Fragment, useState } from 'react';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { useDocument } from '../../../../Hooks';
import { AgroScanMutation, AgroScanQuery, AgroScanUserConfiguration } from '@eir/core';
import { ErrorPage } from '../../Error/ErrorPage';
import { Loading } from '../../Loading/Loading';
import { AgroScanUserConfig } from '../../../../Types';
import { CategoryLocker } from '../CategoryLocker';
import { agroScanAccessActions } from '../../../../Hooks/DatabaseActions';
import { useFirestore } from '../../../ContextProviders/Firebase';
import { toasts } from '../../../../shared';
import { getAgroScanAccessDescriptionRecords } from '../../../../util/DataHelpers';

interface Props {
  userId: string;
}

const AgroScanAccess: FC<Props> = ({ userId }) => {
  const { strings } = useLocalization();
  const firestore = useFirestore();

  const { doc, error, loading } = useDocument<AgroScanUserConfig>(
    userId ? `/agroScan/users/${userId}/configuration` : undefined,
    {},
  );

  const [accessLoadingState, setAccessLoadingState] = useState(
    Object.values({ ...AgroScanQuery, ...AgroScanMutation }).reduce((acc, value) => {
      acc[value] = false;
      return acc;
    }, {} as AgroScanUserConfiguration['access']),
  );

  const onUpdate = async (item: AgroScanMutation | AgroScanQuery) => {
    const { update } = agroScanAccessActions(firestore, userId);
    try {
      setAccessLoadingState((prevState) => ({
        ...prevState,
        [item]: true,
      }));
      await update(item, !doc.access[item]);
    } catch (error) {
      console.log(error);
      toasts.error(strings.settings.updateFailed);
    } finally {
      setAccessLoadingState((prevState) => ({
        ...prevState,
        [item]: false,
      }));
    }
  };

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading />;
  return (
    <div className="acc-mng-module shadow-sm">
      <h5>{strings.settings.agroScan.staffAccountAccess}</h5>
      <div className="categories">
        {Object.entries(getAgroScanAccessDescriptionRecords(strings)).map(
          ([item, description]: [AgroScanMutation | AgroScanQuery, string]) => (
            <Fragment key={item}>
              <div className="name">{description}</div>
              <div className="toggle">
                <CategoryLocker
                  canRead={doc.access?.[item]}
                  isDisabled={accessLoadingState[item]}
                  isLoading={accessLoadingState[item]}
                  onToggle={() => void onUpdate(item)}
                />
              </div>
            </Fragment>
          ),
        )}
      </div>
    </div>
  );
};

export default AgroScanAccess;

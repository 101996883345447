import { FC, useCallback, useEffect, useState } from 'react';
import { ExternalContentType, LanguageCode } from '@eir/core';
import { useAuth } from '../../../ContextProviders/Auth';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { useConcreteProject } from '../../../ContextProviders/ProjectContext';

interface Props {
  url: string;
  contentType: ExternalContentType;
}

enum MessageEventType {
  Auth = 'auth',
  Language = 'language',
  Theme = 'agroscan-theme',
}

type MessageEventData =
  | { type: MessageEventType.Auth; authToken: string; userId: string; project?: string }
  | { type: MessageEventType.Language; code: LanguageCode }
  | { type: MessageEventType.Theme; themeType: string };

const ExternalUrlFrame: FC<Props> = ({ url, contentType }) => {
  const [element, setElement] = useState<HTMLIFrameElement>();
  const auth = useAuth();
  const { activeLanguageCode } = useLocalization();
  const project = useConcreteProject();

  const postMessage = useCallback(
    (message: MessageEventData) => {
      if (contentType.toLowerCase() !== ExternalContentType.AGRO_SCAN || !element) return;
      element.contentWindow?.postMessage(message, url);
    },
    [url, element, contentType],
  );

  useEffect(() => {
    let renewTokenTimer;

    const passUserInfoToIframe = async () => {
      if (auth.user) {
        const { token, expirationTime } = await auth.user.getIdTokenResult();
        const expiresIn = new Date(expirationTime).getTime() - new Date().getTime();

        renewTokenTimer = setTimeout(() => {
          void passUserInfoToIframe();
        }, expiresIn - 5 * 60 * 1000);

        postMessage({ type: MessageEventType.Auth, authToken: token, userId: auth.user.uid, project: project.id });
      }
    };

    void passUserInfoToIframe();

    return () => {
      renewTokenTimer && clearTimeout(renewTokenTimer);
    };
  }, [auth.user, postMessage, project.id]);

  useEffect(() => {
    postMessage({ type: MessageEventType.Language, code: activeLanguageCode });
  }, [activeLanguageCode, postMessage]);

  useEffect(() => {
    postMessage({ type: MessageEventType.Theme, themeType: project.id });
  }, [project.id, postMessage]);

  return (
    <iframe
      title="external-url-iframe"
      id="external-url-iframe"
      allow="geolocation"
      onLoad={(iframe) => {
        if (iframe.currentTarget) setElement(iframe.currentTarget);
      }}
      src={url}
    />
  );
};

export default ExternalUrlFrame;

import { FC, useEffect, useMemo, useState } from 'react';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { useProjectConfig } from '../../../ContextProviders/AppContext';
import { PageWithSidebar } from '../../PageTypes';
import { SettingsSidebar } from '../SettingsSidebar';
import { useListSubscriptions } from '../../../../Hooks/useListSubscriptions';
import { ErrorPage } from '../../Error/ErrorPage';
import { Status, useAuth } from '../../../ContextProviders/Auth';
import { Loading, LoadingSVG } from '../../Loading/Loading';
import { AgroScanMutation, AgroScanQuery, ExtendedSubscription, StripePriceData } from '@eir/core';
import { Button, DarkButton } from '../../../Buttons/Buttons';
import { Alert, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAgroScanAccessDescriptionRecords } from '../../../../util/DataHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import './Subscription.scss';
import {
  useCancelCustomerSubscriptionFunction,
  useCreateCustomerPortalSessionFunction,
  useUpgradeCustomerSubscriptionFunction,
} from '../../../ContextProviders/Firebase';
import { toast } from 'react-toastify';
import { StripeInfo, StripeUser } from '../../../../Types';
import { useDocument } from '../../../../Hooks';
import { useHistory } from 'react-router-dom';
import ConsentAndTaxModal from './ConsentAndTaxModal';
import { format } from 'date-fns';
import { usePolygons } from '../../../../Hooks/usePolygons';

const SubscriptionsPage: FC<{}> = () => {
  const { strings } = useLocalization();
  const projectConfig = useProjectConfig();

  useEffect(() => {
    document.title = `${strings.settings.subscriptions.list.title} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, strings.settings.subscriptions.list.title]);

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <Subscriptions />
        </div>
      </main>
    </PageWithSidebar>
  );
};

type SubscriptionWithPrice = Omit<ExtendedSubscription, 'prices'> & StripePriceData;

const Subscriptions: FC<{}> = () => {
  const { status, subscriptions } = useListSubscriptions({});
  const { strings } = useLocalization();
  const { user, isStaff } = useAuth();
  const {
    doc: stripeUser,
    error: userError,
    loading: userLoading,
  } = useDocument<StripeUser>(user?.uid && isStaff ? `/stripe/data/users/${user.uid}` : undefined, {});

  const { /*doc: stripeInfo,*/ error: infoError, loading: infoLoading } = useDocument<StripeInfo>(`/stripe/info`, {});

  const [processingSubscription, setProcessingSubscription] = useState<string>();

  const [interval, setInterval] = useState<StripePriceData['interval']>('month');

  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [loadingPortal, setLoadingPortal] = useState<boolean>(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState<boolean>(false);
  const [subscriptionHectares, setSubscriptionHectares] = useState<number>(0);
  const { statistics } = usePolygons({ benefactor: stripeUser.fId });

  const upgradeSubscription = useUpgradeCustomerSubscriptionFunction();
  const cancelSubscription = useCancelCustomerSubscriptionFunction();
  const createPortal = useCreateCustomerPortalSessionFunction();

  const [selectedItem, setSelectedItem] = useState<{
    stripeProductId: string;
    stripePriceId: string;
    isUpgrade: boolean;
  }>();

  const history = useHistory();

  const subscriptionsMap = useMemo(
    () =>
      subscriptions?.reduce(
        (result, value) => {
          value.prices.forEach((item) => result[item.interval].push({ ...item, ...value }));
          return result;
        },
        { month: [], year: [] } as { month: SubscriptionWithPrice[]; year: SubscriptionWithPrice[] },
      ),
    [subscriptions],
  );

  const handleConsent = (stripeProductId: string, stripePriceId: string, isUpgrade: boolean) => {
    setSelectedItem((prevState) => {
      return { ...prevState, stripePriceId, stripeProductId, isUpgrade };
    });
  };

  useEffect(() => {
    if (selectedItem !== undefined) {
      if (selectedItem.stripePriceId && selectedItem.stripeProductId) {
        if (stripeUser.customerTaxId && stripeUser.customerTaxId.id) {
          handleSubscribe();
        } else {
          setShowConsentModal(true);
        }
      } else {
        handleSubscribe();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleSubscribe = () => {
    if (!user || !selectedItem) return;
    if (selectedItem.isUpgrade) {
      const subscribeTo = filteredSubscriptions.find(
        (item) => item.productId === selectedItem.stripeProductId,
      )?.hectares;
      if (subscribeTo !== undefined) setSubscriptionHectares(subscribeTo);

      if (subscribeTo !== undefined && subscribeTo < statistics.hectares) {
        setShowDowngradeModal(true);
      } else {
        setShowUpgradeModal(true);
      }
    } else {
      history.push(`/settings/subscriptions/${selectedItem.stripePriceId}`);
    }
  };

  const handleUpgradeSubscription = () => {
    if (!stripeUser.stripeSubscriptionId || !selectedItem || !selectedItem.stripePriceId) {
      toast.warn(strings.settings.subscriptions.subscribeError);
      return;
    }
    setProcessingSubscription(selectedItem.stripeProductId);
    upgradeSubscription({ subscriptionId: stripeUser.stripeSubscriptionId, newPriceId: selectedItem.stripePriceId })
      .then(() => {
        toast.success(strings.settings.subscriptions.subscribeSuccess);
      })
      .catch((error) => {
        console.log('Upgrade subscription failed:', error);
        toast.error(strings.settings.subscriptions.subscribeError);
      })
      .finally(() => {
        setShowUpgradeModal(false);
        setProcessingSubscription(undefined);
        setSelectedItem(undefined);
      });
  };

  const handleCancelSubscription = () => {
    //TODO: Use new cloud function to cancel subscription
    if (!user || !stripeUser.stripeSubscriptionId) {
      toast.warn(strings.settings.subscriptions.cancelSubscriptionError);
      return;
    }
    setProcessingSubscription(stripeUser.productId);
    cancelSubscription({ subscriptionId: stripeUser.stripeSubscriptionId })
      .then(() => {
        toast.info(strings.settings.subscriptions.cancelSubscriptionSuccess);
        setShowCancelModal(false);
      })
      .catch((error) => {
        toast.error(strings.settings.subscriptions.cancelSubscriptionError);
      })
      .finally(() => setProcessingSubscription(undefined));
  };

  const navigateToCustomerPortal = () => {
    if (!user || !stripeUser) return;
    setLoadingPortal(true);
    createPortal({ customerId: stripeUser.customerId })
      .then(({ data }) => {
        window.location.href = data;
      })
      .catch((error) => {
        console.log('CUSTOMER PORTAL SESSION FAILED:', error);
      })
      .finally(() => setLoadingPortal(false));
  };

  const isProcessing = !!processingSubscription;

  if (status === Status.ERROR || userError || infoError) return <ErrorPage error={new Error(strings.error.unknown)} />;
  if (status === Status.IN_FLIGHT || (userLoading && isStaff) || infoLoading)
    return <Loading waitingFor={strings.settings.subscriptions.list.title} />;

  const filteredSubscriptions = subscriptionsMap?.[interval] ?? [];

  const userHasStripeSubscription =
    !!stripeUser.stripeSubscriptionId && !!stripeUser.subscription && !!stripeUser.productId && !!stripeUser.priceId;

  const stripeSubscriptionIsEnded = stripeUser.subscribedTo
    ? (stripeUser.subscribedTo as { seconds: number }).seconds < new Date().getSeconds()
    : false;
  const stripeSubscriptionIsCanceled = !!stripeUser.canceledAt;
  return (
    <>
      {showConsentModal && selectedItem && (
        <ConsentAndTaxModal
          isOpen={showConsentModal}
          toggle={() => setShowConsentModal((prev) => !prev)}
          selectedItem={selectedItem}
          onSuccess={() => {
            handleSubscribe();
          }}
        />
      )}
      <h1>{strings.settings.subscriptions.list.title}</h1>
      <hr />
      {/** //TODO: Replace hardcoded Alert texts with translations */}
      {userHasStripeSubscription &&
        !stripeSubscriptionIsEnded &&
        !stripeSubscriptionIsCanceled &&
        stripeUser.subscribedTo && (
          <Alert color="primary">
            {strings.settings.subscriptions.subscriptionUntil}{' '}
            {format(new Date((stripeUser.subscribedTo as { seconds: number }).seconds * 1000), 'dd-MM-yyyy')}
            <div>
              <Button
                color="link"
                outline
                onClick={() => {
                  navigateToCustomerPortal();
                }}
              >
                {loadingPortal && <LoadingSVG size={15} />} {strings.settings.subscriptions.manageBillingInformation}
              </Button>
            </div>
          </Alert>
        )}
      {userHasStripeSubscription && stripeSubscriptionIsEnded && stripeUser.subscribedTo && (
        <Alert color="error">
          Your subscription has ended at{' '}
          {format(new Date((stripeUser.subscribedTo as { seconds: number }).seconds * 1000), 'dd-MM-yyyy')}
          <div>
            <Button
              color="link"
              outline
              onClick={() => {
                navigateToCustomerPortal();
              }}
            >
              {loadingPortal && <LoadingSVG size={15} />}Click here to manage payment method, billing information and
              invoice history.
            </Button>
          </div>
        </Alert>
      )}
      {userHasStripeSubscription &&
        !stripeSubscriptionIsEnded &&
        stripeSubscriptionIsCanceled &&
        stripeUser.subscribedTo && (
          <Alert color="warning">
            Canceled subscription. Your subscription will end at{' '}
            {format(new Date((stripeUser.subscribedTo as { seconds: number }).seconds * 1000), 'yyyy-MM-dd')}
            <div>
              <Button
                color="link"
                outline
                style={{ paddingLeft: 0 }}
                onClick={() => {
                  navigateToCustomerPortal();
                }}
              >
                {loadingPortal && <LoadingSVG size={15} />}
                {strings.settings.subscriptions.manageBillingInformation}
              </Button>
            </div>
          </Alert>
        )}
      {!userHasStripeSubscription && (
        <Alert color="secondary">
          {strings.settings.subscriptions.noSubscription}{' '}
          <div>
            <Button
              color="link"
              outline
              style={{ paddingLeft: 0 }}
              onClick={() => {
                navigateToCustomerPortal();
              }}
            >
              {loadingPortal && <LoadingSVG size={15} />}
              {strings.settings.subscriptions.manageBillingInformation}
            </Button>
          </div>
        </Alert>
      )}
      <div className="subscription-interval-group">
        <ButtonGroup>
          <Button
            className={interval === 'month' ? 'dark-button' : 'light-button'}
            onClick={() => setInterval('month')}
          >
            {strings.settings.subscriptions.list.monthly}
          </Button>
          <Button className={interval === 'year' ? 'dark-button' : 'light-button'} onClick={() => setInterval('year')}>
            {strings.settings.subscriptions.list.yearly}
          </Button>
        </ButtonGroup>
      </div>

      <div className="subscription-card-container">
        {filteredSubscriptions.map((item) => (
          <div className="subscription-card" key={item.productId}>
            {item.isMostPopular && (
              <div className="subscription-card-banner">{strings.settings.subscriptions.mostPopular}</div>
            )}

            <div className="subscription-card-content">
              <h4>{item.name}</h4>
              {interval === 'year' ? <h2>R${item.amount}/ano</h2> : <h2>R${item.amount}/mês</h2>}

              <div>
                {item.hectares !== undefined && (
                  <div>
                    <FontAwesomeIcon icon={faCheck} color="green" />
                    {strings.settings.subscriptions.hectaresLimit}: {item.hectares}
                  </div>
                )}
                {item.access &&
                  Object.entries(getAgroScanAccessDescriptionRecords(strings)).map(
                    ([entry, description]: [AgroScanMutation | AgroScanQuery, string]) =>
                      item.access?.[entry] ? (
                        <div key={entry}>
                          <FontAwesomeIcon icon={faCheck} color="green" />
                          {description}
                        </div>
                      ) : null,
                  )}
              </div>
            </div>

            {isStaff && (!userHasStripeSubscription || stripeSubscriptionIsEnded) && (
              <DarkButton disabled={isProcessing} onClick={() => handleConsent(item.productId, item.priceId, false)}>
                {processingSubscription === item.productId ? (
                  <LoadingSVG size={20} />
                ) : (
                  strings.settings.subscriptions.getSubscription
                )}
              </DarkButton>
            )}
            {isStaff && userHasStripeSubscription && stripeSubscriptionIsCanceled && !stripeSubscriptionIsEnded && (
              <DarkButton
                disabled={
                  isProcessing || (item.productId === stripeUser.productId && item.priceId === stripeUser.priceId)
                }
                onClick={() =>
                  item.productId === stripeUser.productId && item.priceId === stripeUser.priceId
                    ? () => {
                        return;
                      }
                    : handleConsent(item.productId, item.priceId, true)
                }
              >
                {processingSubscription === item.productId ? (
                  <LoadingSVG size={20} />
                ) : item.productId === stripeUser.productId && item.priceId === stripeUser.priceId ? (
                  strings.global.cancel
                ) : (
                  strings.settings.subscriptions.upgradeSubscription
                )}
              </DarkButton>
            )}
            {isStaff && userHasStripeSubscription && !stripeSubscriptionIsCanceled && !stripeSubscriptionIsEnded && (
              <DarkButton
                disabled={isProcessing}
                onClick={() =>
                  item.productId === stripeUser.productId && item.priceId === stripeUser.priceId
                    ? setShowCancelModal(true)
                    : handleConsent(item.productId, item.priceId, true)
                }
              >
                {processingSubscription === item.productId ? (
                  <LoadingSVG size={20} />
                ) : item.productId === stripeUser.productId && item.priceId === stripeUser.priceId ? (
                  strings.global.cancel
                ) : (
                  strings.settings.subscriptions.upgradeSubscription
                )}
              </DarkButton>
            )}
          </div>
        ))}

        {
          // Re-enable later, Contact Us
          /*<div className="subscription-card">
          <div className="subscription-card-content align-items-center text-center">
            <h4>{strings.settings.subscriptions.custom.title}</h4>
            <h2>{strings.settings.subscriptions.custom.contactUs}</h2>

            <div>{strings.settings.subscriptions.custom.description}</div>
            <a href={`mailto:${stripeInfo.contactEmail}`}>{stripeInfo.contactEmail}</a>
          </div>
      </div>*/
        }
      </div>
      {showUpgradeModal && (
        <Modal isOpen={showUpgradeModal} toggle={() => setShowUpgradeModal((prev) => !prev)}>
          <ModalHeader toggle={() => setShowUpgradeModal((prev) => !prev)}>Upgrade subscription plan</ModalHeader>
          <ModalBody>
            <div>
              <h6>
                By upgrading/downgrading your subscription plan, the billing of the new price and the price difference
                will be applied to your next invoice.
              </h6>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => handleUpgradeSubscription()}
              disabled={processingSubscription && processingSubscription?.length > 0}
            >
              {processingSubscription && processingSubscription?.length > 0 ? (
                <LoadingSVG size={20} />
              ) : (
                'Agree and upgrade'
              )}
            </Button>
            <Button
              color="secondary"
              onClick={() => setShowUpgradeModal(false)}
              disabled={processingSubscription && processingSubscription?.length > 0}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {showCancelModal && (
        <Modal isOpen={showCancelModal} toggle={() => setShowCancelModal((prev) => !prev)}>
          <ModalHeader toggle={() => setShowCancelModal((prev) => !prev)}>
            {strings.settings.subscriptions.cancel.modalHeader}
          </ModalHeader>
          <ModalBody>
            <div>
              <h6>{strings.settings.subscriptions.cancel.modalBody}</h6>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => handleCancelSubscription()}
              disabled={processingSubscription && processingSubscription?.length > 0}
            >
              {processingSubscription && processingSubscription?.length > 0 ? (
                <LoadingSVG size={20} />
              ) : (
                `${strings.settings.subscriptions.cancel.yes}`
              )}
            </Button>
            <Button
              color="secondary"
              onClick={() => setShowCancelModal(false)}
              disabled={processingSubscription && processingSubscription?.length > 0}
            >
              {strings.settings.subscriptions.cancel.no}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {showDowngradeModal && (
        <Modal isOpen={showDowngradeModal} toggle={() => setShowDowngradeModal((prev) => !prev)}>
          <ModalHeader toggle={() => setShowDowngradeModal((prev) => !prev)}>
            <p>{strings.settings.subscriptions.downgradeStubscriptionHeader}</p>
          </ModalHeader>
          <ModalBody>
            <div>
              <h6>
                {strings.settings.subscriptions.downgradeStubscription
                  .replace('{{usedHectares}}', String(statistics.hectares))
                  .replace('{{newLimit}}', String(subscriptionHectares))
                  .replace('{{difference}}', String(subscriptionHectares - statistics.hectares))}
              </h6>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => setShowDowngradeModal(false)}
              disabled={processingSubscription && processingSubscription?.length > 0}
            >
              {strings.global.ok}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
export default SubscriptionsPage;

import { Category } from '../Types';

// eslint-disable-next-line
type Any = any;

export const uuid = (): string =>
  Array.from(crypto.getRandomValues(new Uint8Array(8)))
    .map((n) => n.toString(16))
    .join('');
/*class DefaultMap extends Map {
    defaultGenerator : (key) => Any;
    constructor(defaultGenerator : (key) => Any) {
        super();
        this.defaultGenerator = defaultGenerator;
    }
    get(key : Any) { return this.has(key) ? super.get(key) : (this.set(key, this.defaultGenerator(key)), super.get(key)) }
    setDefault(defaultGenerator) { return this.defaultGenerator = defaultGenerator; }
}*/
/*
function createWithDefault(object, defaultGenerator : Function) {
    return new Proxy(Object.create(object), {
        get(target, p, receiver) {
            if(!Reflect.has(target, p))
                Reflect.set(target, p, defaultGenerator(p))
            return Reflect.get(target, p, receiver)
        }
    })
}*/

//const fUUIDs = new DefaultMap((f) => uuid())

export const BenchmarkWrapper = (f: (...args) => Any, lookupString = ''): Any => {
  return new Proxy(f, {
    apply(target, thisArg, args) {
      const callId = uuid();
      performance.mark(`${callId}:start`);
      const res = Reflect.apply(target, thisArg, args);
      performance.mark(`${callId}:end`);
      performance.measure(`${lookupString}:${f.name}():call[${callId}]`, `${callId}:start`, `${callId}:end`);
      return res;
    },
  });
};

export const PrintTimingWrapper = (f: (...args) => Any, lookupString = ''): Any => {
  return new Proxy(f, {
    apply(target, thisArg, args) {
      const callId = uuid();
      const now = performance.now();
      const res = Reflect.apply(target, thisArg, args);
      console.log(`${lookupString}:${f.name}():call[${callId}]`, performance.now() - now);
      return res;
    },
  });
};

window['perfMeasures'] = (regex: RegExp | string) => {
  console.table(
    performance
      .getEntriesByType('measure')
      .filter((m) => new RegExp(regex).exec(m.name) !== null)
      .map((pm) => ({ name: pm.name, duration: pm.duration })),
  );
};

export function permissionFilteredCategories(categoryPermissions: string[], categories: Category[], isAdmin: boolean) {
  return categories.filter((c) => isAdmin || !c.isProtected || categoryPermissions.includes(c.fId)).map((c) => c.fId);
}

import { FC, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from '../../../Buttons/Buttons';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { useFirestore } from '../../../ContextProviders/Firebase';
import { Status } from '../../../ContextProviders/Auth';
import { subscriptionsActions } from '../../../../Hooks/DatabaseActions';
import { toast } from 'react-toastify';
import { LoadingSVG } from '../../Loading/Loading';
import { ExtendedSubscription } from '@eir/core';

interface Props {
  subscription?: ExtendedSubscription;
  onClose: (deleted?: boolean) => void;
}
const DeleteSubscriptionModal: FC<Props> = ({ onClose, subscription }) => {
  const { strings } = useLocalization();
  const firestore = useFirestore();
  const [requestStatus, setRequestStatus] = useState(Status.IDLE);

  const handleDelete = async () => {
    if (!subscription) return;
    const { delete: deleteSubscription } = subscriptionsActions(firestore);

    try {
      setRequestStatus(Status.IN_FLIGHT);
      await deleteSubscription(subscription.productId);
      setRequestStatus(Status.IDLE);
      toast.success(strings.settings.subscriptions.delete.success);
      onClose(true);
    } catch (error) {
      console.log(error);
      setRequestStatus(Status.IDLE);
      toast.error(strings.settings.subscriptions.delete.error);
    }
  };
  return (
    <Modal isOpen={!!subscription}>
      <ModalHeader>
        {strings.settings.subscriptions.delete.modalHeader}({subscription?.name})
      </ModalHeader>
      <ModalBody>{strings.settings.subscriptions.delete.modalBody}</ModalBody>
      <ModalFooter>
        <Button onClick={handleDelete} disabled={requestStatus === Status.IN_FLIGHT} color="danger">
          {requestStatus === Status.IN_FLIGHT ? <LoadingSVG size={20} /> : strings.global.delete}
        </Button>
        <Button onClick={() => onClose()}>{strings.global.cancel}</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteSubscriptionModal;

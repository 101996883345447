import React, { ReactElement, useContext, useEffect } from 'react';
import { Redirect, Switch, Route, useParams, useLocation } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthPage } from '../Pages/Auth/AuthPage';
import { LegalDocument } from '../Pages/Legal/Legal';
import { Header } from '../Header/Header';
import './Layout.scss';
import { useClearCache } from 'react-clear-cache';

import { SubprojectContext, useArticle, useFullscreen } from '../ContextProviders/AppContext';
import { useAuth } from '../ContextProviders/Auth';
import { Loading } from '../Pages/Loading/Loading';

import { SearchPage } from '../Pages/Search/SearchPage';
import { Body } from '../Pages/MainBody/MainBody';
import { useConcreteProject } from '../ContextProviders/ProjectContext';
import { StaffAccountManagement } from '../Pages/Settings/StaffAccounts';
import { AccountManagement } from '../Pages/Settings/Account';
import { EmailManagement } from '../Pages/Settings/Email';
import { CategoriesManager } from '../Pages/Settings/CategoriesManager';
import { UserProfileManagement } from '../Pages/Settings/UserProfile';
import { ErrorBoundary } from '../Error/BugsnagErrorBoundary';
import { ErrorView } from '../Error/DefaultErrorView';
import { Slide, toast, ToastContainer, ToastContentProps } from 'react-toastify';
import { Handbook } from '../Pages/Settings/Handbook';
import { LandingPage } from '../Pages/LandingPage/LandingPage';
import { ExternalPage } from '../Pages/External/ExternalPage';
import { Footer } from '../Footer/Footer';
import { ForgotPassword } from '../Pages/ForgotPassword/ForgotPassword';
import { ModalContextDisplayer } from '../ContextProviders/ModalContext';
import { FourOhFour } from '../Pages/404/FourOhFour';
import { AccessManagement } from '../Pages/Settings/CategoryAccess';
import { Export } from '../Pages/Settings/Export';
import { Publish } from '../Pages/Publish/Publish';
import { IconButton } from '../Buttons/Buttons';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { useLocalization } from '../ContextProviders/LocalizationContext';
import { RegisterPage } from '../Pages/Auth/Register';
import { VerificationPage } from '../Pages/Auth/Verification';
import EmailVerificationModal from '../Pages/Auth/EmailVerificationModal';
import SubscriptionsConfiguration from '../Pages/Settings/Subscriptions/SubscriptionsConfiguration';
import EditSubscription from '../Pages/Settings/Subscriptions/EditSubscription';
import Subscriptions from '../Pages/Settings/Subscriptions/Subscriptions';
import SubscriptionCheckout from '../Pages/Settings/Subscriptions/SubscriptionCheckout';
import SubscriptionSession from '../Pages/Settings/Subscriptions/SubscriptionSession';
import { ImportDocument } from '../Pages/Settings/Import';
import { SubprojectsManager } from '../Pages/Settings/Subprojects';
import { ProjectValuesManager } from '../Pages/Settings/ProjectValues';
import { TransferManager } from '../Pages/Settings/Transfer';
import InvitationCompletion from '../Pages/Settings/MultiStaffAccount/InvitationCompletion';
import { useFirestore } from '../ContextProviders/Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { CMSUserData } from '@eir/core';
import ChangePassword from '../Pages/Settings/MultiStaffAccount/ChangePassword';
import RolesConfigurationPage from '../Pages/Settings/Roles/RolesConfiguration';
import EditRole from '../Pages/Settings/Roles/EditRole';
import { FilterableList } from '../Pages/Settings/ArticleTransfer';

const AdminGated = ({
  children,
  invert,
  stripeSpecific,
}: {
  children: ReactElement;
  invert?: boolean;
  stripeSpecific?: boolean;
}): ReactElement => {
  const auth = useAuth();
  const { enableStripe } = useConcreteProject();
  if (stripeSpecific && !enableStripe) return <Redirect to="/" />;
  if (invert ? auth.isAdmin : !auth.isAdmin) return <Redirect to="/" />;
  return <>{children}</>;
};
const AuthGated = ({
  children,
  invert,
  register,
  stripeSpecific,
}: {
  children: ReactElement;
  invert?: boolean;
  register?: boolean;
  stripeSpecific?: boolean;
}): ReactElement => {
  const auth = useAuth();
  const config = useConcreteProject();
  const isAuthenticated = auth.isAdmin || auth.isStaff;
  const supportsRegister = config.uniformAuthentication && config.allowAccountCreation;
  if (stripeSpecific && !config.enableStripe) return <Redirect to="/" />;
  if (register && !supportsRegister) return <Redirect to="/" />;
  if (invert ? isAuthenticated : !isAuthenticated) return <Redirect to="/" />;
  return <>{children}</>;
};

interface ArticleRedirectParams {
  articleId: string;
}
const ArticleRedirect = () => {
  const { articleId } = useParams<ArticleRedirectParams>();
  const article = useArticle(articleId);
  const { hash } = useLocation();

  if (!article) return <></>;

  return <Redirect to={`/category/${article.category}/${article.fId}${hash}`} />;
};
interface CategoryRedirectParams {
  categoryId: string;
}
const CategoryRedirect = () => {
  const { categoryId } = useParams<CategoryRedirectParams>();
  return <Redirect to={`/category/${categoryId}`} />;
};

// TODO: Check all links in all contexts - no relative ones for example
const Routes = (): ReactElement => {
  const [fs] = useFullscreen();

  return (
    <div className="layout-wrapper">
      <Header />
      <div className={`site-body ${fs ? 'fullscreen' : ''}`}>
        <Switch>
          <Redirect from={'/externals/:folder'} to={'/external/:folder'} exact={true} />
          <Redirect from={'/term-of-use'} to={'/terms-of-use'} exact={true} />
          <Redirect from={'/about'} to={'/'} exact={true} />
          <Redirect from={'/settings'} to={'/settings/account'} exact={true} />

          <Route path={'/chief-articles/:articleId'} exact={true} component={ArticleRedirect} />
          <Route path={'/changelog-articles/:articleId'} exact={true} component={ArticleRedirect} />
          <Route path={'/categories/:categoryId'} exact={true} component={CategoryRedirect} />
          <Route path={'/chief-categories/:categoryId'} exact={true} component={CategoryRedirect} />
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path={'/external/:externalId'}>
            <ExternalPage />
          </Route>
          <Route path={'/sign-in'} exact={true}>
            <AuthGated invert>
              <AuthPage />
            </AuthGated>
          </Route>
          <Route path={['/register']} exact={true}>
            <AuthGated invert register>
              <RegisterPage />
            </AuthGated>
          </Route>
          <Route path={['/verification']} exact={true}>
            <AuthGated invert register>
              <VerificationPage />
            </AuthGated>
          </Route>
          <Route path={'/terms-of-use'} exact={true}>
            <LegalDocument name="terms-of-use" />
          </Route>
          <Route path={'/privacy-policy'} exact={true}>
            <LegalDocument name="privacy-policy" />
          </Route>
          <Route path={'/articles/:articleId'} exact={true}>
            <ArticleRedirect />
          </Route>
          <Route
            path={['/category/:categoryId/:articleId', '/category/:categoryId', '/category/:categoryId#:sectionId']}
            exact={true}
          >
            <ErrorBoundary FallbackComponent={ErrorView}>
              <Body />
            </ErrorBoundary>
          </Route>
          <Route path={['/search', '/search/:searchText', '/search/:searchText/:articleId']} exact={true}>
            <SearchPage />
          </Route>
          <Route path={['/publish']} exact={true}>
            <AdminGated>
              <Publish />
            </AdminGated>
          </Route>
          <Route path={['/settings/account']} exact={true}>
            <AuthGated>
              <AccountManagement />
            </AuthGated>
          </Route>
          <Route path={['/settings/staff']} exact={true}>
            <AdminGated>
              <StaffAccountManagement />
            </AdminGated>
          </Route>
          <Route path={['/settings/access']} exact={true}>
            <AdminGated>
              <AccessManagement />
            </AdminGated>
          </Route>
          <Route path={['/settings/email']} exact={true}>
            <AdminGated>
              <EmailManagement />
            </AdminGated>
          </Route>
          <Route path={['/settings/handbook']} exact={true}>
            <AdminGated>
              <Handbook />
            </AdminGated>
          </Route>
          <Route path={['/settings/subscriptions-configuration']} exact={true}>
            <AdminGated stripeSpecific>
              <SubscriptionsConfiguration />
            </AdminGated>
          </Route>
          <Route
            path={['/settings/subscriptions-configuration/create', '/settings/subscriptions-configuration/:id']}
            exact={true}
          >
            <AdminGated stripeSpecific>
              <EditSubscription />
            </AdminGated>
          </Route>
          <Route path={['/settings/role-configuration']} exact={true}>
            <AdminGated>
              <RolesConfigurationPage />
            </AdminGated>
          </Route>
          <Route path={['/settings/role-configuration/create', '/settings/role-configuration/:id']} exact={true}>
            <AdminGated>
              <EditRole />
            </AdminGated>
          </Route>
          <Route path={['/settings/subscriptions-configuration']} exact={true}>
            <AdminGated stripeSpecific>
              <SubscriptionsConfiguration />
            </AdminGated>
          </Route>
          <Route
            path={['/settings/subscriptions-configuration/create', '/settings/subscriptions-configuration/:id']}
            exact={true}
          >
            <AdminGated stripeSpecific>
              <EditSubscription />
            </AdminGated>
          </Route>
          <Route path={['/settings/subscriptions']} exact={true}>
            <AuthGated stripeSpecific>
              <Subscriptions />
            </AuthGated>
          </Route>
          <Route path={['/settings/subscriptions/:priceId']} exact={true}>
            <AuthGated stripeSpecific>
              <SubscriptionCheckout />
            </AuthGated>
          </Route>
          <Route path={['/settings/subscriptions/:priceId/session/:sessionId']} exact={true}>
            <SubscriptionSession />
          </Route>
          <Route path={['/settings/export']} exact={true}>
            <AdminGated>
              <Export />
            </AdminGated>
          </Route>
          <Route path={['/settings/import']} exact={true}>
            <AdminGated>
              <ImportDocument />
            </AdminGated>
          </Route>
          <Route path={['/settings/subprojects']} exact={true}>
            <AdminGated>
              <SubprojectsManager />
            </AdminGated>
          </Route>
          <Route path={['/settings/projectvalues']} exact={true}>
            <AdminGated>
              <ProjectValuesManager />
            </AdminGated>
          </Route>
          <Route path={['/settings/transfer']} exact={true}>
            <AdminGated>
              <TransferManager />
            </AdminGated>
          </Route>
          <Route path={['/settings/categories']} exact={true}>
            <AdminGated>
              <CategoriesManager />
            </AdminGated>
          </Route>
          <Route path={['/settings/article-transfer']} exact={true}>
            <AdminGated>
              <FilterableList />
            </AdminGated>
          </Route>
          <Route path={'/forgot-password'} exact={true}>
            <AuthGated invert>
              <ForgotPassword />
            </AuthGated>
          </Route>
          <Route path={'/change-password'} exact={true}>
            <AuthGated>
              <ChangePassword />
            </AuthGated>
          </Route>
          <Route path={['/settings/userProfile', '/settings/userProfile/:userId']} exact={true}>
            <AuthGated stripeSpecific>
              <UserProfileManagement />
            </AuthGated>
          </Route>
          <Route path={'/invitation/:email'} exact={true}>
            <InvitationCompletion />
          </Route>
          <Route path="*" exact={true} component={FourOhFour} />
        </Switch>
      </div>
      <Footer />
      <EmailVerificationModal />
    </div>
  );
};

const GuestRoutes = (): ReactElement => {
  const project = useConcreteProject();
  const GuestUser = React.lazy(() => import(`../ProjectSpecific/${project.id}/GuestUser`));

  return (
    <div className="layout-wrapper">
      <div className="site-body">
        <React.Suspense fallback={<Loading waitingFor="Project speicific component" />}>
          <Switch>
            <Route path="/sign-in" exact={true} component={AuthPage} />
            <Route component={GuestUser} />
            <Route path="/register" exact={true} component={RegisterPage} />
            <Route path="/verification" exact={true} component={VerificationPage} />
          </Switch>
        </React.Suspense>
      </div>
    </div>
  );
};
const AppContent = (): ReactElement => {
  const project = useConcreteProject();
  const appUser = useAuth();
  if ((project.id === 'polis_appen' || project.id === 'softwerk') && !appUser.isAdmin && !appUser.isStaff) {
    return <GuestRoutes />;
  } else {
    return <Routes />;
  }
};

export const Layout = (): ReactElement => {
  const localization = useLocalization();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const project = useConcreteProject();
  const auth = useAuth();
  const firestore = useFirestore();
  const activeSubProject = useContext(SubprojectContext);
  const [loading, setLoading] = React.useState<boolean>();

  // Insert favicon
  const oldLink: HTMLLinkElement | null =
    document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
  const newLink1 = document.createElement('link');
  const newLink2 = document.createElement('link');

  newLink1.rel = 'icon';
  newLink2.rel = 'shortcut icon';
  newLink1.type = 'image/x-icon';
  newLink2.type = 'image/x-icon';
  newLink1.href = `/assets/images/active/shared/favicon.ico`;
  newLink2.href = `/assets/images/active/shared/favicon.ico`;

  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(newLink1);
  document.head.appendChild(newLink2);

  const CacheReloadToastContent = ({ closeToast }: ToastContentProps) => (
    <div>
      <strong>{localization.strings.versionOutdated.title}</strong>
      <p>{localization.strings.versionOutdated.body}</p>
      <IconButton
        onClick={() => {
          void emptyCacheStorage();
          closeToast?.();
        }}
        text={localization.strings.versionOutdated.button}
        icon={faSync}
      />
    </div>
  );

  // Force cache reload when a new deployment has been made
  useEffect(() => {
    if (!isLatestVersion) {
      toast(CacheReloadToastContent, {
        position: 'bottom-left',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLatestVersion]);

  useEffect(() => {
    if (auth.user?.uid) {
      setLoading(true);
      getDoc(doc(firestore, 'cmsUser', auth.user.uid))
        .then((_cmsUser) => {
          if (_cmsUser.exists()) {
            const userData = _cmsUser.data() as CMSUserData;
            if (userData && userData.projectId && project.id !== userData.projectId) {
              getDoc(doc(firestore, 'project', userData.projectId))
                .then((proj) => {
                  if (proj.exists() && proj.data()) {
                    activeSubProject.set(proj.id, proj.data().name, proj.data().style);
                    activeSubProject.setLogoUrl(proj.data().logo);
                    setLoading(false);
                  }
                })
                .catch((err) => {
                  console.log('error:', err);
                  setLoading(false);
                });
            } else {
              setLoading(false);
              return;
            }
          } else {
            setLoading(false);
            return;
          }
        })
        .catch((err) => {
          console.log('error:', err);
          setLoading(false);
        });
    } else {
      activeSubProject.resetToMainStyles();
      setLoading(false);
      return;
    }
    // eslint-disable-next-line
  }, [auth.user]);

  return loading || auth.loading ? (
    <Loading />
  ) : (
    <Router>
      <ToastContainer transition={Slide} hideProgressBar={true} newestOnTop limit={3} position="bottom-center" />
      <AppContent />
      <ModalContextDisplayer />
    </Router>
  );
};

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useCategories, useExternals } from '../../ContextProviders/AppContext';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading, LoadingSVG } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { useCollection } from '../../../Hooks';
import { Category, CMSUser, ExternalContent, StaffPassword, StripeUser, WithID } from '../../../Types';
import { useMonofunction } from '../../ContextProviders/Firebase';
import ChangePasswordForm from './ChangePasswordForm';
import { CMSUserData, UserRole } from '@eir/core';
import { CategoryLocker } from './CategoryLocker';
import './StaffAccounts.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { toasts } from '../../../shared';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import AgroScanAccess from './AgroScan/AgroScanAccess';
import { useConcreteProject, useProjectTitle } from '../../ContextProviders/ProjectContext';
import { DarkButton } from '../../Buttons/Buttons';
import { useHistory } from 'react-router-dom';
import { toCSV } from '../../../util/DataHelpers';
import { toast } from 'react-toastify';
import confirm from 'reactstrap-confirm';
import MultiStaffAccount from './MultiStaffAccount/MultiStaffAccount';
type AccessRole = CMSUserData & WithID & Record<string, unknown> & { hasOngoingSubscription?: boolean };

const Management = (): ReactElement => {
  const localization = useLocalization();
  const project = useConcreteProject();
  const history = useHistory();
  const [accountIsSelected, setAccountIsSelected] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<AccessRole>({
    admin: '',
    categoryPermissions: [],
    createdOn: null,
    email: '',
    fId: localization.strings.settings.selectAccount,
    role: UserRole.STAFF,
    displayName: localization.strings.settings.selectAccount,
  });
  const [searchText, setSearchText] = useState('');
  const [usersList, setUsersList] = useState<JSX.Element[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const { docs: categories, error, loading: catLoading } = useCategories();
  const { docs: externals, error: extError } = useExternals();
  const { docs: CMSUsers, error: arError, loading: arLoading } = useCollection<CMSUser>('/cmsUser', {});
  const {
    docs: stripeUsers,
    error: stripeError,
    loading: stripeLoading,
  } = useCollection<StripeUser>(project.enableStripe ? '/stripe/data/users' : undefined, {});
  const { docs: passwords, error: passError, loading: passLoading } = useCollection<StaffPassword>('/auth', {});

  const stripeUserSubscriptionsMap = useMemo(
    () => new Map((stripeUsers ?? []).map((item) => [item.fId, item])),
    [stripeUsers],
  );
  const firebaseFunctions = useMonofunction();

  // spinner UI for one btn at a time
  const [buttonLoadingStates, setButtonLoadingStates] = useState<boolean[]>(
    Array(categories.length + externals.length).fill(false),
  );
  // disabled/unclickable for all btns
  const [btnsAreDisabled, setBtnsAreDisabled] = useState(false);

  const title = useProjectTitle();
  useEffect(() => {
    document.title = `${localization.strings.settings.staffAccountMng} | ${title}`;
  }, [title, localization.strings.settings.staffAccountMng]);

  useEffect(() => {
    const updatedUser = CMSUsers.find((ar) => ar.fId === selectedAccount.fId);
    if (updatedUser) {
      const stripeUserSubscription = stripeUserSubscriptionsMap.get(updatedUser.fId)?.subscription;
      setSelectedAccount({ ...updatedUser, hasOngoingSubscription: !!stripeUserSubscription });
    }
  }, [CMSUsers, selectedAccount.fId, passwords, stripeUserSubscriptionsMap]);

  useEffect(() => {
    const ul = CMSUsers.filter((ar) => {
      if (ar.role !== UserRole.STAFF) return false;
      if (ar.displayName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim())) return true;
      return false;
    }).map((ar) => (
      <DropdownItem
        key={`_${ar.displayName}`}
        style={{ margin: '0' }}
        onClick={() => {
          setSelectedAccount({ ...ar, hasOngoingSubscription: !!stripeUserSubscriptionsMap.get(ar.fId)?.subscription });
          setAccountIsSelected(true);
        }}
      >
        {ar.displayName}
      </DropdownItem>
    ));

    setUsersList(ul);
  }, [CMSUsers, searchText, stripeUserSubscriptionsMap]);

  useEffect(() => {
    setButtonLoadingStates(Array(selectedAccount.categoryPermissions.length).fill(false));
    setBtnsAreDisabled(false);
  }, [selectedAccount.categoryPermissions]);

  useEffect(() => {
    if (isOpen) setIsOpen(true);
    else
      setTimeout(() => {
        setSearchText('');
        setIsOpen(false);
      }, 150);
    return () => {
      /* */
    };
  }, [isOpen]);

  useEffect(() => {
    setButtonLoadingStates(Array(selectedAccount.categoryPermissions.length).fill(false));
    setBtnsAreDisabled(false);
  }, [selectedAccount.categoryPermissions]);

  if (extError) return <ErrorPage error={extError} />;
  if (error) return <ErrorPage error={error} />;
  if (arError) return <ErrorPage error={arError} />;
  if (passError) return <ErrorPage error={passError} />;
  if (catLoading) return <Loading waitingFor={localization.strings.settings.categories} />;
  if (arLoading) return <Loading waitingFor={localization.strings.settings.accessRoles} />;
  if (passLoading) return <Loading />;
  if (project.enableStripe) {
    if (stripeError) return <ErrorPage error={stripeError} />;
    if (stripeLoading) return <Loading />;
  }

  const setLoadingForButton = (id: number, state: boolean) => {
    const newLoadStates = [...buttonLoadingStates];
    newLoadStates[id] = state;
    setButtonLoadingStates(newLoadStates);
  };
  const onToggle = (index: number, category: Category | ExternalContent) => {
    const updatedAccount = { ...selectedAccount };
    const catPerms = [...selectedAccount.categoryPermissions];

    const canRead = updatedAccount.categoryPermissions.includes(category.fId);
    if (canRead) updatedAccount['categoryPermissions'] = catPerms.filter((cat) => cat !== category.fId);
    else updatedAccount['categoryPermissions'] = [...catPerms, category.fId];

    setBtnsAreDisabled(true);
    setLoadingForButton(index, true);

    firebaseFunctions
      .SetCategoryPermission({
        userId: updatedAccount.fId,
        categoryId: category.fId,
        canRead: !canRead,
        isExternal: 'content' in category,
      })
      .catch((err) => {
        setBtnsAreDisabled(false);
        setLoadingForButton(index, false);
        console.log(err);
        toasts.error(localization.strings.error.unknown);
      });
  };

  const exportUsersCSV = () => {
    const blob = new Blob(
      [
        toCSV(
          CMSUsers.filter((item) => item.role === UserRole.STAFF).map(
            ({ firstName = '', lastName = '', phoneNumber = '', email, fId }) => {
              const stripeInfo = stripeUserSubscriptionsMap.get(fId);
              return {
                firstName,
                lastName,
                email,
                phoneNumber,
                subscription: stripeInfo?.subscription
                  ? `https://dashboard.stripe.com/products/${stripeInfo.subscription}`
                  : '',
                stripeId: stripeInfo?.customerId
                  ? `https://dashboard.stripe.com/customers/${stripeInfo.customerId}`
                  : '',
              };
            },
          ),
          {
            firstName: localization.strings.settings.userProfile.account.firstName,
            lastName: localization.strings.settings.userProfile.account.lastName,
            email: localization.strings.settings.userProfile.account.email,
            phoneNumber: localization.strings.settings.userProfile.account.phone,
            subscription: localization.strings.settings.userProfile.subscription.title,
            stripeId: 'Stripe ID',
          },
        ),
      ],
      { type: 'text/csv' },
    );
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.fullName} users.csv`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleDelete = async (uid: string) => {
    const result = await confirm({
      title: `${localization.strings.settings.deleteAccount}`,
      message: `${localization.strings.settings.confirmDeleteAccount}:\n${uid}?`,
      confirmText: localization.strings.global.confirm,
      confirmColor: 'primary',
      cancelText: localization.strings.global.cancel,
      cancelColor: 'link text-danger',
    });
    if (!result) return;

    setIsLoading(true);
    void firebaseFunctions
      .DeleteUserById({ uid: uid })
      .then(() => {
        toast.success(localization.strings.settings.deleteAccountSuccess);
        setIsLoading(false);

        setSelectedAccount((prevState) => ({
          ...prevState,
          admin: '',
          categoryPermissions: [],
          createdOn: null,
          email: '',
          fId: localization.strings.settings.selectAccount,
          role: UserRole.STAFF,
          displayName: localization.strings.settings.selectAccount,
        }));

        setAccountIsSelected(false);
      })
      .catch((error) => {
        toast.error(localization.strings.settings.deleteAccountFailure);
        setIsLoading(false);
      });
  };

  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return project.enableMultiStaffAccountCreation ? (
    <>
      <MultiStaffAccount />
    </>
  ) : (
    <>
      {project.enableStripe && (
        <DarkButton onClick={exportUsersCSV}>{localization.strings.settings.export.exportAll}</DarkButton>
      )}
      <div className="accounts-search-container mt-3">
        <Dropdown className="accounts-search shadow-sm" toggle={toggle} isOpen={isOpen}>
          <DropdownToggle tag={'div'}>
            <Input
              onChange={({ target: { value } }) => setSearchText(value)}
              value={searchText}
              placeholder={selectedAccount.displayName}
              style={{ borderRadius: isOpen ? '0.4rem 0.4rem 0 0' : '0.4rem' }}
              className="account-select"
            />
            {!isOpen ? '' : <FontAwesomeIcon icon={faSortDown} style={{ pointerEvents: 'none' }} />}
          </DropdownToggle>
          <DropdownMenu className="list shadow-sm">
            {usersList.length ? (
              usersList
            ) : (
              <DropdownItem className="empty-result">{localization.strings.settings.emptyAccountSearch}</DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>

        {accountIsSelected && project.enableStripe && (
          <>
            <DarkButton onClick={() => history.push(`/settings/userProfile/${selectedAccount.fId}`)}>
              {localization.strings.settings.userProfile.title}
            </DarkButton>

            <DarkButton
              onClick={() =>
                window.open(
                  'https://dashboard.stripe.com/customers/' +
                    String(stripeUserSubscriptionsMap.get(selectedAccount.fId)?.customerId ?? ''),
                )
              }
            >
              Stripe
            </DarkButton>
            <DarkButton onClick={() => handleDelete(selectedAccount.fId)}>
              {loading && <LoadingSVG size={15} />}
              {localization.strings.settings.deleteAccount}
            </DarkButton>
          </>
        )}
      </div>

      {accountIsSelected ? (
        <>
          <div className="acc-mng-module shadow-sm">
            <ChangePasswordForm />
          </div>

          {selectedAccount.hasOngoingSubscription ? (
            <div className="acc-mng-module shadow-sm">
              {localization.strings.settings.staffAccountHasOngoingSubscription}
            </div>
          ) : (
            <>
              <div className="acc-mng-module shadow-sm">
                <h5>{localization.strings.settings.staffAccountCategories}</h5>
                <div className="categories">
                  {[...categories, ...externals].filter((c) => c.isProtected).length > 0
                    ? [...categories, ...externals]
                        .filter((c) => c.isProtected)
                        .map((c, index) => (
                          <React.Fragment key={c.fId}>
                            {'content' in c ? (
                              <div className="name-with-sub">
                                <div>{c.name}</div>
                                {'content' in c && (
                                  <div className="sub">{localization.strings.external.externalContent}</div>
                                )}
                              </div>
                            ) : (
                              <div className="name">
                                <div>{c.name}</div>
                              </div>
                            )}
                            <div className="toggle">
                              <CategoryLocker
                                canRead={selectedAccount.categoryPermissions.includes(c.fId)}
                                isDisabled={btnsAreDisabled}
                                isLoading={buttonLoadingStates[index]}
                                onToggle={() => onToggle(index, c)}
                              />
                            </div>
                          </React.Fragment>
                        ))
                    : localization.strings.settings.staffAccountCategoriesPlaceholder}
                </div>
              </div>

              {project.enableVultusAPI && <AgroScanAccess userId={selectedAccount.fId} />}
            </>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

export const StaffAccountManagement = (): ReactElement => {
  const localization = useLocalization();
  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.staffAccountMng}</h1>
          <hr />
          <Management />
        </div>
      </main>
    </PageWithSidebar>
  );
};

import { FC, FormEvent, useEffect, useState } from 'react';
import { Col, Form, FormFeedback, Input, InputGroup } from 'reactstrap';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { IconButton } from '../../Buttons/Buttons';
import { faSave, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useDocument } from '../../../Hooks';
import { CMSUser } from '../../../Types';
import { toasts } from '../../../shared';
import { FirebaseError } from 'firebase/app';
import { Loading } from '../Loading/Loading';
import { useMonofunction } from '../../ContextProviders/Firebase';

const validatePhone = (value: string) => {
  const re = /^\+[1-9]\d{10,15}$/;
  return re.test(value);
};

const EditAccount: FC<{ userId: string }> = ({ userId }) => {
  const { strings } = useLocalization();
  const { doc, error, loading } = useDocument<CMSUser>(`cmsUser/${userId}`, {});

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [phoneFieldErrorMessage, setPhoneFieldErrorMessage] = useState('');

  useEffect(() => {
    setFirstName(doc.firstName);
    setLastName(doc.lastName);
    setPhone(doc.phoneNumber);
  }, [doc]);

  const [isUpdating, setUpdating] = useState(false);

  const functions = useMonofunction();

  const areRequiredFieldsEmpty = !phone || !lastName || !firstName;

  const handleErrors = ({ code }: FirebaseError) => {
    // https://firebase.google.com/docs/auth/admin/errors
    switch (code) {
      case 'auth/invalid-phone-number':
        setPhoneFieldErrorMessage(strings.auth.phoneErrorDisplay);
        break;
      case 'auth/phone-number-already-exists':
        setPhoneFieldErrorMessage(strings.auth.phoneAlreadyExists);
        break;
      default:
        toasts.error(strings.auth.error.unknown);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (areRequiredFieldsEmpty || phoneFieldErrorMessage) return;
    try {
      setUpdating(true);
      await functions.StaffUpdateSelf({ firstName, lastName, phoneNumber: phone });
    } catch (error) {
      handleErrors(error);
    } finally {
      setUpdating(false);
    }
  };

  const onPhoneChange = (value?: string) => {
    setPhone(value);
    if (!value || value.trim().length > 0) {
      setPhoneFieldErrorMessage(validatePhone(value ?? '') ? '' : strings.auth.phoneErrorDisplay);
    } else {
      setPhoneFieldErrorMessage('');
    }
  };

  const getFormLayout = () => {
    if (error) return <div>{error.toString()}</div>;
    if (loading) return <Loading />;
    return (
      <>
        <div className="mb-4">
          <div className="mb-2">
            <InputGroup style={{ width: 'calc(100% - 25px)' }}>
              <Input
                placeholder={strings.auth.firstNamePlaceholder}
                invalid={!firstName}
                disabled={isUpdating}
                value={firstName}
                onChange={({ target: { value } }) => {
                  setFirstName(value);
                }}
              />
            </InputGroup>
          </div>

          <div className="mb-2">
            <InputGroup style={{ width: 'calc(100% - 25px)' }}>
              <Input
                placeholder={strings.auth.lastNamePlaceholder}
                disabled={isUpdating}
                invalid={!lastName}
                value={lastName}
                onChange={({ target: { value } }) => {
                  setLastName(value);
                }}
              />
            </InputGroup>
          </div>

          <div className="mb-2">
            <InputGroup style={{ width: 'calc(100% - 25px)' }}>
              <Input
                placeholder={strings.auth.phonePlaceHolder}
                type="tel"
                invalid={!!phoneFieldErrorMessage}
                disabled={isUpdating}
                value={phone}
                onChange={({ target: { value } }) => {
                  onPhoneChange(value);
                }}
              />
            </InputGroup>
            <FormFeedback>{phoneFieldErrorMessage}</FormFeedback>
          </div>
        </div>
        <IconButton
          type="submit"
          theme="dark"
          disabled={areRequiredFieldsEmpty || phoneFieldErrorMessage || isUpdating}
          isLoading={isUpdating}
          icon={faSave}
          text={strings.global.save}
        />
        <IconButton
          type="button"
          theme="dark"
          onClick={() => {
            setFirstName(doc.firstName);
            setLastName(doc.lastName);
            onPhoneChange(doc.phoneNumber);
          }}
          disabled={isUpdating}
          icon={faXmark}
          text={strings.global.reset}
        />
      </>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Col sm={12} md={10}>
        <div className="mb-4">
          <h5>{strings.auth.editAccountInfo}</h5>
        </div>
        <Form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={onSubmit}
        >
          {getFormLayout()}
        </Form>
      </Col>
    </div>
  );
};

export default EditAccount;

import { ReactElement, useEffect, useState } from 'react';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import './SettingsPage.scss';
import './ArticleTransfer.scss';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useArticlesDraft, useCategoriesDraft, useSectionsPublished } from '../../ContextProviders/AppContext';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading } from '../Loading/Loading';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { sortByOrderIndex } from '../../../util/DataHelpers';
import { IconButton } from '../../Buttons/Buttons';
import { faSquareArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { useAuth } from '../../ContextProviders/Auth';
import { articleActions, sectionActions } from '../../../Hooks/DatabaseActions';
import { useFirestore } from '../../ContextProviders/Firebase';
import {
  Article,
  Category,
  IsChangelog,
  IsChief,
  IsDraft,
  Ordered,
  Section,
  WithID,
  WithLastUpdateDate,
} from '../../../Types';
import { FirestoreCollection } from '../../../Hooks/Database';
import { ArticleData, SectionData } from '@eir/core';

interface ArticleTemp {
  fId: string;
  name: string;
  orderIndex: number;
  section?: string;
}

interface SectionTemp {
  fId: string;
  name: string;
  orderIndex: number;
  articles: ArticleTemp[];
}

interface IndexedCategory {
  fId: string;
  name: string;
  sections: SectionTemp[];
  articles: ArticleTemp[];
}

interface IndexedCategories {
  [key: string]: IndexedCategory;
}

const useItemDetails = (id: string | undefined, indexedCategories: IndexedCategories) => {
  const item = Object.values(indexedCategories).find((item) => item.fId === id);
  return item;
};

const buildCategoryTree = (
  sections: FirestoreCollection<SectionData & WithID & Ordered & IsDraft & IsChief & IsChangelog>,
  articles: FirestoreCollection<ArticleData & WithID & Ordered & WithLastUpdateDate & IsDraft & IsChief & IsChangelog>,
  movableCategories: Category[],
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const categories: { [key: string]: any } = {};
  sections.docs.forEach((section: Section) => {
    const { category, fId, name, orderIndex } = section;
    const __categoryTemp = movableCategories.find((cat) => cat.fId === section.category);

    if (__categoryTemp) {
      if (!categories[category]) {
        categories[category] = { fId: category, name: __categoryTemp.name, sections: [], articles: [] };
      }

      categories[category].sections.push({
        fId,
        name,
        orderIndex,
        articles: [],
      });
    }
  });

  articles.docs.forEach((article: Article) => {
    const { category, section, fId, name, orderIndex } = article;
    const __categoryTemp = movableCategories.find((cat) => cat.fId === article.category);

    if (__categoryTemp) {
      if (!categories[category]) {
        categories[category] = { fId: category, name: __categoryTemp.name, sections: [], articles: [] };
      }

      if (section) {
        const sectionObj = categories[category].sections.find((sec: Section) => sec.fId === section);
        if (sectionObj) {
          sectionObj.articles.push({ fId, name, orderIndex, section });
        } else {
          categories[category].articles.push({ fId, name, orderIndex, section });
        }
      } else {
        categories[category].articles.push({ fId, name, orderIndex });
      }
    }
  });
  Object.values(categories).forEach((category) => {
    category.sections.sort((a: Section, b: Section) => a.orderIndex - b.orderIndex);
    category.sections.forEach((section: SectionTemp) => {
      section.articles.sort((a, b) => a.orderIndex - b.orderIndex);
    });
    category.articles.sort((a: Article, b: Article) => a.orderIndex - b.orderIndex);
  });
  return categories;
};

export const FilterableList = (): ReactElement => {
  const localization = useLocalization();
  const { docs: categories, error, loading } = useCategoriesDraft();
  const articles = useArticlesDraft();
  const sections = useSectionsPublished();
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const project = useConcreteProject();
  const auth = useAuth();
  const firestore = useFirestore();

  const [movableCategories, setMovableCategories] = useState(
    [...categories]
      .filter((category) => category.type === 'articleCategory' && category.isChangelog !== true)
      .sort(sortByOrderIndex),
  );

  const [indexedCategories, setCategories] = useState<IndexedCategories>(
    buildCategoryTree(sections, articles, movableCategories),
  );
  const [selectedCategoryOne, setSelectedCategoryOne] = useState<string | undefined>(undefined);
  const [selectedCategoryTwo, setSelectedCategoryTwo] = useState<string | undefined>(undefined);

  const [dropdownOneOpen, setDropdownOneOpen] = useState(false);
  const [dropdownTwoOpen, setDropdownTwoOpen] = useState(false);

  const categoryOneTree = useItemDetails(selectedCategoryOne, indexedCategories);
  const categoryTwoTree = useItemDetails(selectedCategoryTwo, indexedCategories);

  const toggleDropdownOne = () => setDropdownOneOpen(!dropdownOneOpen);
  const toggleDropdownTwo = () => setDropdownTwoOpen(!dropdownTwoOpen);

  const selectCategoryOne = (id: string) => {
    setSelectedCategoryOne(id);
  };

  const selectCategoryTwo = (id: string) => {
    setSelectedCategoryTwo(id);
  };

  useEffect(() => {
    setMovableCategories(
      [...categories]
        .filter((category) => category.type === 'articleCategory' && category.isChangelog !== true)
        .sort(sortByOrderIndex),
    );
    setCategories(buildCategoryTree(sections, articles, movableCategories));
  }, [sections, articles, refreshTrigger, categories, movableCategories]);

  const moveSection = async (sectionId: string, targetCategory: string | undefined) => {
    const filteredSections = sections.docs.filter((section) => section.category === targetCategory);
    const sortedOrderIndex = filteredSections.sort(sortByOrderIndex);
    let newOrderIndex: number;
    if (sortedOrderIndex.length > 0) {
      newOrderIndex = sortedOrderIndex[sortedOrderIndex.length - 1].orderIndex + 1;
    } else {
      newOrderIndex = 0;
    }
    try {
      const section = sections.docs.find((section) => section.fId === sectionId);
      await sectionActions(firestore, project.id).update(sectionId, {
        orderIndex: newOrderIndex,
        category: targetCategory,
        deleted: false,
        name: section?.name,
      });

      const filteredArticles = articles.docs.filter((article) => article.section === sectionId);
      const sortedArticles = filteredArticles.sort((a, b) => b.orderIndex - a.orderIndex);

      for (const article of sortedArticles) {
        await moveArticle(article.fId, targetCategory, sectionId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshTrigger((old) => old + 1);
    }
  };

  const moveArticle = async (articleId: string, targetCategory: string | undefined, sectionToMoveTo: string | null) => {
    try {
      if (sectionToMoveTo) {
        await articleActions(firestore, project.id, auth.displayName).update(articleId, {
          category: targetCategory,
          section: sectionToMoveTo,
        });
      } else {
        const filteredArticles = articles.docs.filter((article) => article.category === targetCategory);
        const sortedOrderIndex = filteredArticles.sort(sortByOrderIndex);
        let newOrderIndex;
        if (sortedOrderIndex.length > 0) {
          newOrderIndex = sortedOrderIndex[sortedOrderIndex.length - 1].orderIndex + 1;
        } else {
          newOrderIndex = 0;
        }
        await articleActions(firestore, project.id, auth.displayName).update(articleId, {
          category: targetCategory,
          section: sectionToMoveTo,
          orderIndex: newOrderIndex,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshTrigger((old) => old + 1);
    }
  };

  const renderCategoryDetails = (tree: IndexedCategory, categoryOne: boolean) => {
    return (
      <div className="category-tree-box">
        {tree.sections.map((section: SectionTemp) => (
          <div key={section.fId}>
            <div className="list-container">
              <div className="item-container">
                <p className="sectionName">{section.name}</p>
                {categoryOne && (
                  <IconButton
                    type="button"
                    theme="dark"
                    onClick={() => moveSection(section.fId, selectedCategoryTwo)}
                    icon={faSquareArrowRight}
                    text={localization.strings.settings.transferArticle.moveSection}
                    disabled={!categoryTwoTree}
                  />
                )}
              </div>
              <ul className="no-bullets">
                {section.articles.map((article: ArticleTemp) => (
                  <li key={article.fId} className="item-container">
                    <span className="name">{article.name}</span>
                    {categoryOne && (
                      <IconButton
                        type="button"
                        theme="dark"
                        onClick={() => moveArticle(article.fId, selectedCategoryTwo, null)}
                        icon={faSquareArrowRight}
                        text={localization.strings.settings.transferArticle.moveArticle}
                        disabled={!categoryTwoTree}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
        {tree && tree.articles.length > 0 && (
          <div>
            <h4>{localization.strings.section.defaultSection}</h4>
            <ul className="no-bullets">
              {tree.articles.map((article: ArticleTemp) => (
                <li key={article.fId} className="item-container">
                  <span className="name">{article.name}</span>
                  {categoryOne && (
                    <IconButton
                      type="button"
                      theme="dark"
                      onClick={() => moveArticle(article.fId, selectedCategoryTwo, null)}
                      icon={faSquareArrowRight}
                      text={localization.strings.settings.transferArticle.moveArticle}
                      disabled={!categoryTwoTree}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading waitingFor={localization.strings.settings.categories} />;

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.transferArticle.articleTransfer}</h1>
          <hr />
          <div className="articleTransfer-page">
            <h2>{localization.strings.settings.transferArticle.transferArticleHeaderDesc}</h2>
            <p>
              {localization.strings.settings.transferArticle.transferArticleDescription +
                localization.strings.section.defaultSection}
            </p>
            <p>{localization.strings.settings.transferArticle.publishing}</p>
            <div className="mainContent">
              <div>
                <div>
                  <Dropdown isOpen={dropdownOneOpen} toggle={toggleDropdownOne} direction={'down'}>
                    <DropdownToggle caret>
                      {Object.values(indexedCategories).find((category) => category.fId === selectedCategoryOne)
                        ?.name || localization.strings.settings.transferArticle.selectCategoryOne}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.values(indexedCategories).map((category) => {
                        return (
                          <DropdownItem
                            toggle
                            key={category.fId}
                            style={{ margin: '0' }}
                            onClick={() => selectCategoryOne(category.fId)}
                            disabled={selectedCategoryTwo === category.fId}
                          >
                            {category.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>{categoryOneTree && <div>{renderCategoryDetails(categoryOneTree, true)}</div>}</div>
              </div>
              <div>
                <div>
                  <Dropdown isOpen={dropdownTwoOpen} toggle={toggleDropdownTwo} direction={'down'}>
                    <DropdownToggle caret>
                      {Object.values(indexedCategories).find((category) => category.fId === selectedCategoryTwo)
                        ?.name || localization.strings.settings.transferArticle.selectCategoryTwo}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.values(indexedCategories).map((category) => {
                        return (
                          <DropdownItem
                            toggle
                            key={category.fId}
                            style={{ margin: '0' }}
                            onClick={() => selectCategoryTwo(category.fId)}
                            disabled={selectedCategoryOne === category.fId}
                          >
                            {category.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>{categoryTwoTree && <div>{renderCategoryDetails(categoryTwoTree, false)}</div>}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </PageWithSidebar>
  );
};

import { faCircleInfo, faEye, faEyeSlash, faSave, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { useState, FormEvent } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';

import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { useAuth } from '../../../ContextProviders/Auth';
import { IconButton } from '../../../Buttons/Buttons';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  updatePassword,
} from 'firebase/auth';
import { useFirebase } from '../../../ContextProviders/Firebase';

const ChangePassword = () => {
  const localization = useLocalization();
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [oldPass, setOldPass] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passMain, setPassMain] = useState<string>('');
  const [passRetype, setPassRetype] = useState<string>('');
  const [errors, setErrors] = useState<{ old: boolean; main: boolean; notUnique: boolean; retype: boolean }>({
    old: false,
    main: false,
    notUnique: false,
    retype: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const auth = useAuth();
  const firebase = useFirebase();

  const setAllErrors = (value: boolean) => {
    setErrors({ old: value, main: value, notUnique: value, retype: value });
  };

  const checkValidity = (): boolean => {
    if (passMain.length < 8 || passMain.length > 20) {
      setErrors({ ...errors, main: true });
      return false;
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    let ctr = 0;
    if (passMain.length > 7) {
      ctr++;
    }

    if (regex.test(passMain)) {
      ctr++;
    }

    if (passMain.trim() !== passMain) {
      // there exits whitespace in the string
      ctr = 0;
    }

    if (ctr < 2 || passMain.length < 8) {
      setErrors({ ...errors, main: true });
      return false;
    }

    setErrors({ ...errors, main: false });
    return checkPassMatch();
  };

  const checkPassMatch = (): boolean => {
    if (!isEqual(passMain, passRetype)) {
      setErrors({ ...errors, retype: true });
      return false;
    }
    setErrors({ ...errors, retype: false });
    return true;
  };

  const onSuccess = () => {
    toast.success(localization.strings.auth.passwordChangeSuccess);
    setAllErrors(false);
    setPassMain('');
    setPassRetype('');
    setOldPass('');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    if (!auth.user?.uid || !auth.user.email) throw new Error(localization.strings.auth.notAuthenticated);

    e.preventDefault();
    e.stopPropagation();

    if (checkValidity()) {
      setLoading(true);
      try {
        const credential = EmailAuthProvider.credential(auth.user.email, oldPass);
        await reauthenticateWithCredential(auth.user, credential);
        await updatePassword(auth.user, passMain);
        onSuccess();
      } catch (e) {
        setErrors((prevValue) => ({
          ...prevValue,
          ...(['auth/wrong-password', 'auth/invalid-login-credentials'].includes(e.code)
            ? { old: true }
            : { main: true }),
        }));
        toast.error(localization.strings.auth.passwordChangeFailure);
      } finally {
        setLoading(false);
      }
    }
  };

  const sendReset = (): void => {
    if (!auth.user || !auth.user.email) {
      toast.error(localization.strings.settings.emailError);
      return;
    }
    sendPasswordResetEmail(getAuth(firebase), auth.user?.email)
      .then(() => {
        toast.success(localization.strings.auth.resetEmailSent);
      })
      .catch(() => {
        toast.error(localization.strings.auth.resetEmailFail);
      });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Col sm={10} md={4}>
        <div className="mb-4">
          <h5>{localization.strings.settings.changeStaffPass}</h5>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="mb-2">
              <InputGroup style={{ width: 'calc(100% - 25px)' }}>
                <Input
                  type={showOldPassword ? 'text' : 'password'}
                  placeholder={localization.strings.auth.oldPasswordPlaceholder}
                  invalid={errors.old}
                  value={oldPass}
                  onChange={(e) => {
                    setOldPass(e.target.value);
                    setErrors((prevValue) => ({ ...prevValue, old: false }));
                  }}
                />
                <InputGroupText onClick={() => setShowOldPassword((prevValue) => !prevValue)}>
                  <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
                </InputGroupText>

                <FormFeedback>{localization.strings.auth.invalidOldPassword}</FormFeedback>
              </InputGroup>
            </div>

            <div className="mb-2">
              <InputGroup style={{ width: 'calc(100% - 25px)' }}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder={localization.strings.auth.passwordPlaceholder}
                  invalid={errors.main}
                  value={passMain}
                  onChange={(e) => {
                    setPassMain(e.target.value);
                    setErrors({ ...errors, main: false });
                  }}
                />
                <InputGroupText onClick={() => setShowPassword((prevValue) => !prevValue)}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
                </InputGroupText>
                <FormFeedback>
                  {errors.notUnique
                    ? localization.strings.auth.staffPasswordNotUnique
                    : localization.strings.auth.invalidPasswordFormat}
                </FormFeedback>
              </InputGroup>
            </div>

            <div className="mb-2">
              <InputGroup style={{ width: 'calc(100% - 25px)' }}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder={localization.strings.auth.passwordPlaceholderRetype}
                  aria-label="retype password"
                  aria-describedby="basic-addon1"
                  invalid={errors.retype}
                  value={passRetype}
                  onChange={(e) => {
                    setPassRetype(e.target.value);
                    setErrors({ ...errors, retype: false });
                  }}
                />
                <FormFeedback>{localization.strings.auth.invalidPasswordRetype}</FormFeedback>
              </InputGroup>
            </div>
          </div>
          <IconButton
            type="submit"
            theme="dark"
            isLoading={loading}
            icon={faSave}
            text={localization.strings.global.save}
          />
          <IconButton
            type="button"
            theme="dark"
            onClick={() => {
              setPassMain('');
              setPassRetype('');
              setOldPass('');
              setAllErrors(false);
            }}
            disabled={loading}
            icon={faXmark}
            text={localization.strings.global.clear}
          />
          <IconButton type="button" id={`STAFF-info-button`} icon={faCircleInfo} theme="dark" />
          <UncontrolledPopover placement="right-start" target={`STAFF-info-button`} trigger="click">
            <PopoverHeader> {localization.strings.auth.passwordRules.staffHeader}</PopoverHeader>
            <PopoverBody>
              <div>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.lowercase}</p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.uppercase}</p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.adminMin}</p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.whitespace}</p>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </Form>
        <div className="mt-4">
          <Button color="link" onClick={() => sendReset()}>
            {localization.strings.settings.multiStaffAccount.forgotPassword}
          </Button>
        </div>
      </Col>
    </div>
  );
};

export default ChangePassword;

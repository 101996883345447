import { ReactElement, useEffect, useState } from 'react';
import './AuthPage.scss';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import { applyActionCode, getAuth } from 'firebase/auth';
import { useFirebase } from '../../ContextProviders/Firebase';
import { Status } from '../../ContextProviders/Auth';
import { LoadingSVG } from '../Loading/Loading';

export const VerificationPage = (): ReactElement => {
  const localization = useLocalization();
  const projectConfig = useProjectConfig();
  const firebase = useFirebase();
  const [verifyMessage, setVerifyMessage] = useState('');
  const [status, setStatus] = useState(Status.IDLE);

  useEffect(() => {
    document.title = `${localization.strings.auth.emailVerification.verify} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.auth.emailVerification.verify]);

  useEffect(() => {
    const auth = getAuth(firebase);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const oobCode = urlParams.get('oobCode');

    if (oobCode !== null) {
      setStatus(Status.IN_FLIGHT);
      applyActionCode(auth, oobCode)
        .then(() => {
          setVerifyMessage(localization.strings.auth.emailVerification.verified);
        })
        .catch(({ reason }) => {
          console.log(reason);
          setVerifyMessage(localization.strings.auth.emailVerification.error);
        })
        .finally(() => setStatus(Status.IDLE));
    } else setVerifyMessage(localization.strings.auth.emailVerification.invalidToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase]);

  return (
    <div className="centered-page-box">
      <div className="login-form">
        <div className="login-header text-center">
          <h2 className="mb-5 font-weight-bold">{localization.strings.auth.emailVerification.verify}</h2>
          {status === Status.IN_FLIGHT ? <LoadingSVG size={50} /> : <h3>{verifyMessage}</h3>}
        </div>
      </div>
    </div>
  );
};

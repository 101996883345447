import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { PageWithSidebar } from '../../PageTypes';
import { SettingsSidebar } from '../SettingsSidebar';
import { useHistory, useParams } from 'react-router-dom';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { useDocument } from '../../../../Hooks';
import { Subscription } from '../../../../Types';
import { useCategories, useExternals, useProjectConfig } from '../../../ContextProviders/AppContext';
import { ErrorPage } from '../../Error/ErrorPage';
import { Loading } from '../../Loading/Loading';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { useConcreteProject } from '../../../ContextProviders/ProjectContext';
import { AgroScanMutation, AgroScanQuery, AgroScanUserConfiguration } from '@eir/core';
import { getAgroScanAccessDescriptionRecords } from '../../../../util/DataHelpers';
import { CategoryLocker } from '../CategoryLocker';
import { IconButton } from '../../../Buttons/Buttons';
import { faPlus, faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useCreateSubscriptionFunction, useFirestore } from '../../../ContextProviders/Firebase';
import { subscriptionsActions } from '../../../../Hooks/DatabaseActions';
import { toast } from 'react-toastify';
import { Status } from '../../../ContextProviders/Auth';

interface Params {
  id?: string;
}
const EditSubscription: FC<{}> = () => {
  const { id } = useParams<Params>();
  const willEdit = !!id;
  const { strings } = useLocalization();
  const projectConfig = useProjectConfig();
  const { doc, error, loading } = useDocument<Subscription>(id ? `/stripe/data/subscriptions/${id}` : undefined, {});

  const title = useMemo(
    () => (willEdit ? strings.settings.subscriptions.edit.title : strings.settings.subscriptions.create.title),
    [strings.settings.subscriptions.create.title, strings.settings.subscriptions.edit.title, willEdit],
  );
  useEffect(() => {
    document.title = `${title} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, title]);

  const getContent = () => {
    if (id) {
      if (error) return <ErrorPage error={error} />;
      if (loading) return <Loading waitingFor={strings.settings.subscriptions.edit.title} />;
      return <EditSubscriptionContent type="edit" doc={doc} />;
    } else return <EditSubscriptionContent type="create" />;
  };

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{title}</h1>
          <hr />
          {getContent()}
        </div>
      </main>
    </PageWithSidebar>
  );
};

type Props =
  | {
      type: 'edit';
      doc: Subscription;
    }
  | { type: 'create' };

const AgroScanAccessMap = Object.values({ ...AgroScanQuery, ...AgroScanMutation }).reduce((acc, value) => {
  acc[value] = false;
  return acc;
}, {} as AgroScanUserConfiguration['access']);

const EditSubscriptionContent: FC<Props> = (props) => {
  const { strings } = useLocalization();
  const history = useHistory();
  const { enableVultusAPI } = useConcreteProject();
  const { docs: categories } = useCategories();
  const { docs: externals } = useExternals();
  const firestore = useFirestore();
  const [requestStatus, setRequestStatus] = useState(Status.IDLE);

  const [stripeProductId, setStripeProductId] = useState(props.type === 'edit' ? props.doc.fId : '');
  const [stripeProductIdErrorMessage, setStripeProductIdErrorMessage] = useState<string>();
  const { update } = subscriptionsActions(firestore);
  const createSubscription = useCreateSubscriptionFunction();

  const [hectares, setHectares] = useState<string>(props.type === 'edit' ? props.doc.hectares?.toString() ?? '' : '');
  const [isMostPopular, setMostPopular] = useState(props.type === 'edit' ? props.doc.isMostPopular : false);
  const [access, setAccess] = useState(
    props.type === 'edit' ? props.doc.access ?? AgroScanAccessMap : AgroScanAccessMap,
  );
  const [categoryPermissions, setCategoryPermissions] = useState(
    props.type === 'edit' ? props.doc.categoryPermissions ?? [] : [],
  );

  const handleCreate = () => {
    setRequestStatus(Status.IN_FLIGHT);
    createSubscription({
      productId: stripeProductId,
      isMostPopular,
      access,
      categoryPermissions,
      hectares: parseFloat(hectares),
    })
      .then(() => {
        setRequestStatus(Status.IDLE);
        toast.success(strings.settings.subscriptions.create.success);
        history.goBack();
      })
      .catch((error) => {
        setRequestStatus(Status.IDLE);
        switch (error.code) {
          case 'functions/invalid-argument':
            setStripeProductIdErrorMessage(strings.settings.subscriptions.create.stripeProductIdError);
            break;
          case 'functions/already-exists':
            setStripeProductIdErrorMessage(strings.settings.subscriptions.create.alreadyExists);
            break;
        }
        toast.error(strings.settings.subscriptions.create.error);
      });
  };

  const handleEdit = () => {
    setRequestStatus(Status.IN_FLIGHT);
    update(stripeProductId, {
      access,
      isMostPopular,
      categoryPermissions,
      hectares: parseFloat(hectares),
    })
      .then(() => {
        setRequestStatus(Status.IDLE);
        toast.success(strings.settings.subscriptions.edit.success);
        history.goBack();
      })
      .catch(() => {
        setRequestStatus(Status.IDLE);
        toast.error(strings.settings.subscriptions.edit.error);
      });
  };

  const isLoading = requestStatus === Status.IN_FLIGHT;
  return (
    <div>
      <div className="acc-mng-module shadow-sm">
        <h6>{strings.settings.subscriptions.stripeProductId}</h6>
        <Input
          type="text"
          placeholder={strings.settings.subscriptions.stripeProductId}
          disabled={props.type === 'edit' || isLoading}
          value={stripeProductId}
          onChange={({ target: { value } }) => {
            setStripeProductId(value);
            setStripeProductIdErrorMessage(undefined);
          }}
          invalid={!!stripeProductIdErrorMessage}
        />
        <FormFeedback>{stripeProductIdErrorMessage}</FormFeedback>

        {enableVultusAPI && (
          <>
            <h6 style={{ marginTop: '1rem' }}>{strings.settings.subscriptions.hectaresLimit}</h6>
            <Input
              type="number"
              placeholder={'Ha'}
              disabled={isLoading}
              value={hectares}
              onChange={({ target: { value } }) => setHectares(value)}
            />
          </>
        )}

        <FormGroup check inline style={{ marginTop: '1rem' }}>
          <Input
            type="checkbox"
            checked={isMostPopular}
            disabled={isLoading}
            onChange={() => setMostPopular((prevValue) => !prevValue)}
          />
          <Label check>{strings.settings.subscriptions.mostPopular}</Label>
        </FormGroup>
      </div>

      {enableVultusAPI && (
        <div className="acc-mng-module shadow-sm">
          <h5>{strings.settings.agroScan.staffAccountAccess}</h5>
          <div className="categories">
            {Object.entries(getAgroScanAccessDescriptionRecords(strings)).map(
              ([item, description]: [AgroScanMutation | AgroScanQuery, string]) => {
                console.log('item: ', item);
                console.log('description: ', description);

                return (
                  <Fragment key={item}>
                    <div className="name">{description}</div>
                    <div className="toggle">
                      <CategoryLocker
                        canRead={access?.[item]}
                        isDisabled={isLoading}
                        isLoading={false}
                        onToggle={() => setAccess((prevState) => ({ ...prevState, [item]: !prevState[item] }))}
                      />
                    </div>
                  </Fragment>
                );
              },
            )}
          </div>
        </div>
      )}

      <div className="acc-mng-module shadow-sm">
        <h5>{strings.settings.staffAccountCategories}</h5>
        <div className="categories">
          {[...categories, ...externals].filter((c) => c.isProtected).length > 0
            ? [...categories, ...externals]
                .filter((c) => c.isProtected)
                .map((c) => (
                  <Fragment key={c.fId}>
                    {'content' in c ? (
                      <div className="name-with-sub">
                        <div>{c.name}</div>
                        {'content' in c && <div className="sub">{strings.external.externalContent}</div>}
                      </div>
                    ) : (
                      <div className="name">
                        <div>{c.name}</div>
                      </div>
                    )}
                    <div className="toggle">
                      <CategoryLocker
                        canRead={categoryPermissions.includes(c.fId)}
                        isDisabled={isLoading}
                        isLoading={false}
                        onToggle={() =>
                          setCategoryPermissions((prevValues) =>
                            prevValues.includes(c.fId)
                              ? prevValues.filter((item) => item !== c.fId)
                              : [...prevValues, c.fId],
                          )
                        }
                      />
                    </div>
                  </Fragment>
                ))
            : strings.settings.staffAccountCategoriesPlaceholder}
        </div>
      </div>

      {props.type === 'create' ? (
        <IconButton
          theme="dark"
          onClick={handleCreate}
          disabled={isLoading || !stripeProductId || !!stripeProductIdErrorMessage}
          isLoading={isLoading}
          icon={faPlus}
          text={strings.settings.subscriptions.configuration.add}
        />
      ) : (
        <>
          <IconButton
            theme="dark"
            disabled={isLoading || !stripeProductId || !!stripeProductIdErrorMessage}
            isLoading={isLoading}
            onClick={handleEdit}
            icon={faSave}
            text={strings.global.save}
          />
          <IconButton
            theme="dark"
            disabled={isLoading}
            onClick={() => history.goBack()}
            icon={faTimes}
            text={strings.global.cancel}
          />
        </>
      )}
    </div>
  );
};

export default EditSubscription;

import { ReactElement, useEffect } from 'react';
import { PlatformContentFrame } from '../../PlatformContentFrame/PlatformContentFrame';
import { useExternal } from '../../ContextProviders/AppContext';
import { useParams, useHistory } from 'react-router-dom';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';
import { ExternalContentType } from '@eir/core';
import ExternalUrlFrame from './ExternalUrlFrame/ExternalUrlFrame';
import { WorkOrderIframe } from '../../Iframe/WorkorderIframe';

export interface ExternalParams {
  externalId: string;
}

export const ExternalPage = (): ReactElement => {
  const localization = useLocalization();
  const { externalId } = useParams<ExternalParams>();
  const external = useExternal(externalId);
  const title = useProjectTitle();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const externalPath = queryParams.get('path');
  if (externalPath) {
    history.push({
      pathname: `/external/${externalId}`,
      state: { externalPath },
    });
  }

  useEffect(() => {
    document.title = `${localization.strings.external.externalContent} | ${title}`;
  }, [title, localization.strings.external.externalContent]);

  if (!external) return <></>;

  if (!external.type || external.type === ExternalContentType.HTML)
    return <PlatformContentFrame body={external.content} canBeUrl />;

  if (external.fId && external.type === ExternalContentType.WORK_ORDER) {
    return <WorkOrderIframe workorderModule={external.fId} />;
  }

  return <ExternalUrlFrame contentType={external.type} url={external.content} />;
};

import { Article } from '../../Types';
import { createContext, useMemo, ReactNode, useContext, useState } from 'react';
import { useArticlesChangelog, useArticlesPublished, useCategories, useChiefArticles } from './AppContext';
import { useAuth } from './Auth';
import elasticlunr from 'elasticlunr';
import asciiFolder from 'fold-to-ascii';
import stemmerSupport from 'lunr-languages/lunr.stemmer.support';
import lunrSv from 'lunr-languages/lunr.sv';
import striptags from 'striptags';

interface SearchContextType {
  search: (query: string) => elasticlunr.SearchResults[];
}

const SearchContext = createContext<SearchContextType>({
  search: () => [],
});

const separationCharacter = ' ';

stemmerSupport(elasticlunr);
lunrSv(elasticlunr);

const replaceDiacritics = (token: string): string => asciiFolder.foldReplacing(token);
elasticlunr.Pipeline.registerFunction(replaceDiacritics, 'replaceDiacritics');

export function generateSearchIndex(articles: Article[]): elasticlunr.Index<Article> {
  const searchIndex = elasticlunr<Article>((index) => {
    index.addField('name');
    index.addField('content');
    index.setRef('fId');
    index.saveDocument(false);
    // eslint-disable-next-line
    index.use((elasticlunr as any).sv);
    // eslint-disable-next-line
    index.pipeline.after((elasticlunr as any).sv.trimmer, replaceDiacritics);
  });

  articles.forEach((doc) => {
    if (typeof doc.content === 'string')
      searchIndex.addDoc({
        ...doc,
        content: striptags(
          doc.content.replace(new RegExp('&nbsp;', 'g'), separationCharacter),
          [],
          separationCharacter,
        ),
      });
    else console.error(`Article: ${doc.fId} has no content string attribute`);
  });

  return searchIndex;
}

export const SearchProvider = ({ children }: { children?: ReactNode }) => {
  const auth = useAuth();
  const articles = useArticlesPublished();
  const chiefs = useChiefArticles();
  const changelogs = useArticlesChangelog();
  const categories = useCategories();
  const protections: { [fId: string]: boolean } = categories.docs.reduce(
    // eslint-disable-next-line
    (acc, c) => ((acc[c.fId] = c.isProtected), acc),
    {},
  );
  const [index, setIndex] = useState<elasticlunr.Index<Article>>();

  useMemo(() => {
    let articlesFiltered = [...articles.docs, ...changelogs.docs];
    if (auth.isAdmin) articlesFiltered = [...articlesFiltered, ...chiefs.docs];
    if (!(auth.isStaff || auth.isAdmin)) {
      articlesFiltered = articlesFiltered.filter((a) => protections[a.category] !== true);
    }

    setIndex(generateSearchIndex(articlesFiltered));
    // eslint-disable-next-line
  }, [articles.docs, chiefs.docs, changelogs.docs]);

  return (
    <SearchContext.Provider
      value={{
        search: (query: string) =>
          index?.search(query.toLowerCase(), {
            fields: {
              name: { boost: 1 },
              content: { boost: 2 },
            },
            expand: true,
            bool: 'AND',
          }) || [],
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = (query: string) => useContext(SearchContext).search(query);

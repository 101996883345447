import { useEffect, useState } from 'react';
import { useReadSubscriptionFunction } from '../components/ContextProviders/Firebase';
import { Status } from '../components/ContextProviders/Auth';
import { ExtendedSubscription, ReadSubscriptionParams } from '@eir/core';

export const useReadSubscription = ({ productId, priceId }: Partial<ReadSubscriptionParams>) => {
  const readSubscription = useReadSubscriptionFunction();
  const [status, setStatus] = useState(Status.IDLE);
  const [subscription, setSubscription] = useState<ExtendedSubscription>();

  useEffect(() => {
    if (!productId) return;

    setStatus(Status.IN_FLIGHT);
    readSubscription({
      productId,
      priceId,
    })
      .then((res) => {
        setSubscription(res.data);
        setStatus(Status.IDLE);
      })
      .catch((error) => {
        console.log(error);
        setStatus(Status.ERROR);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, priceId]);

  return { status, subscription };
};

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { sendEmailVerification } from 'firebase/auth';
import { useAuth } from '../../ContextProviders/Auth';
import { FC, useEffect, useState } from 'react';
import { toasts } from '../../../shared';
import { LoadingSVG } from '../Loading/Loading';

const EmailVerificationModal: FC<{}> = () => {
  const localization = useLocalization();
  const { user, signOut } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const [isSendingEmail, setSendingEmail] = useState(false);

  useEffect(() => {
    if (user?.emailVerified === false) {
      setOpen(true);
    }
  }, [user?.emailVerified]);

  const onSend = async () => {
    if (!user) return;

    try {
      setSendingEmail(true);
      await sendEmailVerification(user, {
        url: `${window.location.origin}/sign-in`,
        handleCodeInApp: true,
      });
      toasts.success(localization.strings.auth.emailVerification.emailSent);
    } catch (error) {
      toasts.error(localization.strings.auth.error.invalidEmailVerification);
    } finally {
      setSendingEmail(false);
    }
  };

  const onCancel = () => {
    signOut();
    setOpen(false);
  };
  return (
    <Modal size="10rem" autoFocus={true} isOpen={isOpen} zIndex={99999}>
      <ModalHeader>{localization.strings.auth.emailVerification.verify}</ModalHeader>
      <ModalBody>{localization.strings.auth.emailVerification.emailNotVerified}</ModalBody>
      <ModalFooter className="justify-content-between">
        <Button disabled={isSendingEmail} onClick={onCancel}>
          {localization.strings.global.cancel}
        </Button>

        <Button
          color="primary"
          disabled={isSendingEmail}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={onSend}
        >
          {isSendingEmail ? <LoadingSVG size={20} /> : localization.strings.auth.emailVerification.send}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EmailVerificationModal;

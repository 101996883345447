import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useLocalization } from '../ContextProviders/LocalizationContext';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
  onDiscardChanges: () => void;
}

export const NotSavedArticleModal: React.FC<Props> = (props: Props) => {
  const localization = useLocalization();
  return (
    <Modal size="10rem" autoFocus={true} isOpen={props.isOpen} zIndex={99999}>
      <ModalHeader>{localization.strings.article.draftModalHeader}</ModalHeader>
      <ModalBody>{localization.strings.article.draftModalBody}</ModalBody>
      <ModalFooter className="justify-content-between">
        <div className="d-flex justify-content-start">
          <Button className="mr-1" color="primary" onClick={props.onSave}>
            {localization.strings.global.save}
          </Button>

          <Button color="danger" onClick={props.onDiscardChanges}>
            {localization.strings.global.doNotSave}
          </Button>
        </div>

        <div className="d-flex justify-content-end">
          <Button color="danger" onClick={props.onCancel}>
            {localization.strings.global.cancel}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

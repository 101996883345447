import { FC, useEffect, useState } from 'react';

import { useLocalization } from '../../../ContextProviders/LocalizationContext';

import { PageWithSidebar } from '../../PageTypes';
import { SettingsSidebar } from '../SettingsSidebar';
import { useProjectConfig } from '../../../ContextProviders/AppContext';
import { faPencil, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Button, IconButton } from '../../../Buttons/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import DeleteRoleModal from './DeleteRoleModal';
import { StaffRoleTire } from '@eir/core';
import { WithID } from '../../../../Types';

import './RolesConfiguration.scss';
import { useCollection } from '../../../../Hooks';
import { toast } from 'react-toastify';

const RolesConfigurationPage: FC<{}> = () => {
  const { strings } = useLocalization();
  const projectConfig = useProjectConfig();

  useEffect(() => {
    document.title = `${strings.settings.subscriptions.configuration.title} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, strings.settings.subscriptions.configuration.title]);

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <RolesConfiguration />
        </div>
      </main>
    </PageWithSidebar>
  );
};

const RolesConfiguration: FC<{}> = () => {
  const [roleTires, setRoleTires] = useState<(StaffRoleTire & WithID)[]>([]);
  const [roleToDelete, setRoleToDelete] = useState<(StaffRoleTire & WithID) | undefined>(undefined);

  const [apiLoading] = useState<boolean>(false);

  const { docs, loading } = useCollection<StaffRoleTire & WithID>('/roles', {});

  const { strings } = useLocalization();
  const history = useHistory();

  useEffect(() => {
    setRoleTires(docs);
  }, [docs]);

  return (
    <>
      <h1>{strings.settings.roles.settingItemTitle}</h1>
      <p style={{ color: 'var(--grayscale-2)' }}>{strings.settings.roles.hint}</p>
      <hr />
      <IconButton
        theme="dark"
        onClick={() => history.push('/settings/role-configuration/create')}
        icon={faPlus}
        disabled={loading || apiLoading}
        text={strings.settings.roles.createRole}
      />
      <div className="mt-3 acc-mng-module shadow-sm sub-config-container">
        {roleTires && roleTires.length > 0
          ? roleTires.map((item, index) => (
              <div className="sub-config-item" key={index}>
                <div className="sub-config-name">{item.name}</div>
                <Button
                  color="primary"
                  outline={true}
                  disabled={loading || apiLoading}
                  onClick={() => history.push(`/settings/role-configuration/${item.fId}`)}
                >
                  <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
                </Button>
                <Button
                  color="danger"
                  outline={true}
                  disabled={loading || apiLoading}
                  onClick={() => setRoleToDelete(item)}
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </Button>
              </div>
            ))
          : strings.settings.roles.noRoleExists}
      </div>

      {roleToDelete && (
        <DeleteRoleModal
          onClose={(deleted) => {
            if (deleted) {
              toast.success('Deleted');
            }
            if (deleted === false) {
              toast.error(strings.settings.roles.delete.error);
            }
            setRoleToDelete(undefined);
          }}
          role={roleToDelete}
        />
      )}
    </>
  );
};

export default RolesConfigurationPage;

/* eslint-disable  @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFirestore } from '../../../ContextProviders/Firebase';
import { toast } from 'react-toastify';
import { Loading } from '../../Loading/Loading';
import { CMSUserData } from '@eir/core';
import { useConcreteProject } from '../../../ContextProviders/ProjectContext';
import { IconButton } from '../../../Buttons/Buttons';
import { faCircleInfo, faEye, faEyeSlash, faSend } from '@fortawesome/pro-solid-svg-icons';
import { useAuth } from '../../../ContextProviders/Auth';
import { isEqual } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InvitationCompletion = () => {
  const { strings } = useLocalization();
  const params = useParams<{ email: string }>();
  const firestore = useFirestore();
  const history = useHistory();
  const project = useConcreteProject();
  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [cmsUser, setCmsUser] = useState<CMSUserData>();
  const [baseUrl, setBaseUrl] = useState<string>();
  const [password, setPassword] = useState<string>('');
  const [passwordRetype, setPasswordRetype] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ name: boolean; main: boolean; retype: boolean }>({
    name: false,
    main: false,
    retype: false,
  });

  useEffect(() => {
    if (!baseUrl) {
      return;
    }
    if (!params || !params.email) {
      return;
    }
    (async () => {
      try {
        const response = await fetch(baseUrl + `/user/${params.email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const { data } = await response.json();
        if (data) {
          setCmsUser(data as CMSUserData);
          setLoading(false);
        } else {
          toast.error('Invitation for this user does not exist!');
          setLoading(false);
          return history.replace('/');
        }
      } catch (e) {
        toast.error('Invitation for this user does not exist!');
        console.log(e);
        setLoading(false);
        return history.replace('/');
      }
    })();
  }, [params, firestore, history, baseUrl]);

  useEffect(() => {
    if (project && project.firebase && project.firebase.project) {
      setBaseUrl(`https://europe-west3-${project.firebase.project}.cloudfunctions.net/MultiAccountApi`);
    }
  }, [project]);

  useEffect(() => {
    if (auth.user) {
      auth.signOut();
    }
  }, [auth]);

  const checkValidity = (): boolean => {
    if (displayName.trim() === '') {
      setErrors({ ...errors, name: true });
      return false;
    }
    if (password.length < 8 || password.length > 20) {
      setErrors({ ...errors, main: true });
      return false;
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    let ctr = 0;
    if (password.length > 7) {
      ctr++;
    }

    if (regex.test(password)) {
      ctr++;
    }

    if (password.trim() !== password) {
      // there exits whitespace in the string
      ctr = 0;
    }

    if (ctr < 2 || password.length < 8) {
      setErrors({ ...errors, main: true });
      return false;
    }

    setErrors({ ...errors, main: false });
    return checkPassMatch();
  };

  const checkPassMatch = (): boolean => {
    if (!isEqual(password, passwordRetype)) {
      setErrors({ ...errors, retype: true });
      return false;
    }
    setErrors({ ...errors, retype: false });
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!baseUrl) {
      toast.error(strings.project.notLoaded);
      return;
    }

    if (checkValidity()) {
      setApiLoading(true);
      try {
        const response = await fetch(`${baseUrl}/invitation-confirm/${params.email}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ displayName, password }),
        });

        const data = await response.json();
        if (response.status === 200) {
          setApiLoading(false);
          toast.success('Successful!');
          history.replace('/sign-in');
        } else {
          setApiLoading(false);
          toast.error(
            (data as { error: string; details: string }).details.trim().length > 0 ? data.details : data.error,
          );
        }
      } catch (e) {
        toast.error(e.message);
        console.error(e);
      }
    }
  };
  return loading ? (
    <Loading />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ margin: '1rem 1rem' }}>
        <p>{strings.auth.welcomeUser.replace('{{user}}', `${cmsUser?.email}`)}</p>
      </div>
      <Col sm={12} md={6}>
        <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="displayName">{strings.settings.multiStaffAccount.displayName}</Label>
            <Input
              id="displayName"
              name="displayName"
              placeholder={strings.settings.multiStaffAccount.displayName}
              type="text"
              required
              value={displayName}
              invalid={errors.name}
              onChange={(e) => {
                setDisplayName(e.target.value);
                setErrors((prevValue) => ({ ...prevValue, name: false }));
              }}
            />
            <FormFeedback> invalid {strings.settings.multiStaffAccount.displayName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="password">{strings.auth.passwordTitle}</Label>
            <InputGroup>
              <Input
                id="password"
                name="password"
                placeholder={strings.auth.passwordPlaceholder}
                type={showPassword ? 'text' : 'password'}
                required
                value={password}
                invalid={errors.main}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors((prevValue) => ({ ...prevValue, main: false }));
                }}
              />
              <InputGroupText onClick={() => setShowPassword((prevValue) => !prevValue)}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
              </InputGroupText>
              <FormFeedback>{strings.auth.invalidPasswordFormat}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="retypePassword">{strings.auth.passwordConfirmTitle}</Label>
            <InputGroup>
              <Input
                id="retypePassword"
                name="retypePassword"
                placeholder={strings.auth.passwordPlaceholderRetype}
                type={showPassword ? 'text' : 'password'}
                required
                value={passwordRetype}
                invalid={errors.retype}
                onChange={(e) => {
                  setPasswordRetype(e.target.value);
                  setErrors((prevValue) => ({ ...prevValue, retype: false }));
                }}
              />

              <FormFeedback>{strings.auth.invalidPasswordRetype}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton
              icon={faSend}
              isLoading={apiLoading}
              disabled={apiLoading}
              theme="dark"
              tooltip="Submit"
              text={strings.auth.submit}
              type="submit"
              onClick={handleSubmit}
              style={{ width: '100%' }}
            />
            <IconButton type="button" id={`STAFF-info-button`} icon={faCircleInfo} theme="dark" />
            <UncontrolledPopover placement="right-start" target={`STAFF-info-button`} trigger="click">
              <PopoverHeader> Password format</PopoverHeader>
              <PopoverBody>
                <div>
                  <p className="p-0 m-0"> {strings.auth.passwordRules.lowercase}</p>
                  <p className="p-0 m-0"> {strings.auth.passwordRules.uppercase}</p>
                  <p className="p-0 m-0"> {strings.auth.passwordRules.adminMin}</p>
                  <p className="p-0 m-0"> {strings.auth.passwordRules.whitespace}</p>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </Form>
      </Col>
    </div>
  );
};

export default InvitationCompletion;

import { firestore } from '../../typings/firestore';

export interface ExternalDoc extends firestore.DocumentSnapshot {
  data: () => ExternalData;
}

export enum ExternalContentType {
  URL = 'url',
  HTML = 'html',
  AGRO_SCAN = 'agroscan',
  WORK_ORDER = 'workorder',
}

export interface ExternalData {
  name: string;
  content: string;
  orderIndex?: number;
  isProtected: boolean;
  type: ExternalContentType;
  icon: string;
  availableMobile: boolean;
}

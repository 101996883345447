export enum AgroScanQuery {
  RetrievePolygons = 'RetrievePolygons',
  RetrieveCrudeProtein = 'RetrieveCrudeProtein',
  RetrieveDryMatter = 'RetrieveDryMatter',
  RetrievePlantHealth = 'RetrievePlantHealth',
  RetrievePrescriptionFile = 'RetrievePrescriptionFile',
  RetrieveSoilOrganicCarbon = 'RetrieveSoilOrganicCarbon',
  RetrieveWaterStress = 'RetrieveWaterStress',
  RetrieveSoilMoisture = 'RetrieveSoilMoisture',
  RetrieveRequestsHistory = 'RetrieveRequestsHistory',
  RetrieveVegetationIndices = 'RetrieveVegetationIndices',
}

export enum AgroScanMutation {
  GeneratePolygon = 'GeneratePolygon',
  DeactivatePolygon = 'DeactivatePolygon',
  ProcessCrudeProtein = 'ProcessCrudeProtein',
  ProcessDryMatter = 'ProcessDryMatter',
  ProcessPlantHealth = 'ProcessPlantHealth',
  ProcessPrescriptionFile = 'ProcessPrescriptionFile',
  ProcessSoilOrganicCarbon = 'ProcessSoilOrganicCarbon',
  ProcessWaterStress = 'ProcessWaterStress',
  ProcessSoilMoisture = 'ProcessSoilMoisture',
  ProcessVegetationIndices = 'ProcessVegetationIndices',
}

export interface AgroScanUserConfiguration {
  access: Record<AgroScanMutation | AgroScanQuery, boolean>;
  hectares?: number;
}

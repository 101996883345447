import { ReactElement, useEffect } from 'react';
import './Legal.scss';
import { ErrorPage } from '../Error/ErrorPage';
import { PlatformContentFrame } from '../../PlatformContentFrame/PlatformContentFrame';
import { usePlatform, useLegal } from '../../ContextProviders/AppContext';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';

export const LegalDocument = ({ name }: { name: string }): ReactElement => {
  const localization = useLocalization();
  const platform = usePlatform();
  const legal = useLegal();
  const camelCasedName = name
    .split('-')
    .map((word: string, i) => (i > 0 ? word[0].toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()))
    .join('');

  const title = useProjectTitle();
  useEffect(() => {
    document.title = `${localization.strings.global.termsOfUse} | ${title}`;
  }, [title, localization.strings.global.termsOfUse]);

  if (!legal.doc[camelCasedName]) return <ErrorPage error={new Error(localization.strings.error[404])} />;

  const body = legal.doc[camelCasedName]
    .replace('{{publisher}}', platform.doc.publisher)
    .replace('{{organization}}', platform.doc.organization)
    .replace('{{application}}', platform.doc.name);

  return <PlatformContentFrame body={body} />;
};

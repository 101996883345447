import { ReactElement, useEffect } from 'react';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import { useAuth } from '../../ContextProviders/Auth';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import ChangePasswordForm from './ChangePasswordForm';
import { SettingsSidebar } from './SettingsSidebar';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import EditAccount from './EditAccount';

export const AccountManagement = (): ReactElement => {
  const localization = useLocalization();
  const { isAdmin, user } = useAuth();
  const projectConfig = useProjectConfig();
  const title = useProjectTitle();
  const project = useConcreteProject();

  useEffect(() => {
    document.title = `${localization.strings.settings.accountMng} | ${title}`;
  }, [title, localization.strings.settings.accountMng]);

  if (projectConfig.loading) return <Loading waitingFor="Project Config" />;

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.accountMng}</h1>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 50 }}>
            {project.uniformAuthentication && project.allowAccountCreation && !isAdmin && user && (
              <div style={{ maxWidth: 500, width: '100%' }}>
                <EditAccount userId={user.uid} />
              </div>
            )}
            <div style={{ maxWidth: 500, width: '100%' }}>
              <ChangePasswordForm />
            </div>
          </div>
        </div>
      </main>
    </PageWithSidebar>
  );
};

import elasticlunr from 'elasticlunr';
import asciiFolder from 'fold-to-ascii';
import stemmerSupport from 'lunr-languages/lunr.stemmer.support';
import lunrSv from 'lunr-languages/lunr.sv';
import striptags from 'striptags';
import { Article } from './models/article';
import { StoreService } from './StoreService';
import { firestore } from '../typings/firestore';
import { UserRole } from './models';

interface SearchResultItem {
  ref: string;
  score: number;
}

// IndexedArticle contains data from articles that is used in search indexes.
interface IndexedArticle {
  title: string;
  id: string;
  content: string;
}

const separationCharacter = ' ';

stemmerSupport(elasticlunr);
lunrSv(elasticlunr);

const replaceDiacritics = (token: string): string => asciiFolder.foldReplacing(token);
elasticlunr.Pipeline.registerFunction(replaceDiacritics, 'replaceDiacritics');

export class SearchService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private searchIndex: any;

  constructor(service: StoreService, role: UserRole, categoryPermissions: string[], setupIndex = true) {
    if (setupIndex) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.setupSearchIndex(service, role, categoryPermissions);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    service.articleCollectionRef.onSnapshot((snapshot: any) => {
      // TODO: Web and Mobile QuerySnapshot interface are different.
      let documentChange: firestore.DocumentChange[] = [];
      if (snapshot) {
        if (typeof snapshot.docChanges === 'function') {
          documentChange = snapshot.docChanges();
        }
        if (typeof snapshot.docChanges === 'object') {
          documentChange = snapshot.docChanges;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      documentChange.forEach((change: any) => {
        if (this.searchIndex == null) {
          return;
        }

        if (change.type === 'added') {
          this.searchIndex.addDoc(searchDocument(change.doc));
        }
        if (change.type === 'modified') {
          this.searchIndex.updateDoc(searchDocument(change.doc));
        }
        if (change.type === 'removed') {
          this.searchIndex.removeDoc(searchDocument(change.doc));
        }
      });
    });
  }

  search(query: string): string[] {
    return this.searchIndex
      .search(query.toLowerCase(), {
        fields: {
          title: { boost: 1 },
          content: { boost: 2 },
        },
        expand: true,
        bool: 'AND',
      })
      .map((resultItem: SearchResultItem) => resultItem.ref);
  }

  async setupSearchIndex(firebaseStore: StoreService, role: UserRole, categoryPermissions: string[]): Promise<void> {
    let chiefArticles: Article[] = [];
    let changelogArticles: Article[] = [];
    const permittedCategories = await firebaseStore.initPermittedCategories(role, categoryPermissions);
    console.log(
      '[@core/SearchService]  setupSearchIndex:',
      'firebaseStore:',
      firebaseStore ? true : false,
      'categories:',
      permittedCategories,
      'role:',
      role,
    );
    const defaultArticles = await firebaseStore.fetchAllArticles();
    if (role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN) {
      chiefArticles = await firebaseStore.fetchAllChiefArticles();
    }
    if (permittedCategories.includes('changelog')) {
      changelogArticles = await firebaseStore.fetchAllChangelogArticles();
    }
    const allArticles = defaultArticles.concat(chiefArticles, changelogArticles);
    this.searchIndex = generateSearchIndex(allArticles);
  }
}

export function generateSearchIndex(articles: Article[]): elasticlunr.Index<IndexedArticle> {
  const searchIndex = elasticlunr<IndexedArticle>((index) => {
    index.addField('title');
    index.addField('content');
    index.setRef('id');
    index.saveDocument(false);
    index.use(elasticlunr.sv);
    index.pipeline.after(elasticlunr.sv.trimmer, replaceDiacritics);
  });

  articles.forEach((doc) => {
    searchIndex.addDoc(searchDocument(doc));
  });

  return searchIndex;
}

export function searchDocument(doc: Article): IndexedArticle {
  const cleanEscapedContent = doc.data().content.replace(new RegExp('&nbsp;', 'g'), separationCharacter);
  const strippedAndDecoded = striptags(cleanEscapedContent, [], separationCharacter);

  return {
    id: doc.id,
    title: doc.data().name,
    content: strippedAndDecoded.replace(/  +/g, separationCharacter),
  };
}

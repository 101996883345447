import { firestore } from '../../typings/firestore';

export enum ArticleFieldPath {
  Category = 'category',
  Section = 'section',
  orderIndex = 'orderIndex',
  deleted = 'deleted',
}

export interface Article extends firestore.DocumentSnapshot {
  data: () => ArticleData;
}

export interface ArticleData {
  name: string;
  content: string;
  category: string;
  orderIndex: number;
  section?: string | null;
  deleted?: boolean;
  state?: string;
  lastUpdatedBy?: string;
}

export const isArticle = (doc?: firestore.DocumentSnapshot): boolean => {
  return doc != null && doc.ref.parent.id.startsWith('article');
};
